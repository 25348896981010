import { ResourceType } from '@understory-io/resource-management-types';
import { Location } from '@understory-io/utils-types';
import { FC, Suspense } from 'react';
import { Await } from 'react-router';

import { ErrorElement } from '../../../../../../Components/error-element';
import { ResourceTypeSelect } from './resource-type-select';
import { ResourceTypeSelectSkeleton } from './resource-type-select-skeleton';

type Props = {
  locationId: string;
  resourceTypesAndLocationsPromise: Promise<[ResourceType[], Location[]]>;
};

export const ResourceTypeSelectLoader: FC<Props> = ({
  locationId,
  resourceTypesAndLocationsPromise,
}) => {
  return (
    <Suspense fallback={<ResourceTypeSelectSkeleton />}>
      <Await
        resolve={resourceTypesAndLocationsPromise}
        errorElement={<ErrorElement />}
      >
        {([resourceTypes, locations]) => (
          <ResourceTypeSelect
            resourceTypes={resourceTypes}
            locationId={locationId}
            locations={locations}
          />
        )}
      </Await>
    </Suspense>
  );
};
