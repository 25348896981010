import { Experience } from '@understory-io/experiences-types';

const VARIANT_PREFIX = 'variant';
const ADDON_PREFIX = 'addon';

export function getAllTicketsFromVariants(
  variants?: Experience['price']['variants']
) {
  return (
    variants?.flatMap((variant) =>
      [
        { id: `${VARIANT_PREFIX}/${variant.id}`, name: variant.name },
        variant.addons?.map((addon) => ({
          id: `${ADDON_PREFIX}/${addon.id}`,
          name: addon.name,
        })) ?? [],
      ].flat()
    ) ?? []
  );
}
