import { Localized } from '@understory-io/utils-types';

import { getTranslation } from '../../../../Api';
import { Language } from '../../../../i18n/config';

/**
 * Translates the input to the storefront languages
 * @param input - the input to translate
 * @param existingValues - the existing values
 * @param activeLanguage - the source language of the input
 * @param storefrontLanguages - the languages to translate to
 * @param isTranslateEnabled - whether auto-translation is enabled
 * @returns a `Localized` object with the input and translated values,
 * if translation is enabled
 */
export const translateInput = async (
  input: string,
  existingValues: Localized = {},
  activeLanguage: Language,
  storefrontLanguages: Language[],
  isTranslateEnabled: boolean
): Promise<Localized> => {
  const languagesToTranslateTo = storefrontLanguages.filter(
    (l) => l !== activeLanguage
  );

  const result = {
    [activeLanguage]: input ?? '',
  };

  languagesToTranslateTo.forEach((l) => (result[l] = existingValues[l] || ''));

  if (!isTranslateEnabled) {
    return result;
  }

  // We don't need to translate an empty string
  if (!input) {
    languagesToTranslateTo.forEach((l) => (result[l] = ''));
  } else {
    await Promise.all(
      languagesToTranslateTo.map((l) =>
        getTranslation({
          text: input,
          targetLanguageCode: l,
          sourceLanguageCode: activeLanguage,
        }).then(({ text }) => (result[l] = text))
      )
    );
  }

  return result;
};
