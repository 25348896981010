import {
  intervalToDuration,
  secondsInDay,
  secondsInHour,
  secondsInMinute,
} from 'date-fns';
import { useCallback, useMemo, useState } from 'react';

type TimeSpan = {
  days: number;
  hours: number;
  minutes: number;
};

export const useTimeSpan = (initialSeconds: number) => {
  const duration = intervalToDuration({
    start: 0,
    end: initialSeconds * 1000,
  });

  const [timeSpan, setTimeSpan] = useState<TimeSpan>({
    days: duration.days ?? 0,
    hours: duration.hours ?? 0,
    minutes: duration.minutes ?? 0,
  });

  const totalSeconds = useMemo(() => {
    const { days, hours, minutes } = timeSpan;
    return (
      days * secondsInDay + hours * secondsInHour + minutes * secondsInMinute
    );
  }, [timeSpan]);

  const updateTimeSpan = useCallback((field: keyof TimeSpan, value: string) => {
    setTimeSpan((prev) => ({
      ...prev,
      [field]: parseInt(value || '0', 10),
    }));
  }, []);

  const resetTimeSpan = useCallback(() => {
    setTimeSpan({ days: 0, hours: 0, minutes: 0 });
  }, []);

  return { timeSpan, totalSeconds, updateTimeSpan, resetTimeSpan };
};
