import { DeleteOutlined } from '@mui/icons-material';
import {
  Chip,
  Grid,
  GridProps,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Text } from '@understory-io/pixel';
import { useTranslation } from 'react-i18next';

import { useRoles } from '../../Hooks/data/useRoles';
import { Input } from '../Input/Input';

export const UserItem = ({
  email,
  name,
  role,
  onDelete,
  onUpdate,
  ...props
}: GridProps & {
  email?: string;
  name: string;
  role: string;
  onDelete: () => void;
  onUpdate: (key: 'email' | 'role', value: any) => void;
}) => {
  const { t } = useTranslation();
  const { activeRoles: roles } = useRoles();

  return (
    <Stack gap={2}>
      <Text fontSize="medium" variant="medium">
        {name}
      </Text>
      <Grid container columns={11} columnSpacing={2} {...props}>
        <Grid item xs={5}>
          <TextField
            fullWidth
            InputLabelProps={{ shrink: Boolean(email) }}
            defaultValue={email}
            onChange={(evt) => onUpdate('email', evt.target.value)}
            label={t('utils.generic.email')}
          />
        </Grid>
        <Grid item xs={5} alignItems={'center'}>
          <Input
            fullWidth
            type={'select'}
            InputLabelProps={{ shrink: Boolean(role) }}
            onChange={(evt) => onUpdate('role', evt.target.value)}
            label={t('utils.generic.role')}
            defaultValue={role}
            options={roles?.map(({ name, id }) => ({
              label: name,
              key: id,
            }))}
          />
        </Grid>
        <Grid
          item
          xs={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'end'}
        >
          <IconButton size={'medium'} onClick={onDelete}>
            <DeleteOutlined fontSize={'small'} />
          </IconButton>
        </Grid>
      </Grid>
    </Stack>
  );
};
