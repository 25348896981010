import { ChevronRightRounded } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ResourceType } from '@understory-io/resource-management-types';
import { Suspense } from 'react';
import { Await, Link } from 'react-router';

import { ErrorElement } from '../../../../Components/error-element';
import { useTranslate } from '../../../../Hooks/useTranslate';
import routes from '../../../../Utils/routes';
import { ResourceManagementListItem } from '../list/resource-management-list-item';
import { ResourceManagementListItemSkeleton } from '../list/resource-management-list-item-skeleton';
import { ResourceTypesListEmpty } from './resource-types-list-empty';

type Props = {
  resourceTypesPromise: Promise<ResourceType[]>;
};

export const ResourceTypesList = ({ resourceTypesPromise }: Props) => {
  const { t } = useTranslate('resourceManagement.overview.list');

  return (
    <Stack gap={2}>
      <Suspense
        fallback={
          <>
            <ResourceManagementListItemSkeleton />
            <ResourceManagementListItemSkeleton />
          </>
        }
      >
        <Await resolve={resourceTypesPromise} errorElement={<ErrorElement />}>
          {(resourceTypes) =>
            resourceTypes.length === 0 ? (
              <ResourceTypesListEmpty />
            ) : (
              resourceTypes.map((item) => (
                <ResourceManagementListItem
                  key={item.resourceTypeId}
                  component={Link}
                  to={
                    routes.resourceManagement.details(item.resourceTypeId).index
                  }
                >
                  <StyledTypeName>{item.name}</StyledTypeName>
                  <StyledResourcesCount>
                    {t('resourcesCount', { count: item.totalResources })}
                  </StyledResourcesCount>
                  <ChevronRightRounded
                    htmlColor={lightTheme.palette.neutral.n300}
                  />
                </ResourceManagementListItem>
              ))
            )
          }
        </Await>
      </Suspense>
    </Stack>
  );
};

const StyledTypeName = styled(Text)({
  fontSize: 15,
  flexGrow: 1,
  fontWeight: 500,
  letterSpacing: '-0.15px',
});

const StyledResourcesCount = styled(Text)({
  fontSize: 15,
  color: lightTheme.palette.neutral.n300,
  fontWeight: 500,
  letterSpacing: '-0.15px',
});
