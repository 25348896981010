import styled from '@emotion/styled';
import { capitalize, Divider, Skeleton, Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Text } from '@understory-io/pixel';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useProfile } from '../../../../../../../Hooks/useProfile';
import { useUsers } from '../../../../../../../Hooks/useUsers';
import { trackEventFlowGuideSelected } from '../../../../../../../tracking/events/flow/details/form/trackEventFlowGuideSelected';
import { trackEventFlowGuideSelectionOpened } from '../../../../../../../tracking/events/flow/details/form/trackEventFlowGuideSelectionOpened';
import { useEventUpsert } from '../../../../domain/event_upsert_context'; // Make sure to import useEventUpsert hook correctly

export const EventUpsertDetailsFormGuideSelect = () => {
  const { t } = useTranslation();
  const { currentEvent, updateEventField } = useEventUpsert();

  const {
    me: { data: me },
  } = useProfile();

  const {
    users: { data: users, isLoading, isError },
  } = useUsers();

  const usersSorted = useMemo(() => {
    if (!users) return [];
    return [...users].sort((a, b) =>
      (a.name ?? a.email).localeCompare(b.name ?? b.email)
    );
  }, [users]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const selectedGuidesIds =
        typeof event.target.value === 'string'
          ? event.target.value.split(',')
          : event.target.value;

      trackEventFlowGuideSelected(
        me?.id,
        users,
        selectedGuidesIds,
        currentEvent.assignedGuides
      );
      updateEventField('assignedGuides', selectedGuidesIds);
    },
    [updateEventField]
  );

  const label = useMemo(
    () =>
      currentEvent.assignedGuides?.length > 1
        ? t('eventUpsert.flow.details.form.guide.label.plural')
        : t('eventUpsert.flow.details.form.guide.label.singular'),
    [currentEvent.assignedGuides, t]
  );

  const getGuideFieldLabel = useCallback(
    (selectedIds: string[]) => {
      return users
        ?.filter((user) => selectedIds.includes(user.id ?? ''))
        .map((user) => user.name ?? t('utils.generic.noName'))
        .join(', ');
    },
    [users, t]
  );

  const getGuideOptionName = useCallback(
    (id?: string) => {
      const user = users?.find((user) => user.id === id);
      if (!user) return t('utils.generic.noName');

      return `${
        user.name ? capitalize(user.name) : t('utils.generic.noName')
      } ${user.id === me?.id ? `(${t('utils.generic.you')})` : ''}`;
    },
    [me?.id, users, t]
  );

  if (isLoading && !users) {
    return <Skeleton variant="rounded" height={56} width="100%" />;
  }

  if (isError || !users) {
    return null;
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="details-guide-select-label" shrink>
        {label}
      </InputLabel>
      <Select
        labelId="details-guide-select-label"
        multiple
        displayEmpty
        value={currentEvent.assignedGuides}
        onChange={handleChange}
        onOpen={trackEventFlowGuideSelectionOpened}
        input={<OutlinedInput notched label={label} />}
        renderValue={(selected) => {
          if (!selected.length) {
            return (
              <Text fontSize="small">
                {t('eventUpsert.flow.details.form.guide.options.noGuide')}
              </Text>
            );
          }
          return <Text fontSize="small">{getGuideFieldLabel(selected)}</Text>;
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 600,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        <MenuItem onClick={() => updateEventField('assignedGuides', [])}>
          <Text fontSize="small">
            {t('eventUpsert.flow.details.form.guide.options.noGuide')}
          </Text>
        </MenuItem>
        <Divider variant="middle" />
        {usersSorted.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            <Stack direction="row" alignItems="center" gap={1}>
              <StyledCheckbox
                checked={currentEvent.assignedGuides.includes(user.id ?? '')}
              />
              <Text fontSize="small">{getGuideOptionName(user.id)}</Text>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  margin: 0;
`;
