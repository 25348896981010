import styled from '@emotion/styled';
import { Box, Stack, StackProps } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { FC, ReactNode } from 'react';

type Props = {
  title: string;
  description: string;
  action?: ReactNode;
} & StackProps;

export const ResourceManagementEmptyList: FC<Props> = ({
  title,
  description,
  action,
  ...props
}) => {
  return (
    <Stack alignItems="center" mx="auto" gap={0.5} {...props}>
      <Box
        component="img"
        width={200}
        height={200}
        sx={{
          objectFit: 'contain',
          objectPosition: 'center',
        }}
        src="/empty-state-nature-with-circle.svg"
        mb={3}
      />
      <Text fontSize="xlarge" variant="medium">
        {title}
      </Text>
      <Description color={lightTheme.palette.neutral.n300} textAlign="center">
        {description}
      </Description>
      {action}
    </Stack>
  );
};

const Description = styled(Text)`
  max-width: 700px;
  margin-bottom: 24px;
`;
