import { ChevronRight } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { lightTheme, Text } from '@understory-io/pixel';
import { renderDate } from '@understory-io/utils-date';
import { Connection, RequestItem } from '@understory-io/utils-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { Link } from 'react-router';
import { Outlet, useLoaderData, useNavigate } from 'react-router';

import { getConnections, getInvitation } from '../../../Api';
import { getPublicCompanyProfile, getUserinfo } from '../../../Api';
import { Avatar } from '../../../Components/Avatar';
import { StatusBadge } from '../../../Components/badge/status-badge';
import { RouterLinkButton } from '../../../Components/button/router-link-button';
import { useAuth } from '../../../Hooks/useAuth';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { useTranslate } from '../../../Hooks/useTranslate';
import { trackConnectPageViewed } from '../../../tracking/connect/connect-events';
import { Account } from '../components/choose-account-dialog';
import { connectStore } from '../connect-store';
import { ViewHeaderCreate } from './connect-header';
import { encodeConnectionPath } from './connection-path-tools';
import { ListCard } from './list-card';

type LoaderData = {
  connections: {
    companyId: string;
    token: string;
    createdAt: string;
    receiverLabel: string;
    state: Connection['state'];
    logo?: string;
  }[];
  receivedRequests: {
    createdAt: string;
    receiverLabel: string;
    token: string;
    logo?: string;
  }[];
  memberOfCompanies?: Account[];
  token?: string;
  accountChosen?: boolean;
};

async function getPublicCompany(companyId?: string): Promise<Account | null> {
  if (!companyId) {
    return null;
  }
  try {
    const profile = await getPublicCompanyProfile(companyId);
    return profile === null
      ? null
      : {
          id: profile.id,
          name: profile.name,
          logo: profile?.pictures?.logo?.url,
        };
  } catch (error) {
    console.error(error);
    return null;
  }
}

const CONNECTION_SORT_ORDER = ['pending', 'connected', 'disconnected'];
function sortConnections(
  a: { state: Connection['state'] },
  b: { state: Connection['state'] }
): number {
  return (
    CONNECTION_SORT_ORDER.indexOf(a.state) -
    CONNECTION_SORT_ORDER.indexOf(b.state)
  );
}

export async function loader(): Promise<LoaderData> {
  const userInfo = await getUserinfo();

  const connectState = connectStore.getState();
  const invitationsIds = Object.entries(connectState.invitations)
    .filter(([, value]) => value.org === userInfo.org)
    .map(([key]) => key);

  const [allConnections, ...allInvitations] = await Promise.all([
    getConnections(),
    ...invitationsIds.map((invitationId) => getInvitation(invitationId)),
  ]);

  const invitationsToShow = allInvitations.filter(
    (invitation): invitation is RequestItem => invitation !== null
  );

  const enrichedItems = await Promise.all(
    [...allConnections, ...invitationsToShow].map(async (item) => {
      const company = await getPublicCompany(item.connectedCompanyId);
      const receiverLabel = company?.name || item.receiverEmail;

      return {
        ...item,
        logo: company?.logo,
        receiverLabel,
      };
    })
  );

  const connections: LoaderData['connections'] = [];
  const receivedRequests: LoaderData['receivedRequests'] = [];

  for (const item of enrichedItems) {
    if (item.state === 'requested') {
      receivedRequests.push({
        createdAt: item.createdAt,
        receiverLabel: item.receiverLabel,
        token: item.token,
        logo: item.logo,
      });
    } else {
      connections.push({
        companyId: item.companyId,
        token: item.token,
        createdAt: item.createdAt,
        receiverLabel: item.receiverLabel,
        state: item.state,
        logo: item.logo,
      });
    }
  }

  return {
    connections: connections.sort(sortConnections),
    receivedRequests,
  };
}

export const ConnectSettingsList = () => {
  const flags = useFlags();
  const navigate = useNavigate();
  const { canAccess } = useAuth();
  const loaderData = useLoaderData() as LoaderData;
  const { t } = useTranslate('connect');
  const fireOnce = useFireOnce();

  useEffect(() => {
    fireOnce(() =>
      trackConnectPageViewed(
        loaderData.receivedRequests.length,
        loaderData.connections.length
      )
    );
  }, [
    fireOnce,
    loaderData.connections.length,
    loaderData.receivedRequests.length,
  ]);

  if (!flags.featureConnectedAccounts) {
    navigate('/dashboard', { replace: true });
    // Return something so we don't run any of the below code when redirecting
    return <></>;
  }

  if (!canAccess('admin')) {
    return <>Not admin</>;
  }

  return (
    <>
      <Stack gap={4}>
        <ViewHeaderCreate
          title={t('title')}
          description={t('description')}
          action={t('addConnection')}
        />

        {loaderData.receivedRequests.length === 0 ? null : (
          <ListCard
            title={t('invitations', {
              numberOfInvitations: loaderData.receivedRequests.length,
            })}
          >
            <Stack component="ul" gap={2}>
              {loaderData.receivedRequests.map((request) => (
                <Box
                  component="li"
                  key={request.token}
                  sx={{
                    display: 'grid',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gridTemplateAreas: {
                      xs: `"label actions" "date actions"`,
                      md: '"avatar label date actions"',
                    },
                    gridTemplateColumns: {
                      xs: '1fr .5fr',
                      md: '48px .5fr .8fr 24px',
                    },
                    columnGap: { xs: 0, md: 3 },
                    rowGap: { xs: 1, md: 1 },
                  }}
                >
                  <Box
                    component={'span'}
                    sx={{
                      display: {
                        xs: 'none',
                        md: 'unset',
                      },
                      gridArea: 'avatar',
                    }}
                  >
                    <Avatar
                      stringValue={request.receiverLabel.toUpperCase()}
                      imageUrl={request.logo}
                    />
                  </Box>
                  <Box
                    component={Text}
                    variant="medium"
                    fontSize={'medium'}
                    sx={{
                      gridArea: 'label',
                    }}
                  >
                    {request.receiverLabel}
                  </Box>
                  <Box
                    component={Text}
                    variant="normal"
                    fontSize={'medium'}
                    color={lightTheme.palette.neutral.n300}
                    sx={{
                      gridArea: 'date',
                    }}
                  >
                    {renderDate(request.createdAt, 'dd.MM.yyyy')}
                  </Box>
                  <Stack
                    direction={'row'}
                    justifyContent="end"
                    gap={1}
                    sx={{
                      gridArea: 'actions',
                    }}
                  >
                    <RouterLinkButton
                      href={`decline/${request.token}`}
                      variant="secondary"
                    >
                      {t('decline')}
                    </RouterLinkButton>
                    <RouterLinkButton
                      href={`accept/${request.token}`}
                      variant="primary"
                    >
                      {t('accept')}
                    </RouterLinkButton>
                  </Stack>
                </Box>
              ))}
            </Stack>
          </ListCard>
        )}
        <ListCard title={t('connections')}>
          {loaderData.connections.length ? (
            <Stack component={'ul'} gap={2}>
              {loaderData.connections.map((c) => {
                const key = encodeConnectionPath(c.companyId, c.token);
                return (
                  <Box component="li" key={key} sx={{ listStyle: 'none' }}>
                    <GridLink
                      to={'details/' + key}
                      sx={{
                        display: 'grid',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gridTemplateAreas: {
                          xs: '"label state arrow" "date state arrow"',
                          md: '"avatar label date state arrow"',
                        },
                        gridTemplateColumns: {
                          xs: '1fr .7fr 24px',
                          md: '48px .4fr .4fr .2fr 24px',
                        },
                        gap: {
                          xs: 0,
                          md: 3,
                        },
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          gridArea: 'avatar',
                          display: {
                            xs: 'none',
                            md: 'unset',
                          },
                        }}
                      >
                        <Avatar
                          imageUrl={c.logo}
                          stringValue={c.receiverLabel.toUpperCase()}
                        />
                      </Box>
                      <Text
                        fontSize="medium"
                        variant="medium"
                        style={{ gridArea: 'label' }}
                      >
                        {c.receiverLabel}
                      </Text>

                      <Box
                        component={Text}
                        fontSize="medium"
                        variant="normal"
                        color={lightTheme.palette.neutral.n300}
                        sx={{
                          gridArea: 'date',
                        }}
                      >
                        {renderDate(c.createdAt, 'dd.MM.yyyy')}
                      </Box>
                      <Box
                        component="span"
                        sx={{
                          gridArea: 'state',
                        }}
                      >
                        <StatusBadge
                          type="connection"
                          size="medium"
                          state={c.state}
                        />
                      </Box>
                      <span
                        style={{
                          gridArea: 'arrow',
                          color: lightTheme.palette.neutral.n200,
                        }}
                      >
                        <ChevronRight />
                      </span>
                    </GridLink>
                  </Box>
                );
              })}
            </Stack>
          ) : (
            <Text color={lightTheme.palette.neutral.n400}>
              {t('noConnections')}
            </Text>
          )}
        </ListCard>
        <Outlet />
      </Stack>
    </>
  );
};

const GridLink = styled(Link)({});
