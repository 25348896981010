import { CloseRounded } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  IconButton,
  Skeleton,
  Stack,
} from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import { Text } from '@understory-io/pixel';
import { ResourceType } from '@understory-io/resource-management-types';
import randomBytes from 'randombytes';
import { Suspense } from 'react';
import {
  ActionFunctionArgs,
  Await,
  LoaderFunctionArgs,
  redirect,
  useLoaderData,
  useNavigate,
} from 'react-router';
import { toast } from 'react-toastify';

import { createResource } from '../../../../Api/resource-management';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { userInfoQuery } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { t } from '../../../../i18n/config';
import { resourceTypesQuery } from '../../data/use-resource-types';
import { resourcesQuery } from '../../data/use-resources';
import { CreateResourceForm } from './create-resource-form';
import { CreateResourceInputs } from './create-resource-inputs';

type LoaderData = {
  resourceTypePromise: Promise<ResourceType>;
};

export const loader =
  (client: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const user = await client.fetchQuery(userInfoQuery());
    if (!user.companyId) {
      return redirect('/login');
    }

    const resourceTypePromise = (async () => {
      const resourceTypes = await client.fetchQuery(resourceTypesQuery());

      const { resourceTypeId } = params;
      const resourceType = resourceTypes.find(
        (type) => type.resourceTypeId === resourceTypeId
      );
      if (!resourceType) {
        throw new Response('Resource type cannot be found', { status: 404 });
      }

      return resourceType;
    })();

    return { resourceTypePromise };
  };

export const action =
  (queryClient: QueryClient) =>
  async ({ request }: ActionFunctionArgs) => {
    const loadingToastId = randomBytes(16).toString('hex');
    toast.loading(
      t('resourceManagement.resourceType.createNew.toast.loading'),
      {
        toastId: loadingToastId,
      }
    );

    try {
      const user = await queryClient.fetchQuery(userInfoQuery());
      if (!user.companyId) {
        throw new Error("User doesn't have a company ID");
      }

      const payload = (await request.json()) as CreateResourceInputs;

      await createResource({
        ...payload,
        companyId: user.companyId,
      });

      await queryClient.invalidateQueries({
        queryKey: resourcesQuery(payload.resourceTypeId).queryKey,
      });

      // Since the type will have total resources incremented,
      // we either have to invalidate all types or manually update the cache.
      await queryClient.invalidateQueries({
        queryKey: resourceTypesQuery().queryKey,
      });

      toast.dismiss(loadingToastId);

      return redirect('../');
    } catch (error) {
      console.error(error);
      toast.dismiss(loadingToastId);
      toast.error(t('resourceManagement.resource.createNew.toast.error'), {
        delay: 500,
      });
      return {
        success: false,
      };
    }
  };

export const CreateResourceDialog = () => {
  const { t } = useTranslate('resourceManagement.resource');
  const { isSm } = useResponsive();
  const { resourceTypePromise } = useLoaderData() as LoaderData;
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('../');
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('createNew.title')}
    >
      <DialogContent sx={{ padding: 4 }}>
        <Stack direction="row" justifyContent="space-between" marginBottom={1}>
          <Text fontSize="xlarge" variant="medium">
            {t('createNew.title')}
          </Text>
          <IconButton sx={{ padding: 0 }} onClick={handleClose} tabIndex={-1}>
            <CloseRounded sx={{ fontSize: { xs: '24px', md: '32px' } }} />
          </IconButton>
        </Stack>
        <Suspense
          fallback={
            <>
              <Skeleton variant={'rectangular'} height={32} width={'100%'} />
              <Skeleton variant={'rectangular'} height={32} width={'100%'} />
            </>
          }
        >
          <Await resolve={resourceTypePromise}>
            {(resourceType) => (
              <CreateResourceForm resourceType={resourceType} />
            )}
          </Await>
        </Suspense>
      </DialogContent>
    </Dialog>
  );
};
