import { AddRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import { Text } from '@understory-io/pixel';
import {
  Resource,
  ResourceType,
} from '@understory-io/resource-management-types';
import { Location } from '@understory-io/utils-types';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  Outlet,
  useLoaderData,
} from 'react-router';
import { toast } from 'react-toastify';

import { ShowForScope } from '../../../../Components/AllowForScope/AllowForScope';
import { AppShell } from '../../../../Components/AppShell/AppShell';
import { RouterLinkButton } from '../../../../Components/button/router-link-button';
import { Page } from '../../../../Components/Page/Page';
import { PageBreadcrumbItem } from '../../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/page-breadcrumb-item';
import { PageBreadcrumbBreadcrumbs } from '../../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/PageBreadcrumbBreadcrumbs';
import { locationsQuery } from '../../../../Hooks/data/useLocations';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { t } from '../../../../i18n/config';
import routes from '../../../../Utils/routes';
import { RESOURCE_MANAGEMENT_SCOPES } from '../../auth/resource-management-scopes';
import { resourceTypesQuery } from '../../data/use-resource-types';
import { resourcesQuery } from '../../data/use-resources';
import { actionPayloadValidator, handlePayload } from './actions';
import { ResourceTypeDetailsCard } from './resource-type-details-card';
import { ResourcesList } from './resources-list';

type LoaderData = {
  resourceType: ResourceType;
  resources: Promise<Array<Resource>>;
  locations: Promise<Array<Location>>;
};

export const loader =
  (client: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<LoaderData> => {
    const resourceTypes = await client.fetchQuery(resourceTypesQuery());

    const resourceType = resourceTypes.find(
      (x) => x.resourceTypeId === params.resourceTypeId
    );

    if (!resourceType) {
      throw new Response('Resource type not found', { status: 404 });
    }
    const locations = client.fetchQuery(locationsQuery(resourceType.companyId));
    const resources = client.fetchQuery(
      resourcesQuery(resourceType.resourceTypeId)
    );

    return {
      resourceType,
      resources,
      locations,
    };
  };

export const ResourceTypeDetails = () => {
  const { t } = useTranslate('resourceManagement.details');
  const { resourceType, resources, locations } = useLoaderData<LoaderData>();

  return (
    <AppShell hideContainerPadding>
      <PageBreadcrumbBreadcrumbs>
        <PageBreadcrumbItem
          title={t('resourceManagement', 'utils.primaryNavigation')}
          href={routes.resourceManagement.index}
        />
        <PageBreadcrumbItem
          title={resourceType.name}
          href={
            routes.resourceManagement.details(resourceType.resourceTypeId).index
          }
        />
      </PageBreadcrumbBreadcrumbs>
      <Page
        sx={{
          maxWidth: '1400px',
          pr: { xs: 0, md: 2 },
          pb: { xs: 0, md: 10 },
          pl: { xs: 0, lg: '50px' },
          pt: { xs: 5, lg: 0 },
        }}
      >
        <Stack gap={2} direction="row" justifyContent="space-between">
          <Stack gap={2} direction="row" alignItems={'center'}>
            <Text fontSize="h5">{resourceType.name}</Text>
          </Stack>
          <ShowForScope scopes={RESOURCE_MANAGEMENT_SCOPES.MANAGE_RESOURCES}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <RouterLinkButton
                href={
                  routes.resourceManagement.details(resourceType.resourceTypeId)
                    .createResource
                }
                variant="primary"
                leftIcon={<AddRounded fontSize="small" sx={{ mr: 1 }} />}
              >
                {t('addResource')}
              </RouterLinkButton>
            </Stack>
          </ShowForScope>
        </Stack>

        <Stack
          gap={5}
          marginTop={4}
          sx={{
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
        >
          <ResourceTypeDetailsCard
            resourceType={resourceType}
            locations={locations}
          />
          <ResourcesList
            resourceType={resourceType}
            resourcesPromise={resources}
          />
        </Stack>
        <Outlet />
      </Page>
    </AppShell>
  );
};

export const action =
  (client: QueryClient) =>
  async ({ request }: ActionFunctionArgs) => {
    const payload = await request.json();
    if (!actionPayloadValidator.Check(payload)) {
      return { success: false };
    }

    const toastId = toast.loading(
      t('resourceManagement.details.toast.updateResource.loading')
    );

    try {
      await handlePayload(client, payload);

      toast.update(toastId, {
        render: t('resourceManagement.details.toast.updateResource.success'),
        type: 'success',
        isLoading: false,
        delay: 700,
        autoClose: 5000,
      });

      return {
        success: false,
      };
    } catch (error) {
      console.error(error);

      toast.update(toastId, {
        render: t('utils.errors.generic'),
        type: 'error',
        isLoading: false,
        delay: 700,
        autoClose: 5000,
      });

      return {
        success: false,
      };
    }
  };
