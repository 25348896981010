import { Paper } from '@mui/material';
import { BubbleMenu } from '@tiptap/react';
import {
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonEditLink,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonUnderline,
  useRichTextEditorContext,
} from 'mui-tiptap';
import { PropsWithChildren } from 'react';

import CustomMenuSelectHeading from './custom-menu-select-heading';

interface CustomBubbleMenuProps {}

export default function CustomBubbleMenu({
  children,
}: PropsWithChildren<CustomBubbleMenuProps>) {
  const editor = useRichTextEditorContext();

  if (!editor) return null;

  return (
    <BubbleMenu tippyOptions={{ duration: 100 }} editor={editor}>
      <Paper sx={{ display: { xs: 'flex', md: 'none' }, p: 0.5, gap: 0.25 }}>
        <CustomMenuSelectHeading />
        <MenuButtonBold />
        <MenuButtonItalic />
        <MenuButtonUnderline />
        <MenuButtonEditLink />
        <MenuButtonBulletedList />
        <MenuButtonOrderedList />
        {children}
      </Paper>
    </BubbleMenu>
  );
}
