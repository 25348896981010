import { getCompanyUsers, getGiftCard, getReceipt } from '../../../Api';
import { queryClient } from '../../../query-client';

export async function getCompanyUsersQuery() {
  const companyProfile = await queryClient.fetchQuery({
    queryKey: ['companyUsers'],
    queryFn: () => getCompanyUsers(),
  });

  return companyProfile;
}

export async function getReceiptQuery(id: string) {
  const receipt = await queryClient.fetchQuery({
    queryKey: ['receipt', id],
    queryFn: () => getReceipt(id),
  });

  return receipt;
}

export async function getGiftCardQuery(id: string) {
  const receipt = await queryClient.fetchQuery({
    queryKey: ['giftcard', id],
    queryFn: () => getGiftCard(id),
  });

  return receipt;
}
