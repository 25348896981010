import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { GiftCardHistory } from '@understory-io/utils-types';
import { Suspense } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Await, Link } from 'react-router';

import { InfoBox } from '../../../../Components/InfoBox/InfoBox';
import {
  Timeline,
  TimelineProps,
} from '../../../../Components/Timeline/Timeline';
import { formatMoney } from '../../../../Components/VariantSelect/VariantSelect';

interface HistoryEntryBase extends Pick<GiftCardHistory, 'type' | 'date'> {}

interface ValueAdjustedEntry extends HistoryEntryBase {
  type: 'giftcard-value-adjusted';
  amountUsedCents: number;
  userName?: string;
  note: GiftCardHistory['note'];
}

interface GiftCardUsedEntry extends HistoryEntryBase {
  type: 'giftcard-used';
  amountUsedCents: number;
  bookingId: string;
}

interface GiftCardInvalidatedEntry extends HistoryEntryBase {
  type: 'giftcard-cancelled';
  userName?: string;
}

interface GiftCardRefundedEntry extends HistoryEntryBase {
  type: 'giftcard-refunded';
  refundedAmountCents: number;
}

interface GiftFilledEntry extends HistoryEntryBase {
  type: 'giftcard-filled';
  amountFilledCents: number;
  bookingId: string;
}

export type GiftCardActivity =
  | ValueAdjustedEntry
  | GiftCardUsedEntry
  | GiftCardInvalidatedEntry
  | GiftCardRefundedEntry
  | GiftFilledEntry;

type ActivityLogProps = {
  activities: Promise<GiftCardActivity[]>;
  currency: string;
  purchaseDate: Date;
};

export const ActivityLog = ({
  activities,
  currency,
  purchaseDate,
}: ActivityLogProps) => {
  const { t } = useTranslation();

  return (
    <InfoBox title={t('giftCard.activity.title')}>
      <Suspense fallback={<Skeleton variant="rounded" height={100} />}>
        <Await resolve={activities}>
          {(activities) => {
            const activityMap: TimelineProps['activities'] = [
              ...activities.map((entry) => ({
                date: new Date(entry.date),
                title: getActivityLogTitle(entry, t, currency),
                content: (
                  <ActivityLogContent entry={entry} currency={currency} />
                ),
              })),
              {
                date: purchaseDate,
                title: t('giftCard.activity.purchased'),
              },
            ];

            if (!activityMap || !activityMap.length) return null;

            return <Timeline activities={activityMap} />;
          }}
        </Await>
      </Suspense>
    </InfoBox>
  );
};

const StyledLink = styled(Link)({
  color: lightTheme.palette.action.a300,
  textDecoration: 'underline',
  transition: '0.1s ease-in-out',
  ':hover': {
    color: lightTheme.palette.action.a200,
  },
});

const getActivityLogTitle = (
  entry: GiftCardActivity,
  t: TFunction,
  currency: string
) => {
  const moneyFormatter = formatMoney(t, true);

  switch (entry.type) {
    case 'giftcard-value-adjusted':
      return t(
        entry.amountUsedCents > 0
          ? 'giftCard.activity.balanceReduced'
          : 'giftCard.activity.balanceIncreased',
        {
          amount: moneyFormatter({
            value: Math.abs(entry.amountUsedCents) / 100,
            nativeCurrency: currency,
          }),
          name: entry.userName,
        }
      );
    case 'giftcard-cancelled':
      return t('giftCard.activity.giftCardCancelled', {
        name: entry.userName,
      });
    case 'giftcard-refunded':
      return t('giftCard.activity.refundedAmount', {
        amount: moneyFormatter({
          value: entry.refundedAmountCents / 100,
          nativeCurrency: currency,
        }),
      });
    default:
      // For gift card's usage, we only use content
      return undefined;
  }
};

const ActivityLogContent = ({
  entry,
  currency,
}: {
  entry: GiftCardActivity;
  currency: string;
}) => {
  const { t } = useTranslation();
  const moneyFormatter = formatMoney(t, true);

  switch (entry.type) {
    case 'giftcard-used':
      return (
        <Text fontSize="small">
          {t('giftCard.activity.spentOn', {
            amount: moneyFormatter({
              value: entry.amountUsedCents / 100,
              nativeCurrency: currency,
            }),
          })}{' '}
          <StyledLink to={`/booking/${entry.bookingId}`}>
            {t('giftCard.activity.bookingLink')}
          </StyledLink>
        </Text>
      );
    case 'giftcard-value-adjusted':
      return entry.note ? <Text fontSize="small">{entry.note}</Text> : null;
    case 'giftcard-filled':
      return (
        <Text fontSize="small">
          {t('giftCard.activity.filledBy', {
            amount: moneyFormatter({
              value: entry.amountFilledCents / 100,
              nativeCurrency: currency,
            }),
          })}{' '}
          <StyledLink to={`/booking/${entry.bookingId}`}>
            {t('giftCard.activity.bookingLink')}
          </StyledLink>
        </Text>
      );
    default:
      return null;
  }
};
