import { Experience } from '@understory-io/experiences-types';

import { ampli } from '../../../../../Ampli';
import { getLocalized } from '../../../../../Hooks/useBookings';

export const trackEventFlowExperienceSelected = (experience: Experience) => {
  ampli.eventFlowExperienceSelected({
    experience_id: experience.id,
    experience_name: getLocalized(experience.headline, 'en') ?? '',
  });
};
