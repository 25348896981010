import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { useFormContext } from 'react-hook-form';

import { ampli } from '../../../../../../../Ampli';
import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import { RoleFormSchema } from '../use-role-form-validation';

export const RoleFormScopesTable = () => {
  const { t } = useTranslate('settings.team.roles.form');
  const { watch, setValue, clearErrors } = useFormContext<RoleFormSchema>();
  const scopes = watch('scopes');

  const handleScopeChange = (scopeKey: string, checked: boolean) => {
    clearErrors('scopes');

    const newScopes = new Set(scopes);

    const isWrite = scopeKey.includes('.write');
    const config = SCOPE_CONFIGS.find((c) =>
      isWrite ? c.writeKey === scopeKey : c.readKey === scopeKey
    );

    if (checked) {
      newScopes.add(scopeKey);
      if (isWrite && config?.readKey) {
        newScopes.add(config.readKey);
      }

      config?.dependencies?.forEach((dep) => newScopes.add(dep));
    } else {
      newScopes.delete(scopeKey);
      if (!isWrite && config?.writeKey) {
        newScopes.delete(config.writeKey);
      }

      config?.dependencies?.forEach((dep) => newScopes.delete(dep));
    }

    setValue('scopes', Array.from(newScopes));

    ampli.rolesFlowPermissionsChanged({
      permission: scopeKey,
      enabled: checked,
    });
  };

  return (
    <TableContainer>
      <Table
        size="small"
        sx={{
          '& .MuiTableCell-root': {
            paddingX: 0,
          },
        }}
      >
        <TableHead>
          <TableRow sx={{ '& .MuiTableCell-root': { borderTop: 'none' } }}>
            <TableCell />
            <TableCell align="center">
              <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
                {t('readAccess')}
              </Text>
            </TableCell>
            <TableCell align="center" color={lightTheme.palette.neutral.n400}>
              <Text fontSize="small">{t('writeAccess')}</Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {SCOPE_CONFIGS.map((config) => (
            <TableRow key={config.readKey}>
              <TableCell>
                <Text fontSize="small">{t(`scopes.${config.readKey}`)}</Text>
              </TableCell>
              <TableCell align="center">
                {config.readKey && (
                  <Checkbox
                    size="small"
                    checked={scopes.includes(config.readKey)}
                    onChange={(e) =>
                      handleScopeChange(config.readKey, e.target.checked)
                    }
                  />
                )}
              </TableCell>
              <TableCell align="center">
                <Checkbox
                  size="small"
                  checked={scopes.includes(config.writeKey)}
                  onChange={(e) =>
                    handleScopeChange(config.writeKey, e.target.checked)
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const SCOPE_CONFIGS = [
  {
    readKey: 'company',
    writeKey: 'company.write',
    dependencies: ['organization.write'],
  },
  {
    readKey: 'booking',
    writeKey: 'booking.write',
  },
  {
    readKey: 'experience',
    writeKey: 'experience.write',
  },
  {
    readKey: 'event',
    writeKey: 'event.write',
  },
  {
    readKey: 'giftCard',
    writeKey: 'giftCard.write',
  },
  {
    readKey: 'location',
    writeKey: 'location.write',
  },
  {
    readKey: 'marketing',
    writeKey: 'marketing.write',
  },
  {
    readKey: 'resource',
    writeKey: 'resource.write',
  },
  {
    readKey: 'role',
    writeKey: 'role.write',
  },
];
