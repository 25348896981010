import { FormControl, MenuItem, Select, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { Controller, useFormContext } from 'react-hook-form';

import { ampli } from '../../../../../../../Ampli';
import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import { RoleFormSchema } from '../use-role-form-validation';

export const RoleFormCapabilitiesGuideAccess = () => {
  const { t } = useTranslate('settings.team.roles.form');
  const { control } = useFormContext<RoleFormSchema>();

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Text
          fontSize="large"
          variant="medium"
          color={lightTheme.palette.neutral.n400}
        >
          {t('capabilities.guideAccess')}
        </Text>
        <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
          {t('capabilities.guideAccessDescription')}
        </Text>
      </Stack>
      <Controller
        control={control}
        name="guideAccess"
        render={({ field }) => (
          <FormControl variant="outlined" fullWidth>
            <Select
              {...field}
              onChange={(e) => {
                field.onChange(e);
                ampli.rolesFlowViewEventsChanged({
                  view_events_setting: e.target.value,
                });
              }}
              sx={{ height: 48 }}
            >
              <MenuItem value="all">
                <Text fontSize="small">{t('capabilities.guideAccessAll')}</Text>
              </MenuItem>
              <MenuItem value="own">
                <Text fontSize="small">{t('capabilities.guideAccessOwn')}</Text>
              </MenuItem>
            </Select>
          </FormControl>
        )}
      />
    </Stack>
  );
};
