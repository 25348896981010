import { Grid, MenuItem, Stack, TextField } from '@mui/material';
import { Button, lightTheme } from '@understory-io/pixel';
import { useTranslation } from 'react-i18next';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { Language } from '../../../../../i18n/config';
import { Variant, VariantAddon } from '../../../schemas/ticketSchema';
import { createErrorObject } from '../../utils/form-helpers';
import { VatRegistrationPopover } from './vat-registration-popover';

interface TicketAddonProps {
  addon: Partial<VariantAddon>;
  vatCategories: { key: string; label: string }[];
  defaultCurrency: string;
  defaultVatCategory: string;
  onDelete: (addonId: string) => void;
  activeLanguage: Language;
  errors?: ReturnType<typeof createErrorObject<Variant>>;
  index: number;
}

export const TICKET_ADDON_ID_PROPERTY_NAME = 'addonId';
export const TICKET_ADDON_NAME_PROPERTY_NAME = 'addonName';
export const TICKET_ADDON_PRICE_PROPERTY_NAME = 'addonPrice';
export const TICKET_ADDON_VAT_CATEGORY_PROPERTY_NAME = 'addonVatCategory';
export const TICKET_ADDON_EXPLANATION_PROPERTY_NAME = 'addonExplanation';

export function TicketAddon({
  addon,
  vatCategories,
  defaultCurrency,
  defaultVatCategory,
  activeLanguage,
  errors,
  index,
  onDelete,
}: TicketAddonProps) {
  const { t } = useTranslate('experience.edit.dialog.tickets.input.label');
  const { t: tError } = useTranslation();
  const currentError = errors?.addons?.[index] as VariantAddon;

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'flex-start',
        borderRadius: 2,
        paddingY: 2,
        paddingX: 1.5,
        backgroundColor: lightTheme.palette.contrast.white,
        boxShadow: lightTheme.shadows.medium,
      }}
    >
      <Grid container spacing={2}>
        <input
          type="hidden"
          name={TICKET_ADDON_ID_PROPERTY_NAME}
          value={addon.id}
        />
        <Grid item xs={12} sm={6}>
          <TextField
            label={t('addonName')}
            placeholder={t('addonName')}
            defaultValue={addon.name?.[activeLanguage]}
            name={`${addon.id}:${TICKET_ADDON_NAME_PROPERTY_NAME}`}
            error={Boolean(currentError?.name?.[activeLanguage])}
            helperText={
              currentError?.name?.[activeLanguage]
                ? tError(currentError?.name?.[activeLanguage])
                : undefined
            }
            fullWidth
            hiddenLabel
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={8} sm={3}>
          <TextField
            label={t('price', {
              currency: t(defaultCurrency?.toUpperCase(), 'utils.currency'),
            })}
            placeholder={t('price', {
              currency: t(defaultCurrency?.toUpperCase(), 'utils.currency'),
            })}
            name={`${addon.id}:${TICKET_ADDON_PRICE_PROPERTY_NAME}`}
            defaultValue={
              typeof addon.priceBreakdown?.vatInclusivePriceCents === 'number'
                ? addon.priceBreakdown?.vatInclusivePriceCents / 100
                : undefined
            }
            error={Boolean(
              currentError?.priceBreakdown?.vatInclusivePriceCents
            )}
            helperText={
              currentError?.priceBreakdown?.vatInclusivePriceCents
                ? tError(
                    currentError?.priceBreakdown
                      ?.vatInclusivePriceCents as unknown as string
                  )
                : undefined
            }
            type="number"
            fullWidth
            hiddenLabel
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4} sm={3}>
          {vatCategories.length > 0 ? (
            <TextField
              label={t('vatCategory')}
              placeholder={t('vatCategory')}
              defaultValue={
                addon.priceBreakdown?.vatSetting?.vatCategory ??
                defaultVatCategory
              }
              name={`${addon.id}:${TICKET_ADDON_VAT_CATEGORY_PROPERTY_NAME}`}
              error={Boolean(
                currentError?.priceBreakdown?.vatSetting?.vatCategory
              )}
              helperText={
                currentError?.priceBreakdown?.vatSetting?.vatCategory
                  ? tError(
                      currentError?.priceBreakdown?.vatSetting?.vatCategory
                    )
                  : undefined
              }
              select
              fullWidth
              hiddenLabel
              InputLabelProps={{ shrink: true }}
            >
              {vatCategories.map(({ key, label }) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <VatRegistrationPopover />
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t('explanation')}
            placeholder={t('explanation')}
            defaultValue={addon.explanation?.[activeLanguage]}
            name={`${addon.id}:${TICKET_ADDON_EXPLANATION_PROPERTY_NAME}`}
            error={Boolean(currentError?.explanation?.[activeLanguage])}
            helperText={
              currentError?.explanation?.[activeLanguage]
                ? tError(currentError?.explanation?.[activeLanguage])
                : undefined
            }
            fullWidth
            hiddenLabel
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        {!!addon.id && (
          <Grid item display="flex" justifyContent="flex-end" xs={12}>
            <Button
              size="small"
              variant="secondary"
              onClick={() => addon.id && onDelete(addon.id)}
            >
              {t('delete', 'experience.edit.dialog.actions')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}
