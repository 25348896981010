import styled from '@emotion/styled';
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from '@mui/icons-material';
import { Box, Button, capitalize, Stack, TextField } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { FC, useMemo, useState } from 'react';
import { FieldPath, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IExperience } from '../../../../Hooks/useExperience';
import { getLocalizedString } from '../../../../Hooks/useLocalizedStringFormatter';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { CreateBookingFormInputs } from '../create-booking-form';

type InformationRequestInputsProps = {
  informationRequests: NonNullable<IExperience['informationRequests']>;
  guestCount: number;
};

export const InformationRequestInputs: FC<InformationRequestInputsProps> = ({
  informationRequests,
  guestCount,
}) => {
  const { t } = useTranslate('dialogs.createBooking');
  const [showInputs, setShowInputs] = useState(false);

  const { bookingRequests, ticketRequests } = useMemo(() => {
    const bookingRequests = informationRequests.filter(
      (request) => request.scope === 'booking'
    );
    const ticketRequests = informationRequests.filter(
      (request) => request.scope === 'ticket'
    );

    return { bookingRequests, ticketRequests };
  }, [informationRequests]);

  if (!guestCount) return null;
  if (!bookingRequests.length && !ticketRequests.length) return null;

  return (
    <Box position="relative">
      <StyledButton
        type="button"
        onClick={() => setShowInputs((show) => !show)}
        endIcon={
          showInputs ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />
        }
      >
        {t('actions.showCustomInputs')}
      </StyledButton>
      {showInputs && (
        <Stack
          gap={4}
          marginTop={2}
          maxHeight={!showInputs ? 150 : 'unset'}
          overflow="hidden"
        >
          {bookingRequests.length > 0 && (
            <RequestInputGroup
              title={t('title', 'eventUpsert.flow.booking')}
              requests={bookingRequests}
              scope="booking"
            />
          )}

          {Array.from({ length: guestCount }).map((_, guestIndex) => (
            <RequestInputGroup
              key={guestIndex}
              guestIndex={guestIndex}
              scope="ticket"
              title={`${capitalize(t('guest', 'utils.generic'))} ${guestIndex + 1}`}
              requests={ticketRequests}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};

const StyledButton = styled(Button)({
  fontWeight: 400,
  height: 'unset',
  marginTop: 16,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  ':hover': {
    background: 'unset',
  },
});

const RequestInputGroup = ({
  title,
  requests,
  scope,
  guestIndex,
}: {
  title: string;
  requests: NonNullable<IExperience['informationRequests']>;
  scope: 'booking' | 'ticket';
  guestIndex?: number;
}) => {
  return (
    <Stack gap={2}>
      <Text>{title}</Text>
      {requests.map((request, index) => {
        const namePath: FieldPath<CreateBookingFormInputs> =
          scope === 'booking'
            ? `informationRequests.bookingRequests.${index}`
            : `informationRequests.ticketRequests.${guestIndex ?? 0}.requests.${index}`;

        return (
          <InformationRequestInput
            key={request.id}
            name={namePath}
            request={request}
          />
        );
      })}
    </Stack>
  );
};

const InformationRequestInput = ({
  name,
  request,
}: {
  name:
    | `informationRequests.bookingRequests.${number}`
    | `informationRequests.ticketRequests.${number}.requests.${number}`;
  request: NonNullable<IExperience['informationRequests']>[number];
}) => {
  const { i18n } = useTranslation();
  const {
    control,
    register,
    // getFieldState does not update unless we listen for errors
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
    getFieldState,
  } = useFormContext<CreateBookingFormInputs>();

  useController({
    name,
    control,
    defaultValue: { ...request, answer: '' },
  });

  const fieldError = getFieldState(name)?.error;

  return (
    <TextField
      {...register(`${name}.answer`)}
      label={getLocalizedString(request.request, i18n.language)}
      fullWidth
      required={request.required}
      InputLabelProps={{ shrink: true }}
      error={!!fieldError}
      helperText={fieldError?.message}
    />
  );
};
