import * as yup from 'yup';

import { localizedSchema } from './genericSchemas';

const selectedOptions = ['yes', 'no'] as const;
type ExperienceSelectOption = (typeof selectedOptions)[number];

export const infoForGuestsSchema = yup.object().shape({
  selectedOptionKey: yup
    .mixed<ExperienceSelectOption>()
    .oneOf([...selectedOptions], 'utils.validationErrors.invalidEnum')
    .default('no'),
  yes: localizedSchema(undefined, true),
});
export type InfoForGuests = yup.InferType<typeof infoForGuestsSchema>;
