import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { AddRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { useState } from 'react';

import { IInvitation } from '../Api/Invitation';
import { UserItem } from '../Components/UserItem/UserItem';
import { DialogWrapper } from '../features/connect/components/dialog-wrapper';
import { useRoles } from '../Hooks/data/useRoles';
import useResponsive from '../Hooks/layout/useResponsive';
import { useTranslate } from '../Hooks/useTranslate';
import { validEmail } from '../Utils/helpers';
import { InputDialog } from './InputDialog';

export const InviteUsersDialog = NiceModal.create(() => {
  const { t } = useTranslate('dialogs.inviteUser');
  const { isSm } = useResponsive();

  const modal = useModal();

  const handleClose = () => modal.remove();

  const [users, setUsers] = useState<IInvitation[]>([]);
  const {
    roles: { isLoading: isLoadingRoles },
    activeRoles: roles,
  } = useRoles();

  const handleDelete = (index: number) => () =>
    setUsers((p) => p.filter((_, i) => i !== index));
  const handleUpdate = (index: number) => (key: 'email' | 'role', value: any) =>
    setUsers((p) =>
      p.map((el, i) => {
        return i === index
          ? { ...el, claims: { ...el.claims, [key]: value } }
          : el;
      })
    );

  const handleSubmit = () => {
    modal.resolve(users);
    modal.remove();
  };

  const handleCreate = async () => {
    try {
      const { email, role, name } = await NiceModal.show<{
        email: string;
        role: string;
        name: string;
      }>(InputDialog, {
        title: t('buttons.addUser'),
        inputs: [
          {
            key: 'name',
            name: t('name', 'utils.generic'),
            props: {
              fullWidth: true,
            },
            rules: {
              required: true,
            },
          },
          {
            key: 'email',
            name: t('email', 'utils.generic'),
            props: {
              fullWidth: true,
              helperText: t('emailHelpText'),
            },
            rules: {
              required: true,
              validate: validEmail,
            },
          },
          {
            key: 'role',
            name: t('role', 'utils.generic'),
            props: {
              fullWidth: true,
              options: roles?.map(({ name, id }) => ({
                label: name,
                key: id,
              })),
              type: 'select',
              helperText: t('roleHelpText'),
            },
            rules: {
              required: true,
            },
          },
        ],
      });

      setUsers((p) => [
        ...p,
        {
          shouldNotify: true,
          claims: {
            email,
            role,
            name,
          },
        },
      ]);
    } catch (err) {
      return;
    }
  };

  return (
    <DialogWrapper
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('title')}
    >
      <Stack gap={4}>
        <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
          {t('description')}
        </Text>
        <Stack gap={3}>
          {users.map((el, i) => {
            return (
              <UserItem
                key={`user-item-${el.claims.email}`}
                name={el.claims.name ?? ''}
                email={el.claims.email}
                role={el.claims.role}
                id={`user-item-${i}`}
                onUpdate={handleUpdate(i)}
                onDelete={handleDelete(i)}
              />
            );
          })}
          <Stack alignItems={'start'}>
            <Button
              size="small"
              variant="secondary"
              disabled={isLoadingRoles}
              leftIcon={<AddRounded fontSize="small" />}
              onClick={handleCreate}
            >
              {users.length > 0
                ? t('buttons.addOneMoreUser')
                : t('buttons.addUser')}
            </Button>
          </Stack>
        </Stack>
        <Button
          size="large"
          variant="primary"
          disabled={users.length === 0}
          onClick={handleSubmit}
        >
          {t('buttons.sendInvitations')}
        </Button>
      </Stack>
    </DialogWrapper>
  );
});
