import NiceModal from '@ebay/nice-modal-react';
import { Stack } from '@mui/material';
import { Button, lightTheme } from '@understory-io/pixel';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useBookingAccess } from '../../../../../Hooks/useBookingAccess';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { canRefundOrder } from '../../../../../Utils/orders';
import { useBookingDetailsContext } from '../../domain/use_booking_details_context';
import { BookingDetailsSummaryBookingTicketsModal } from '../booking_details_summary/ui/booking/ui/BookingDetailsSummaryBookingTicketsModal';
import { useBookingDetailsActions } from './domain/use_booking_details_actions';
import { BookingDetailsActionsMobile } from './ui/BookingDetailsActionsMobile';

export const BookingDetailsActions = () => {
  const { t } = useTranslate('dialogs.booking');
  const flags = useFlags();
  const { isLg } = useResponsive();

  const {
    booking,
    cancelAvailable,
    moveAvailable,
    checkInAvailable,
    receipt: { data: order },
  } = useBookingDetailsContext();
  const { hasWriteAccess } = useBookingAccess({ booking });

  const {
    handleCancel,
    handleRefund,
    handleMoveBooking,
    handleCheckInBooking,
  } = useBookingDetailsActions();

  const showCancelButton =
    hasWriteAccess &&
    booking?.status !== 'cancelled' &&
    (!booking?.startDateTime || new Date(booking.startDateTime) > new Date());

  const showRefundButton = useMemo(() => {
    return hasWriteAccess && canRefundOrder(order);
  }, [order, hasWriteAccess]);

  const showMoveBookingButton =
    hasWriteAccess &&
    flags['featureMoveBooking'] === true &&
    (showRefundButton || showCancelButton);

  const showButtons =
    (showCancelButton || showRefundButton || showMoveBookingButton) &&
    booking?.status !== 'moved';

  const handleCheckIn = () => {
    if (booking?.tickets) {
      NiceModal.show(BookingDetailsSummaryBookingTicketsModal, {
        bookingId: booking.id,
        handleCheckInBooking,
      });
    } else {
      handleCheckInBooking();
    }
  };

  if (!showButtons || !booking) {
    return null;
  }

  if (isLg) {
    return (
      <BookingDetailsActionsMobile
        options={[
          {
            label: t('actions.tertiary'),
            onClick: handleCancel,
            disabled: !cancelAvailable,
            textColor: lightTheme.palette.error.e300,
          },
          {
            label: t('actions.secondary'),
            onClick: () => handleRefund('popover'),
          },
          {
            label: t('actions.moveBooking'),
            onClick: () => handleMoveBooking('popover'),
            disabled: !moveAvailable,
          },
        ]}
      />
    );
  }

  return (
    <Stack direction={'row'} gap={1}>
      {showCancelButton && (
        <Button
          variant={'secondary'}
          size={'medium'}
          onClick={handleCancel}
          disabled={!cancelAvailable}
        >
          {t('actions.tertiary')}
        </Button>
      )}
      {showRefundButton && (
        <Button
          variant={'secondary'}
          size={'medium'}
          onClick={() => handleRefund('button')}
        >
          {t('actions.secondary')}
        </Button>
      )}
      {showMoveBookingButton && (
        <Button
          variant={'secondary'}
          size={'medium'}
          onClick={() => handleMoveBooking('button')}
          disabled={!moveAvailable}
        >
          {t('actions.moveBooking')}
        </Button>
      )}
      {checkInAvailable && (
        <Button size="medium" variant="primary" onClick={handleCheckIn}>
          {t('actions.checkIn')}
        </Button>
      )}
    </Stack>
  );
};
