import { useParams } from 'react-router';

import { GetStorefrontUrlResponse } from '../../../Api/ai-onboarding';
import { PreviewLayout } from '../preview-layout';
import { PreviewLayoutHeader } from '../preview-layout-header';
import { PreviewListLoader } from '../ui/PreviewListLoader';
import { GeneratingPreviewError } from './generating-preview-error';
import { GeneratingPreviewSuccess } from './generating-preview-success';
import { useGeneratingPreviewState } from './use-generating-preview-state';

export const GeneratingPreview = () => {
  const { id } = useParams<{ id: string }>();

  const previewState = useGeneratingPreviewState(id);

  const storefrontUrl = constructPreviewUrl(previewState);

  return (
    <PreviewLayout>
      <PreviewLayoutHeader
        type={previewState.status === 'succeeded' ? 'finished' : 'creating'}
      />
      {previewState.status === 'initiated' && <PreviewListLoader />}
      {previewState.status === 'failed' && <GeneratingPreviewError />}
      {previewState.status === 'succeeded' && (
        <GeneratingPreviewSuccess storefrontUrl={storefrontUrl} />
      )}
    </PreviewLayout>
  );
};

const constructPreviewUrl = (
  previewState: GetStorefrontUrlResponse
): string => {
  if (previewState.status !== 'succeeded') return '';

  const { storefrontUrl, experienceId } = previewState;

  try {
    const url = new URL(storefrontUrl);
    url.pathname = `/experience/${experienceId}`;
    url.searchParams.set('hide-gate', 'true');
    return url.toString();
  } catch (error) {
    return storefrontUrl;
  }
};
