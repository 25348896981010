import { Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import {
  DraftExperience,
  RegularExperience,
} from '@understory-io/experiences-types';
import { lightTheme } from '@understory-io/pixel';
import { MediaItem } from '@understory-io/utils-types';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  redirect,
  useActionData,
  useLoaderData,
  useLocation,
  useNavigate,
  useSubmit,
} from 'react-router';
import { ValidationError } from 'yup';

import {
  publishExperienceDraft,
  saveExperienceDraft,
  updateExperienceMetaData,
} from '../../../Api/Experience';
import { Language } from '../../../i18n/config';
import routes from '../../../Utils/routes';
import { resourceTypesQuery } from '../../resource-management/data/use-resource-types';
import { experienceSchema } from '../schemas';
import { BasicExperienceFields as BasicExperienceFieldsType } from '../schemas/basicExperienceFieldsSchema';
import { Image as ImageType } from '../schemas/mediaSchema';
import { BasicExperienceFields } from './basic-experience-fields';
import EditExperienceErrorDialog from './components/dialogs/edit-experience-error-dialog';
import { LanguageOptions } from './components/language-options';
import { Media } from './components/media/media';
import { DetailsOverview } from './details-overview/details-overview';
import { EditExperienceHeader } from './header/edit-experience-header';
import { MoreOptions } from './more-options';
import {
  getCompanyProfile,
  getExperience,
  getLocations,
  getTags,
} from './queries';
import {
  convertFormDataValue,
  getFormDataValue,
  getLanguageErrors,
} from './utils/form-helpers';
import { getActiveLanguage } from './utils/get-active-language';
import { translateInput } from './utils/translate-input';

export type LoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>;

export const loaderName = 'EditExperienceLoader';

export const loader =
  (client: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const id = params.id;
    if (!id) {
      throw new Response(null, {
        status: 404,
      });
    }

    const [experience, companyProfile, tags] = await Promise.all([
      getExperience(id),
      getCompanyProfile(),
      getTags(),
    ]);

    const locations = await getLocations(companyProfile.id);

    const activeLanguage = getActiveLanguage(request, companyProfile);
    const autoTranslate = experience.autoTranslateEnabled;

    const resourceTypesPromise = client.fetchQuery(resourceTypesQuery());

    return {
      tags,
      experience,
      activeLanguage,
      availableLanguages: companyProfile.languages as Language[],
      locations,
      autoTranslate,
      defaultCurrency: companyProfile.defaultCurrency,
      vatRegistrations: companyProfile.vatCompliance.vatRegistrations,
      resourceTypesPromise,
    };
  };

export type PublishErrors = Record<
  Language | 'general',
  Array<{ key: keyof Partial<RegularExperience>; index?: number }>
>;

type ActionData = {
  publishErrors?: PublishErrors;
};

const ACTION_KEYS = {
  publish: 'publish',
  toggleAutoTranslate: 'toggle-auto-translate',
  autosave: 'autosave',
  media: 'media',
} as const;

const AUTO_TRANSLATION_KEY = 'isAutoTranslateEnabled';

export async function action({ request, params }: ActionFunctionArgs) {
  const id = params.id;
  if (!id) {
    throw new Response(null, {
      status: 400,
    });
  }

  try {
    const [experience, companyProfile] = await Promise.all([
      getExperience(id),
      getCompanyProfile(),
    ]);

    const activeLanguage = getActiveLanguage(request, companyProfile);

    const formData = await request.formData();

    const action = getFormDataValue(formData.get('action'));

    if (action === ACTION_KEYS.publish) {
      try {
        // Validate experience
        experienceSchema.validateSync(experience, { abortEarly: false });

        await publishExperienceDraft(id);
        return redirect(routes.experience.details(experience.id).index);
      } catch (error) {
        if (error instanceof ValidationError) {
          const publishErrors = getLanguageErrors(error);

          return { publishErrors } as ActionData;
        }

        throw error;
      }
    }

    if (action === ACTION_KEYS.media) {
      const medias = JSON.parse(
        formData.get('data') as string
      ) as unknown as MediaItem[];

      try {
        const convertedMedias = medias?.map((media) => {
          if (media.type === 'video') {
            // Currently we dont support youtube videos
            if (media.provider === 'youtube') return;

            return {
              // For our own videos we return the mimeType together with the video
              mimeType: (media as MediaItem & { mimeType: string }).mimeType,
              playback: media.playback,
              preview: '',
              provider: media.provider,
              type: media.type,
            } as ImageType;
          }

          // default to an image
          return {
            mimeType: media.mimeType,
            provider: 'understory',
            type: media.type,
            url: media.url,
            preview: media.preview,
          } as ImageType;
        });

        const experienceToSave = {
          ...experience,
          media: {
            ...experience.media,
            cover: convertedMedias ?? [],
          },
        } as DraftExperience;

        await saveExperienceDraft(id, experienceToSave);
        return null;
      } catch (error) {
        captureException(error);
      }
    }

    if (action === ACTION_KEYS.toggleAutoTranslate) {
      const isEnabled = convertFormDataValue(
        formData.get(AUTO_TRANSLATION_KEY)
      );

      if (typeof isEnabled !== 'boolean') {
        throw new Error('Invalid auto translate value');
      }

      const metaDataToSave = {
        status: experience.status,
        sortOrder: experience.metadata?.sortorder ?? 1,
        autoTranslateEnabled: isEnabled,
      };

      await updateExperienceMetaData(id, metaDataToSave);

      return null;
    }

    if (action === ACTION_KEYS.autosave) {
      const headline = getFormDataValue(formData.get('headline'));
      const locationIds = getFormDataValue(formData.get('locationIds'));
      const visibility = getFormDataValue(formData.get('visibility'));

      const newHeadline = await translateInput(
        headline,
        experience.headline,
        activeLanguage,
        companyProfile.languages as Language[],
        experience.autoTranslateEnabled
      );

      const experienceToSave = {
        ...experience,
        headline: newHeadline,
        locationIds: locationIds.split(',').filter(Boolean),
        visibility: ['public', 'private'].includes(visibility)
          ? (visibility as 'public' | 'private')
          : 'public',
      };

      await saveExperienceDraft(id, experienceToSave);
    }

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export default function EditExperience() {
  const {
    experience,
    locations,
    activeLanguage,
    availableLanguages,
    autoTranslate,
    tags,
    resourceTypesPromise,
  } = useLoaderData<LoaderData>();
  const actionData = useActionData<ActionData>();
  const [shouldTriggerValidation, setShouldTriggerValidation] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const [publishErrors, setPublishErrors] = useState<PublishErrors | undefined>(
    actionData?.publishErrors
  );

  useEffect(() => {
    if (!actionData) return;

    setPublishErrors(actionData.publishErrors);
    setShowErrorDialog(!!actionData.publishErrors);
  }, [actionData]);

  const submit = useSubmit();
  const location = useLocation();
  const navigate = useNavigate();

  // Validate experience after publish
  useEffect(() => {
    if (!actionData?.publishErrors && !shouldTriggerValidation) return;

    setShouldTriggerValidation(true);
    try {
      experienceSchema.validateSync(experience, { abortEarly: false });

      setPublishErrors(undefined);
      setShouldTriggerValidation(false);
    } catch (err) {
      if (err instanceof ValidationError) {
        const publishErrors = getLanguageErrors(err);

        setPublishErrors(publishErrors);
      }
      captureException(err);
    }
  }, [actionData?.publishErrors, experience, shouldTriggerValidation]);

  const handleChangeAutoTranslate = useCallback(
    (isEnabled: boolean) => {
      submit(
        {
          action: ACTION_KEYS.toggleAutoTranslate,
          [AUTO_TRANSLATION_KEY]: isEnabled,
        },
        { method: 'post' }
      );
    },
    [submit]
  );

  const handleChangeLanguage = useCallback(
    (language: Language) => {
      const newParams = new URLSearchParams(location.search);
      newParams.set('language', language);
      navigate(`${location.pathname}?${newParams.toString()}`, {
        replace: true,
      });
    },
    [location, navigate]
  );

  const handleAutoSaveSubmit = useCallback(
    (data: BasicExperienceFieldsType) => {
      submit({ action: ACTION_KEYS.autosave, ...data }, { method: 'post' });
    },
    [submit]
  );

  const handlePublish = useCallback(() => {
    submit({ action: ACTION_KEYS.publish }, { method: 'post' });
  }, [submit]);

  const handleMediaChange = useCallback(
    (data: MediaItem[]) => {
      submit(
        { action: ACTION_KEYS.media, data: JSON.stringify(data) },
        {
          method: 'post',
          encType: 'multipart/form-data',
        }
      );
    },
    [submit]
  );

  return (
    <Stack
      sx={{
        minHeight: '100vh',
      }}
    >
      <EditExperienceHeader
        experience={experience}
        handlePublish={handlePublish}
      />
      <ExperienceFormWrapper>
        <Stack sx={{ alignItems: { xs: 'flex-start', md: 'flex-end' } }}>
          <LanguageOptions
            key={activeLanguage + publishErrors}
            activeLanguage={activeLanguage}
            availableLanguages={availableLanguages}
            handleChangeLanguage={handleChangeLanguage}
            isAutoTranslateEnabled={autoTranslate}
            handleChangeAutoTranslate={handleChangeAutoTranslate}
            publishErrors={publishErrors}
          />
        </Stack>
        <Media experience={experience} handleMediaChange={handleMediaChange} />
        <Stack sx={{ gap: 2 }}>
          <BasicExperienceFields
            key={activeLanguage}
            locations={locations}
            defaultValues={{
              locationIds: experience.locationIds ?? [],
              visibility: experience.visibility,
              headline: experience.headline?.[activeLanguage] ?? '',
            }}
            onSubmit={handleAutoSaveSubmit}
            publishErrors={publishErrors}
            activeLanguage={activeLanguage}
          />
          <DetailsOverview
            experience={experience}
            activeLanguage={activeLanguage}
            tags={tags}
            publishErrors={publishErrors}
            resourceTypesPromise={resourceTypesPromise}
            locations={locations}
          />
        </Stack>
        <MoreOptions experience={experience} />
      </ExperienceFormWrapper>
      {showErrorDialog && (
        <EditExperienceErrorDialog
          experienceId={experience.id}
          errors={publishErrors}
          open={showErrorDialog}
          closeDialog={() => setShowErrorDialog(false)}
        />
      )}
    </Stack>
  );
}

type ExperienceFormWrapperProps = PropsWithChildren;

const CONTENT_MAX_WIDTH_PX = 900;

const ExperienceFormWrapper = ({ children }: ExperienceFormWrapperProps) => {
  return (
    <Stack
      sx={{
        width: '100%',
        flexGrow: 1,
        maxWidth: CONTENT_MAX_WIDTH_PX,
        padding: { xs: 2, md: 4 },
        paddingBottom: { xs: 4, md: 8 },
        backgroundColor: lightTheme.palette.contrast.surface2,
        boxShadow: { xs: 'none', sm: lightTheme.shadows.xlarge },
        borderRadius: { md: '20px 20px 0px 0px' },
        gap: { xs: 4, md: 3 },
        marginX: 'auto',
      }}
    >
      {children}
    </Stack>
  );
};
