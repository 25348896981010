import { CloseRounded } from '@mui/icons-material';
import { Dialog, DialogContent, IconButton, Stack } from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import { Text } from '@understory-io/pixel';
import randomBytes from 'randombytes';
import { ActionFunctionArgs, redirect, useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { createResourceType } from '../../../../Api/resource-management';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { userInfoQuery } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { t } from '../../../../i18n/config';
import { resourceTypesQuery } from '../../data/use-resource-types';
import { CreateResourceTypeForm } from './create-resource-type-form';
import { CreateResourceTypeInputs } from './create-resource-type-inputs';

export const loader = (client: QueryClient) => async () => {
  const user = await client.fetchQuery(userInfoQuery());
  if (!user.companyId) {
    return redirect('/login');
  }
};

export const action =
  (queryClient: QueryClient) =>
  async ({ request }: ActionFunctionArgs) => {
    const loadingToastId = randomBytes(16).toString('hex');
    toast.loading(
      t('resourceManagement.resourceType.createNew.toast.loading'),
      {
        toastId: loadingToastId,
      }
    );

    try {
      const user = await queryClient.fetchQuery(userInfoQuery());
      if (!user.companyId) {
        throw new Error("User doesn't have a company ID");
      }

      const payload = (await request.json()) as CreateResourceTypeInputs;

      await createResourceType({ ...payload, companyId: user.companyId });

      await queryClient.invalidateQueries({
        queryKey: resourceTypesQuery().queryKey,
      });

      toast.dismiss(loadingToastId);

      return redirect('../');
    } catch (error) {
      console.error(error);
      toast.dismiss(loadingToastId);
      toast.error(t('resourceManagement.resourceType.createNew.toast.error'), {
        delay: 500,
      });
      return {
        success: false,
      };
    }
  };

export const CreateResourceTypeDialog = () => {
  const { t } = useTranslate('resourceManagement.resourceType');
  const { isSm } = useResponsive();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('../');
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('createNew.title')}
    >
      <DialogContent sx={{ padding: 4 }}>
        <Stack direction="row" justifyContent="space-between" marginBottom={1}>
          <Text fontSize="xlarge" variant="medium">
            {t('createNew.title')}
          </Text>
          <IconButton sx={{ padding: 0 }} onClick={handleClose} tabIndex={-1}>
            <CloseRounded sx={{ fontSize: { xs: '24px', md: '32px' } }} />
          </IconButton>
        </Stack>
        <CreateResourceTypeForm />
      </DialogContent>
    </Dialog>
  );
};
