import * as yup from 'yup';

export const languageSchema = yup
  .array()
  .of(
    yup
      .string()
      .required('utils.validationErrors.required')
      .typeError('utils.validationErrors.invalidString')
  );
export type language = yup.InferType<typeof languageSchema>;
