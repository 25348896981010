import { AddOutlined } from '@mui/icons-material';
import { IconButton, Stack, StackProps } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { defaultItemStyling } from './media';

interface MediaDropzone extends StackProps {
  open: () => void;
  hasMedia: boolean;
}

const BUTTON_SIZE_PX = 32;
const ICON_SIZE_PX = 16;

export function MediaDropzone({ hasMedia, open, ...props }: MediaDropzone) {
  const { t } = useTranslate('experience.edit.details.media');

  return (
    <Stack
      sx={{
        ...defaultItemStyling,
        aspectRatio: hasMedia ? '1 / 1' : 'initial',
        p: hasMedia ? 0 : 4,
      }}
      {...props}
    >
      <IconButton
        size="small"
        onClick={open}
        sx={{
          borderRadius: 1,
          borderColor: lightTheme.palette.neutral.n200,
          width: BUTTON_SIZE_PX,
          height: BUTTON_SIZE_PX,
          color: lightTheme.palette.contrast.black,
          ':hover': {
            backgroundColor: lightTheme.palette.contrast.surface1,
          },
        }}
      >
        <AddOutlined sx={{ width: ICON_SIZE_PX, height: ICON_SIZE_PX }} />
      </IconButton>
      <Stack>
        <Text
          textAlign="center"
          color={lightTheme.palette.neutral.n400}
          variant="medium"
          fontSize="small"
        >
          {hasMedia ? t('more') : t('title')}
        </Text>
        <Text
          textAlign="center"
          color={lightTheme.palette.neutral.n300}
          variant="normal"
          fontSize="xsmall"
        >
          {t('description')}
        </Text>
      </Stack>
    </Stack>
  );
}
