import { Skeleton } from '@mui/material';
import { FC } from 'react';

import { ResourceManagementListItem } from './resource-management-list-item';

type Props = {
  height?: number;
};

export const ResourceManagementListItemSkeleton: FC<Props> = ({
  height = 50,
}) => {
  return (
    <ResourceManagementListItem padding={0}>
      <Skeleton variant={'rectangular'} height={height} width={'100%'} />
    </ResourceManagementListItem>
  );
};
