import { IExperience } from '../../../Hooks/useExperience';
import { ActionConfigKey } from './experience-details-header';

export const getExperienceActions = async (
  experience: IExperience,
  isShared: boolean
) => {
  const actions: ActionConfigKey[] = [];

  if (experience.status === 'active') {
    actions.push('deactivate');
  }

  if (experience.status === 'inactive') {
    actions.push('activate');
  }

  if (!isShared) {
    if (experience.status === 'draft') {
      actions.push('delete');
    }

    actions.push('edit');

    if (experience.status !== 'draft') {
      actions.push('createEvent');
    }
  }

  return actions;
};
