import { TFunction } from 'i18next';
import * as yup from 'yup';

export const useRoleFormSchemaValidation = (t: TFunction) =>
  yup.object({
    name: yup.string().required(t('required', 'utils.errors')),
    description: yup.string().required(t('required', 'utils.errors')),
    scopes: yup
      .array()
      .of(yup.string())
      .min(1, 'permissions.atLeastOnePermission')
      .required(),
    guideAccess: yup.string().oneOf(['all', 'own']).required(),
    experienceAccessIds: yup.array().of(yup.string().required()).default([]),
  });

export type RoleFormSchema = yup.InferType<
  ReturnType<typeof useRoleFormSchemaValidation>
>;
