import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { DraftExperience } from '@understory-io/experiences-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionFunctionArgs, useActionData } from 'react-router';
import { useRouteLoaderData } from 'react-router';
import { ValidationError } from 'yup';

import { saveExperienceDraft } from '../../../../Api/Experience';
import { useTranslate } from '../../../../Hooks/useTranslate';
import {
  Seat,
  seatSchema,
  SeatType,
  seatTypes,
} from '../../schemas/seatSchema';
import { EditExperienceDialog } from '../components/dialogs/edit-experience-dialog';
import { LoaderData, loaderName } from '../edit-experience';
import { getExperience } from '../queries';
import {
  convertFormDataValue,
  createErrorObject,
  getParticipantCount,
  UNLIMITED_VALUE,
} from '../utils/form-helpers';

const SEAT_COUNT_PROPERTY_NAME = 'seatCount';
const SEAT_TYPE_PROPERTY_NAME = 'type';
const SEAT_MIN_PROPERTY_NAME = 'minParticipants';
const SEAT_MAX_PROPERTY_NAME = 'maxParticipants';
const SEAT_UNLIMITED_PROPERTY_NAME = 'isUnlimited';

export default function CapacityForm() {
  const actionData = useActionData() as ActionData;
  const { experience } = useRouteLoaderData(loaderName) as LoaderData;
  const { t } = useTranslate('experience.edit.dialog.capacity');
  const { t: tError } = useTranslation();
  const [seatType, setSeatType] = useState<SeatType>(
    experience.seats.type ?? 'single'
  );
  const [isFieldDisabled, setIsFieldDisabled] = useState(
    experience.seats.seatCount === UNLIMITED_VALUE
  );

  const handleSeatTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSeatType(event.target.value as SeatType);
  };

  const handleCheckboxChange = (checked: boolean) => {
    setIsFieldDisabled(checked);
  };

  return (
    <EditExperienceDialog
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
      title={t('title')}
      description={t('description')}
    >
      <RadioGroup
        name={SEAT_TYPE_PROPERTY_NAME}
        onChange={handleSeatTypeChange}
        defaultValue={experience.seats.type ?? seatType[0]}
        value={seatType}
      >
        {seatTypes.map((type) => (
          <FormControlLabel
            key={type}
            label={t(`labels.${type}`)}
            value={type}
            control={<Radio />}
          />
        ))}
      </RadioGroup>

      <Stack
        display={seatType === 'group' ? 'flex' : 'none'}
        flexDirection="row"
        gap={2}
        mt={2}
      >
        <TextField
          type="number"
          name={SEAT_MIN_PROPERTY_NAME}
          autoFocus
          hiddenLabel
          defaultValue={experience.seats.minParticipants}
          placeholder="0"
          error={!!actionData?.error?.minParticipants}
          helperText={
            actionData?.error?.minParticipants
              ? tError(actionData?.error?.minParticipants)
              : undefined
          }
          label={t('labels.min')}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          type="number"
          name={SEAT_MAX_PROPERTY_NAME}
          hiddenLabel
          defaultValue={experience.seats.maxParticipants}
          placeholder="0"
          error={!!actionData?.error?.maxParticipants}
          helperText={
            actionData?.error?.maxParticipants
              ? tError(actionData?.error?.maxParticipants)
              : undefined
          }
          label={t('labels.max')}
          InputLabelProps={{ shrink: true }}
        />
      </Stack>
      <Stack mt={2}>
        <TextField
          type="number"
          name={SEAT_COUNT_PROPERTY_NAME}
          hiddenLabel
          autoFocus
          disabled={isFieldDisabled}
          defaultValue={
            isFieldDisabled ? undefined : experience.seats.seatCount
          }
          placeholder="0"
          error={!!actionData?.error?.seatCount}
          helperText={
            actionData?.error?.seatCount
              ? tError(actionData?.error?.seatCount)
              : undefined
          }
          label={t('labels.count')}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <FormControlLabel
          name={SEAT_UNLIMITED_PROPERTY_NAME}
          label="No capacity limit"
          onChange={(_, checked) => handleCheckboxChange(checked)}
          control={<Checkbox defaultChecked={isFieldDisabled} />}
        />
      </Stack>
    </EditExperienceDialog>
  );
}

type ActionData = {
  shouldClose?: boolean;
  error?: Partial<Record<keyof Seat, string>>;
} | null;

export async function action({
  params,
  request,
}: ActionFunctionArgs): Promise<ActionData> {
  const id = params.id;

  if (!id) {
    throw new Response('Invalid id', { status: 400 });
  }

  const experience = await getExperience(id);
  if (!experience) {
    throw new Response('Experience not found', { status: 404 });
  }

  try {
    const formData = await request.formData();
    const seatType = convertFormDataValue(
      formData.get(SEAT_TYPE_PROPERTY_NAME)
    ) as SeatType;
    const seatMin = convertFormDataValue(formData.get(SEAT_MIN_PROPERTY_NAME));
    const seatMax = convertFormDataValue(formData.get(SEAT_MAX_PROPERTY_NAME));
    const seatValue = convertFormDataValue(
      formData.get(SEAT_COUNT_PROPERTY_NAME)
    );
    const seatUnlimited = convertFormDataValue(
      formData.get(SEAT_UNLIMITED_PROPERTY_NAME)
    ) as boolean;

    const seatCount = seatUnlimited ? UNLIMITED_VALUE : (seatValue as number);

    const result = seatSchema.validateSync(
      {
        type: seatType,
        minParticipants: getParticipantCount(seatType, seatMin as number),
        maxParticipants: getParticipantCount(seatType, seatMax as number),
        seatCount: seatCount,
      },
      {
        abortEarly: false,
      }
    );

    const experienceToSave = {
      ...experience,
      seats: result,
    } as DraftExperience;

    await saveExperienceDraft(id, experienceToSave);

    return {
      shouldClose: true,
    };
  } catch (error) {
    if (error instanceof ValidationError) {
      const errorObj = createErrorObject<Seat>(error);

      return {
        error: errorObj,
      };
    }

    return null;
  }
}
