import { Close } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { MediaItem } from '@understory-io/utils-types';

import { DraggableVideoPreview } from '../../../../../Components/DraggableGallery/DraggableVideoPreview/DraggableVideoPreview';
import { getURLFromMediaItem } from '../../../../../Components/DraggableGallery/Media';
import { useTranslate } from '../../../../../Hooks/useTranslate';

type MediaPreviewProps = {
  media: MediaItem;
  handleDelete: (media: MediaItem) => void;
  isCover?: boolean;
};

const BUTTON_SIZE_PX = { xs: 32, md: 24 };
const ICON_SIZE_PX = { xs: 16, md: 12 };

export default function MediaPreview({
  isCover,
  media,
  handleDelete,
}: MediaPreviewProps) {
  const { t } = useTranslate('experience.edit.details.media');
  return (
    <Stack
      sx={{
        backgroundColor: lightTheme.palette.contrast.surface2,
        borderRadius: 2,
        overflow: 'hidden',
        position: 'relative',
        zIndex: 10,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <IconButton
        onClick={() => handleDelete(media)}
        sx={{
          position: 'absolute',
          width: BUTTON_SIZE_PX,
          height: BUTTON_SIZE_PX,
          top: 8,
          right: 8,
          zIndex: 10,
          borderWidth: 0.75,
          borderColor: lightTheme.palette.contrast.surface1,
          color: lightTheme.palette.contrast.black,
          backgroundColor: lightTheme.palette.contrast.white,
          '&:hover': {
            backgroundColor: lightTheme.palette.contrast.surface1,
          },
        }}
      >
        <Close sx={{ width: ICON_SIZE_PX, height: ICON_SIZE_PX }} />
      </IconButton>
      {isCover && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 12,
            left: 12,
            borderRadius: 1,
            paddingX: 1,
            paddingY: 0.5,
            backgroundColor: lightTheme.palette.contrast.surface1,
          }}
        >
          <Text
            color={lightTheme.palette.neutral.n400}
            fontWeight="medium"
            fontSize="xsmall"
          >
            {t('cover')}
          </Text>
        </Box>
      )}
      {media.type === 'image' ? (
        <Box
          component="img"
          src={getURLFromMediaItem(media)}
          sx={{
            objectFit: 'cover',
            aspectRatio: '1 / 1',
          }}
        />
      ) : (
        <DraggableVideoPreview
          styles={videoJSStyles}
          options={{
            autoplay: true,
            loop: true,
            muted: true,
            sources: [
              {
                src: getURLFromMediaItem(media),
                type: 'application/x-mpegURL',
              },
            ],
          }}
        />
      )}
    </Stack>
  );
}

const videoJSStyles = {
  parent: {},
  video: {
    width: '100%',
    height: '100%',
  },
};
