import {
  Box,
  Chip,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { Skeleton, Text } from '@understory-io/pixel';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useContext } from 'react';

import { InfoBox } from '../../../Components/InfoBox/InfoBox';
import { useTicketsAndAddons } from '../../../Hooks/use-tickets-and-addons';
import { IExperience } from '../../../Hooks/useExperience';
import { useLocalizedStringFormatter } from '../../../Hooks/useLocalizedStringFormatter';
import { TEvent } from '../../../Utils/eventHelpers';
import { useResourceTypes } from '../../resource-management/data/use-resource-types';
import { EventDetailsContext } from './EventDetailsView';
import { StyledTable } from './shared';

export const EventResourceAvailability = () => {
  const {
    isLoading: eventIsLoading,
    event,
    experience,
  } = useContext(EventDetailsContext);

  const { debugResourceAvailableOnEventDetails } = useFlags();
  if (!debugResourceAvailableOnEventDetails) return null;

  if (eventIsLoading || !event?.resourceManagement) {
    return null;
  }

  return (
    <InfoBox title="Resource Availability" sx={{ position: 'relative' }}>
      <Box position="absolute" top={20} right={30}>
        <Chip label="Debug view" color="warning" />
      </Box>
      <EventResourceAvailabilityContent
        resourceManagement={event.resourceManagement}
        experience={experience}
      />
    </InfoBox>
  );
};

type EventResourceAvailabilityContentProps = {
  resourceManagement: Exclude<TEvent['resourceManagement'], undefined>;
  experience: IExperience | undefined;
};

const EventResourceAvailabilityContent: FC<
  EventResourceAvailabilityContentProps
> = ({ resourceManagement, experience }) => {
  const { isLoading, data: resourceTypes } = useResourceTypes();

  const allTickets = useTicketsAndAddons(experience);

  const localizer = useLocalizedStringFormatter();

  if (isLoading) {
    return <Skeleton variant={'rounded'} height={100} width={'100%'} />;
  }

  const groupedByResourceTypes = Object.entries(
    resourceManagement.ticketsRequiringResources
  ).reduce<{ [resourceTypeId: string]: { ticketId: string; count: number }[] }>(
    (acc, [ticketId, resourceTypeToCount]) => {
      Object.entries(resourceTypeToCount).forEach(
        ([resourceTypeId, numberOfResourcesRequired]) => {
          acc[resourceTypeId] ||= [];
          acc[resourceTypeId].push({
            ticketId,
            count: numberOfResourcesRequired,
          });
        }
      );

      return acc;
    },
    {}
  );

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr">
      <Stack gap={2}>
        <Text variant="medium" fontSize="small">
          Capacity based on resources
        </Text>
        <StyledTable>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" size="small" padding="none">
                Event
              </TableCell>
              <TableCell size="small" padding="none">
                {resourceManagement.eventAvailability ?? 'No limit'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" size="small" padding="none">
                Booking
              </TableCell>
              <TableCell size="small" padding="none">
                {resourceManagement.bookingAvailability ?? 'No limit'}
              </TableCell>
            </TableRow>
          </TableBody>
        </StyledTable>
      </Stack>

      <Stack gap={2}>
        <Text variant="medium" fontSize="small">
          Resources needed by tickets
        </Text>

        {Object.entries(groupedByResourceTypes).length === 0 && (
          <Text fontSize="small">
            No resource requirements:
            <br />
            Sum of tickets are limited by Booking - addons are unlimited
          </Text>
        )}

        <StyledTable>
          <TableBody>
            {Object.entries(groupedByResourceTypes).map(
              ([resourceTypeId, tickets]) => {
                const resourceType = resourceTypes?.find(
                  ({ resourceTypeId: id }) => id === resourceTypeId
                );
                return (
                  <TableRow key={resourceTypeId}>
                    <TableCell
                      component="th"
                      scope="row"
                      size="small"
                      padding="none"
                    >
                      {resourceType?.name ?? `Unknown (${resourceTypeId})`} (
                      {resourceManagement.resourceAvailability[
                        resourceTypeId
                      ] ?? 'Unknown availability'}
                      )
                    </TableCell>
                    <TableCell size="small" padding="none">
                      <Stack>
                        {tickets.map(({ ticketId, count }) => {
                          const ticket = allTickets.find(
                            ({ id }) => id === ticketId
                          );

                          return (
                            <Box key={ticketId}>
                              {localizer(
                                ticket?.name,
                                `Unknown ticket (${ticketId})`
                              )}{' '}
                              ({count}x)
                            </Box>
                          );
                        })}
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </StyledTable>
      </Stack>
    </Box>
  );
};
