import { Stack } from '@mui/material';
import { Experience } from '@understory-io/experiences-types';
import { Button, LinkButton, Text } from '@understory-io/pixel';
import randomBytes from 'randombytes';
import { useEffect } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import {
  ActionFunctionArgs,
  LoaderFunction,
  redirect,
  useHref,
  useLoaderData,
  useNavigate,
  useNavigation,
  useRouteLoaderData,
} from 'react-router';
import { useLinkClickHandler, useSubmit } from 'react-router';
import { toast } from 'react-toastify';

import {
  getConnectionItem,
  getExperiences,
  updateConnectionItem,
} from '../../../Api';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { useTranslate } from '../../../Hooks/useTranslate';
import { t } from '../../../i18n/config';
import {
  trackConnectEditCompleted,
  trackConnectEditStarted,
} from '../../../tracking/connect/connect-events';
import { DialogWrapper } from '../components/dialog-wrapper';
import {
  SelectExperiences,
  SelectExperiencesInputs,
} from '../components/select-experiences';
import { decodeConnectionPath } from '../list/connection-path-tools';
import { ConnectionDetailsLoaderData } from './connection-details-page';

type LoaderData = {
  sharedExperiences: 'all' | 'none' | 'selection';
  experiences: Promise<Experience[]>;
};

export const loader: LoaderFunction<LoaderData> = async () => {
  const experiences = getExperiences({
    type: 'owned',
  });

  return {
    experiences: experiences,
  };
};

export async function action({ request, params }: ActionFunctionArgs) {
  const loadingToastId = randomBytes(16).toString('hex');
  toast.loading(t('connect.dialog.edit.toast.loading'), {
    toastId: loadingToastId,
  });

  try {
    const json = (await request.json()) as EditDialogFormInputs;

    toast.dismiss(loadingToastId);
    const { companyId, token } = decodeConnectionPath(params.id!);

    const connection = await getConnectionItem(companyId, token);

    await updateConnectionItem({
      companyId,
      token,
      sharedExperiences:
        json.share === 'selection' ? json.experiences : json.share,
    });

    toast.success(t('connect.dialog.edit.toast.success'), {
      autoClose: 5000,
    });

    if (connection.state !== 'disconnected') {
      const oldShare =
        typeof connection.sharedExperiences === 'string'
          ? connection.sharedExperiences
          : 'selection';
      const newShare = json.share;

      const change = `${oldShare} to ${newShare}`; // Ex. "none to selection"
      trackConnectEditCompleted(change);
    }

    return redirect('..');
  } catch (error) {
    console.error(error);
    toast.dismiss(loadingToastId);
    toast.error(t('connect.dialog.edit.toast.error'), { delay: 500 });
    return {
      success: false,
    };
  }
}

type EditDialogFormInputs = SelectExperiencesInputs;

export function EditConnectionDialog() {
  const { t } = useTranslate('connect.dialog.edit');
  const { isSm } = useResponsive();
  const { experiences } = useLoaderData() as LoaderData;
  const { yourSharedExperiences, connectedCompanyLabel } = useRouteLoaderData(
    'connection-details'
  ) as ConnectionDetailsLoaderData;

  const sharedExperiences =
    yourSharedExperiences === null ? 'none' : yourSharedExperiences;

  const closeHref = useHref(`..`);
  const closeLinkClick = useLinkClickHandler(closeHref);
  const submit = useSubmit();
  const { location, state } = useNavigation();

  const navigate = useNavigate();
  const fireOnce = useFireOnce();

  useEffect(() => {
    fireOnce(() => trackConnectEditStarted(connectedCompanyLabel));
  }, [connectedCompanyLabel, fireOnce]);

  const formMethods = useForm<EditDialogFormInputs>({
    defaultValues: {
      share: Array.isArray(sharedExperiences) ? 'selection' : sharedExperiences,
      experiences: Array.isArray(sharedExperiences) ? sharedExperiences : [],
    },
  });

  const { handleSubmit, watch } = formMethods;

  const share = watch('share');
  const selectedExperiences = watch('experiences');

  const disableButton =
    state === 'submitting' ||
    state === 'loading' ||
    (share === 'selection' && selectedExperiences.length === 0);

  const handleClose = () => {
    navigate(closeHref);
  };

  const onSubmit = (data: FieldValues) => {
    submit(data, {
      method: 'post',
      action: location?.pathname,
      encType: 'application/json',
    });
  };

  return (
    <DialogWrapper
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('title')}
    >
      <FormProvider {...formMethods}>
        <Stack
          gap={4}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Text variant="normal" fontSize="small">
            {t('description')}
          </Text>

          <SelectExperiences experiences={experiences} />

          <Stack
            gap={2}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={'space-between'}
            alignItems={{ xs: 'stretch', md: 'stretch' }}
          >
            <LinkButton
              href={closeHref}
              onClick={closeLinkClick}
              variant="secondary"
              size="large"
              fullWidth
              style={{
                flexShrink: 'unset',
              }}
            >
              {t('cancel')}
            </LinkButton>
            <Button
              type="submit"
              variant="primary"
              size="large"
              fullWidth
              disabled={disableButton}
              style={{
                flexShrink: 'unset',
              }}
            >
              {t('confirm')}
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </DialogWrapper>
  );
}
