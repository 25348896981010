import { Skeleton, Stack } from '@mui/material';
import { Button, lightTheme, LinkButton, Text } from '@understory-io/pixel';
import randomBytes from 'randombytes';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  Await,
  Form,
  redirect,
  useHref,
  useLinkClickHandler,
  useNavigate,
  useNavigation,
  useRouteLoaderData,
} from 'react-router';
import { toast } from 'react-toastify';

import { initiatePartialRefund } from '../../../../Api/Receipt';
import { cancelGiftCard, getGiftCard } from '../../../../Api/Voucher';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useFireOnce } from '../../../../Hooks/useFireOnce';
import { t } from '../../../../i18n/config';
import {
  giftCardRefundFlowCompleted,
  giftCardRefundFlowStarted,
} from '../../../../tracking/giftCards/giftCardEvents';
import routes from '../../../../Utils/routes';
import { DialogWrapper } from '../../../connect/components/dialog-wrapper';
import { LoaderData as GiftCardDetailsLoaderData } from '../gift-card-details-page';

export default function RefundGiftCarddialog() {
  const { giftCard, isRefundable } = useRouteLoaderData(
    'gift-card-details'
  ) as GiftCardDetailsLoaderData;

  const { t } = useTranslation();

  // Intentionally throwing error so the error element is shown
  if (!giftCard.receiptId)
    throw new Error(t('giftCard.refund.error.noReceipt'));
  if (giftCard.amountLeftCents <= 0)
    throw new Error(t('giftCard.refund.error.noBalance'));

  const { state } = useNavigation();
  const isSubmitting = state === 'submitting';

  const { isSm } = useResponsive();

  const closeHref = useHref(routes.giftCard.details(giftCard.id).index);
  const closeLinkClick = useLinkClickHandler(closeHref);
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(closeHref);
  };

  const fireOnce = useFireOnce();
  useEffect(() => {
    fireOnce(() => {
      giftCardRefundFlowStarted();
    });
  }, [fireOnce]);

  return (
    <DialogWrapper
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('giftCard.refund.title')}
    >
      <Suspense fallback={<Skeleton variant="rectangular" height={100} />}>
        <Await resolve={isRefundable}>
          {(isRefundable) => {
            if (!isRefundable)
              throw new Error(t('giftCard.refund.error.isRefunded'));

            return (
              <>
                <Text color={lightTheme.palette.neutral.n400}>
                  {t('giftCard.refund.description')}
                </Text>
                <Stack
                  component={Form}
                  method="post"
                  noValidate
                  sx={{ gap: 4, marginTop: 4 }}
                >
                  <Stack
                    sx={{
                      gap: 2,
                      flexDirection: { xs: 'column-reverse', md: 'row' },
                    }}
                  >
                    <LinkButton
                      href={closeHref}
                      onClick={closeLinkClick}
                      variant="secondary"
                      size="large"
                      fullWidth
                      style={{
                        flexShrink: 'unset',
                      }}
                      disabled={isSubmitting}
                    >
                      {t('giftCard.refund.cancelLabel')}
                    </LinkButton>
                    <Button
                      type="submit"
                      variant="primary"
                      size="large"
                      fullWidth
                      loading={isSubmitting}
                      style={{
                        flexShrink: 'unset',
                      }}
                    >
                      {t('giftCard.refund.submitLabel')}
                    </Button>
                  </Stack>
                </Stack>
              </>
            );
          }}
        </Await>
      </Suspense>
    </DialogWrapper>
  );
}

export async function action({ params }: ActionFunctionArgs) {
  const loadingToastId = randomBytes(16).toString('hex');
  toast.loading(t('giftCard.refund.toast.loading'), {
    toastId: loadingToastId,
  });

  const id = params.id;

  if (!id) {
    throw new Response('Invalid id', { status: 404 });
  }

  try {
    const giftCard = await getGiftCard(id);
    if (!giftCard) throw new Error('Gift card not found');

    try {
      if (!giftCard.receiptId) throw new Error('No order id on gift card');
      await initiatePartialRefund(giftCard.receiptId, giftCard.amountLeftCents);
    } catch (error) {
      console.log(error);
      toast.dismiss(loadingToastId);
      toast.error(t('giftCard.deactivate.toast.refundFailed'), {
        delay: 500,
      });
      // Aborting rest of the flow on failure
      return null;
    }

    if (giftCard.status !== 'cancelled') {
      await cancelGiftCard(id);
    }

    toast.dismiss(loadingToastId);
    toast.success(t('giftCard.refund.toast.success'), {
      autoClose: 5000,
    });

    // Intentionally not awaiting the event. Optimistic tracking.
    giftCardRefundFlowCompleted();

    return redirect(routes.giftCard.details(id).index);
  } catch (error) {
    toast.dismiss(loadingToastId);
    toast.error(t('giftCard.deactivate.toast.error'), { delay: 500 });
    return null;
  }
}
