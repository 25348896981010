import NiceModal from '@ebay/nice-modal-react';
import { AutoAwesomeOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { RegularExperience } from '@understory-io/experiences-types';
import { lightTheme, Skeleton } from '@understory-io/pixel';
import {
  MenuButton,
  MenuButtonProps,
  useRichTextEditorContext,
} from 'mui-tiptap';
import { Suspense } from 'react';
import { Await } from 'react-router';

import { ampli } from '../../../../../../Ampli';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { Language } from '../../../../../../i18n/config';
import { GenerationDialog } from '../../../../../../Modals/GenerationDialog';
import { getExperience } from '../../../queries';

interface MenuButtonAIProps extends MenuButtonProps {
  experienceId: string;
  activeLanguage: Language;
}

export function MenuButtonAI({
  experienceId,
  activeLanguage,
  sx,
  ...props
}: MenuButtonAIProps) {
  const { t } = useTranslate('utils.toolbar.ai');
  const experiencePromise = getExperience(experienceId);
  const editor = useRichTextEditorContext();

  const handleClick = async (experience: RegularExperience) => {
    ampli.generateDescriptionOpen();

    const res = await NiceModal.show<string>(GenerationDialog, {
      experienceId: experience.id,
      experienceName: experience.headline?.[activeLanguage] ?? '',
      language: activeLanguage,
      instantReturn: false,
    });

    editor?.chain().setContent(res).run();
  };

  return (
    <Suspense
      fallback={<Skeleton variant="rectangular" width={76.5} height={35} />}
    >
      <Await resolve={experiencePromise}>
        {(experience) => (
          <MenuButton
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 0.5,
              border: '1px solid',
              '&.MuiToggleButton-root': {
                border: {
                  xs: 'none',
                  sm: `1px solid ${lightTheme.palette.neutral.n100} !important`,
                },
              },
              ...sx,
            }}
            disabled={!editor?.isEditable}
            onClick={() => handleClick(experience)}
            {...props}
          >
            <AutoAwesomeOutlined sx={{ fontSize: 20 }} />
            <Box
              component="span"
              sx={{
                display: {
                  xs: 'none',
                  sm: 'inline-flex',
                },
              }}
            >
              {t('label')}
            </Box>
          </MenuButton>
        )}
      </Await>
    </Suspense>
  );
}
