import { mapValues } from 'lodash';
import * as yup from 'yup';

import { Language } from '../../../i18n/config';

export const localizedSchema = (activeLanguage?: Language, optional = false) =>
  yup.lazy((obj) =>
    yup
      .object(
        mapValues(obj, (value, key) => {
          if (optional) return yup.string().default('');

          if (!activeLanguage) {
            return yup.string().required('utils.validationErrors.required');
          }

          if (key === activeLanguage) {
            return yup.string().required('utils.validationErrors.required');
          }

          return yup.string().default('');
        })
      )
      .test({
        name: 'min',
        params: { min: 1 },
        message: 'utils.validationErrors.required',
        test: (value) =>
          optional ? true : value && Object.keys(value).length >= 1,
      })
  );
export type Localized = yup.InferType<ReturnType<typeof localizedSchema>>;

export const localizedInputSchema = yup
  .string()
  .typeError('utils.validationErrors.invalidString')
  .required('utils.validationErrors.required')
  .min(1, 'utils.validationErrors.minLength');
export type LocalizedInput = yup.InferType<typeof localizedInputSchema>;
