import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { CloseRounded } from '@mui/icons-material';
import { Dialog, Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';

import useResponsive from '../../../../../../../../Hooks/layout/useResponsive';
import { useBookings } from '../../../../../../../../Hooks/useBookings';
import { useTranslate } from '../../../../../../../../Hooks/useTranslate';
import { useTicketGroups } from '../domain/use-ticket-groups';
import { BookingDetailsSummaryBookingTicketsModalList } from './BookingDetailsSummaryBookingTicketsModalList';

type BookingDetailsSummaryBookingTicketsModalProps = {
  bookingId: string;
  language?: string;
  handleCheckInBooking: (ticketId?: string) => Promise<void>;
};

export const BookingDetailsSummaryBookingTicketsModal =
  NiceModal.create<BookingDetailsSummaryBookingTicketsModalProps>(
    ({ bookingId, language, handleCheckInBooking }) => {
      const { t } = useTranslate('dialogs.booking.tickets');

      const { isSm } = useResponsive();

      const modal = useModal();

      const { enrichedBooking: booking } = useBookings(undefined, bookingId);

      const { activeTickets, checkedInTickets, cancelledTickets } =
        useTicketGroups(booking?.tickets);

      const handleClose = () => {
        modal.reject('StripeDialog');
        modal.hide();
      };

      return (
        <Dialog
          open={modal.visible}
          onClose={handleClose}
          PaperProps={{ sx: { maxWidth: 800, minWidth: isSm ? 0 : 500 } }}
          fullScreen={isSm}
        >
          <Stack gap={3} p={4}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Text fontSize={'xlarge'} variant="medium">
                {t('title')}
              </Text>
              <CloseRounded onClick={handleClose} sx={{ cursor: 'pointer' }} />
            </Stack>

            <Stack gap={3}>
              {activeTickets && activeTickets.length > 0 && (
                <BookingDetailsSummaryBookingTicketsModalList
                  booking={booking}
                  tickets={activeTickets}
                  language={language}
                  checkInTicket={handleCheckInBooking}
                />
              )}
              {checkedInTickets && checkedInTickets.length > 0 && (
                <BookingDetailsSummaryBookingTicketsModalList
                  booking={booking}
                  tickets={checkedInTickets}
                  language={language}
                  checkInTicket={handleCheckInBooking}
                />
              )}
              {cancelledTickets && cancelledTickets.length > 0 && (
                <BookingDetailsSummaryBookingTicketsModalList
                  booking={booking}
                  tickets={cancelledTickets}
                  language={language}
                  checkInTicket={handleCheckInBooking}
                />
              )}
            </Stack>
          </Stack>
        </Dialog>
      );
    }
  );
