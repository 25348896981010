import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { CheckIn } from '@understory-io/utils-types';

import { TBooking } from '../../../Hooks/useBookings';
import { TicketCheckingIn } from './ticket-checking-in';
import { TicketCheckinSuccess } from './ticket-checking-success';
import { TicketDetails } from './ticket-details';
import { TicketError } from './ticket-error';

type TicketPopoverContentProps = {
  isCheckedIn: boolean;
  checkInLoading: boolean;
  qr: {
    bookingId: string;
    ticketId?: string;
  } | null;
  booking: UseQueryResult<TBooking | undefined, Error>;
  checkInBooking: UseMutationResult<
    void,
    Error,
    {
      id: string;
      method: CheckIn['method'];
      ticketId?: string;
    },
    void
  >;
  onClose: () => void;
  onCheckIn: () => void;
};

export const TicketPopoverContent = ({
  isCheckedIn,
  checkInLoading,
  qr,
  booking,
  checkInBooking,
  onClose,
  onCheckIn,
}: TicketPopoverContentProps) => {
  if (isCheckedIn) return <TicketCheckinSuccess />;
  if (checkInLoading) return <TicketCheckingIn />;

  if (qr === null)
    return <TicketError error="invalid-format" onClose={onClose} />;

  if (booking.error || checkInBooking.isError)
    return <TicketError error="load-fail" onClose={onClose} />;

  if (booking.data && !isCheckedIn && !checkInLoading) {
    return (
      <TicketDetails
        booking={booking.data}
        isCheckInLoading={checkInLoading}
        ticketId={qr?.ticketId}
        onCheckIn={onCheckIn}
        onClose={onClose}
      />
    );
  }

  return null;
};
