import { captureException } from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Experience } from '@understory-io/experiences-types';

import * as api from '../Api';
import { ExperienceQueryOptions } from '../Api/Experience';
import { experienceSchema } from '../Pages/SyiPage/config';
import { useOnBoarding } from './useOnBoarding';
import { useProfile } from './useProfile';

export type Localized = Record<string, string>;

/** This is just an alias for now to limit number of files changed here */
export type IExperience = Experience;

export type Variant = IExperience['price']['variants'][number];

export const useExperience = (
  id?: string,
  options?: ExperienceQueryOptions
) => {
  const queryClient = useQueryClient();

  const {
    company: { data: company },
  } = useProfile();
  const { updateStep } = useOnBoarding();

  const ExperienceQueryKey = ['experience', company?.id, id];
  const ExperiencesQueryKey = ['experiences', company?.id, options];

  const experience = useQuery({
    queryKey: ExperienceQueryKey,
    queryFn: async () => {
      return await api.getExperience(id!);
    },
    enabled: !!id,
  });

  const experiences = useQuery({
    queryKey: ExperiencesQueryKey,
    queryFn: async () => {
      const experiences = await api.getExperiences(options);
      for (const experience of experiences) {
        queryClient.setQueryData(
          ['experience', company?.id, experience.id],
          experience
        );
      }
      return experiences;
    },

    enabled: Boolean(company?.id),
  });

  const updateExperience = useMutation({
    mutationFn: (data: IExperience) => api.updateExperience(id!, data),

    onMutate: async (data) => {
      await queryClient.cancelQueries({
        queryKey: ExperienceQueryKey,
      });

      const previous =
        queryClient.getQueryData<IExperience>(ExperienceQueryKey);

      queryClient.setQueryData<IExperience[]>(ExperiencesQueryKey, (prev) => {
        if (!id) return prev ?? [];

        const mapped =
          prev?.map((el) => {
            return el.id === data.id ? { ...data } : el;
          }) ?? [];
        return [
          ...mapped,
          ...(data.id
            ? []
            : [
                {
                  ...data,
                  id,
                },
              ]),
        ];
      });

      queryClient.setQueryData<IExperience>(ExperienceQueryKey, (prev) => {
        return { ...prev!, ...data };
      });

      return { previous };
    },

    onError: (err, variables, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData<IExperience>(
          ExperienceQueryKey,
          context.previous
        );
      }

      captureException(err, (scope) => {
        scope.setTransactionName('Failed to update experience');
        return scope;
      });
    },

    onSuccess: async (data, variables) => {
      await updateStep(
        { ...variables, id },
        experienceSchema(),
        'experience',
        'create'
      );
    },

    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: ['getLocations', id],
      });
      queryClient.invalidateQueries({
        queryKey: ['experiences'],
      });
      queryClient.invalidateQueries({
        queryKey: ['events', 'bookings'],
      });
    },
  });

  const deleteExperience = useMutation({
    mutationFn: (id: string) => api.deleteExperience(id),

    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ExperienceQueryKey,
      });

      queryClient.setQueryData<IExperience[]>(ExperiencesQueryKey, (prev) => {
        return prev?.filter((el) => el.id !== id) ?? [];
      });
    },

    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ExperienceQueryKey,
      });
      experiences.refetch();
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['experiences'],
      });
      queryClient.invalidateQueries({
        queryKey: ['events', 'bookings'],
      });
    },
  });

  return {
    experience,
    experiences,
    updateExperience,
    deleteExperience,
  };
};
