import { Dialog, DialogProps, Stack } from '@mui/material';
import { Button } from '@understory-io/pixel';

import type { PublishErrors } from '../../edit-experience';
import { ErrorContext } from '../error-context';
import { defaultStyles, DialogHeading } from './edit-experience-dialog';

interface EditExperienceErrorDialogProps extends DialogProps {
  errors?: PublishErrors;
  experienceId: string;
  closeDialog: () => void;
}

export default function EditExperienceErrorDialog({
  open,
  closeDialog,
  errors,
  experienceId,
  ...props
}: EditExperienceErrorDialogProps) {
  return (
    <Dialog
      onClose={closeDialog}
      open={open}
      PaperProps={{
        style: defaultStyles,
      }}
      {...props}
    >
      <ErrorContext
        experienceId={experienceId}
        errors={errors}
        closeDialog={closeDialog}
        render={({ title, description, actions }) => (
          <>
            <DialogHeading
              title={title}
              description={description}
              handleClose={closeDialog}
            />
            <Stack
              sx={{
                '& > *': {
                  flexGrow: 1,
                },
              }}
              flexDirection="row"
              gap={2}
            >
              {actions.map(({ label, variant, onClick }) => (
                <Button
                  key={label}
                  size="large"
                  variant={variant}
                  onClick={onClick}
                >
                  {label}
                </Button>
              ))}
            </Stack>
          </>
        )}
      />
    </Dialog>
  );
}
