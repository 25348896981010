import {
  Role,
  SavedRole,
  UnderstoryApiCollection,
  UnderstoryApiSingleResource,
} from '@understory-io/utils-types';

import { apiServices } from './apiServices';
import api from './index';

export const getRoles = async () => {
  if (typeof apiServices.accounts === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  const { data } = await api.get<UnderstoryApiCollection<Role>>(
    `${apiServices.accounts}/roles`
  );

  return data.items;
};

export const getRole = async (id: string) => {
  if (typeof apiServices.accounts === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  const { data } = await api.get<UnderstoryApiSingleResource<SavedRole>>(
    `${apiServices.accounts}/roles/${id}`
  );

  return data.item;
};

export const createRole = async (
  role: Omit<SavedRole, 'roleId' | 'companyId' | 'status'>
) => {
  if (typeof apiServices.accounts === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  const { data } = await api.post<UnderstoryApiSingleResource<SavedRole>>(
    `${apiServices.accounts}/roles`,
    role
  );

  return data.item;
};

export const updateRole = async (
  roleId: string,
  role: Omit<SavedRole, 'roleId' | 'companyId'>
) => {
  if (typeof apiServices.accounts === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  await api.put(`${apiServices.accounts}/roles/${roleId}`, role);
};
