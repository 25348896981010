import { VisibilityOutlined } from '@mui/icons-material';
import { alpha, Stack } from '@mui/material';
import { RegularExperience } from '@understory-io/experiences-types';
import { Button, lightTheme } from '@understory-io/pixel';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigation } from 'react-router';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import routes from '../../../../Utils/routes';
import { useNavigateWithParams } from '../utils/use-navigate-with-params';
import { AutoSaveStatus } from './auto-save-status';
import { GoBack } from './go-back';

type EditExperienceHeaderProps = {
  experience: RegularExperience;
  handlePublish: () => void;
};

export const EditExperienceHeader = ({
  experience,
  handlePublish,
}: EditExperienceHeaderProps) => {
  const { isSm } = useResponsive();
  const { t } = useTranslate('experience.edit.details.label');
  const { state } = useNavigation();
  const navigateWithParams = useNavigateWithParams();

  const [isPublishing, setIsPublishing] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const handleClickPublish = useCallback(() => {
    setIsPublishing(true);
    handlePublish();
  }, [handlePublish]);

  useEffect(() => {
    if (!isPublishing) return;

    if (state === 'idle') {
      setIsPublishing(false);
    }
  }, [isPublishing, state]);

  const handleScroll = useCallback(() => {
    setIsScrolling(window.scrollY > 0);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const scrollOpacity = useMemo(() => (isScrolling ? 0.5 : 1), [isScrolling]);

  return (
    <Stack
      sx={{
        position: 'sticky',
        backdropFilter: isScrolling ? 'blur(20px)' : undefined,
        backgroundColor: {
          xs: alpha(lightTheme.palette.contrast.surface2, scrollOpacity),
          md: alpha(lightTheme.palette.contrast.surface1, scrollOpacity),
        },
        top: 0,
        zIndex: 15,
        padding: { xs: 2, md: 4 },
        paddingBottom: { md: 3 },
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 5,
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          gap: { xs: 3, md: 5 },
          alignItems: 'center',
        }}
      >
        <GoBack experienceId={experience.id} />
        <AutoSaveStatus />
      </Stack>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
        <Button
          variant="secondary"
          size="small"
          onClick={() =>
            navigateWithParams(
              routes.experience.details(experience.id).edit.preview
            )
          }
          leftIcon={<VisibilityOutlined />}
        >
          {!isSm && t('preview', 'dialogs.booking.notifications')}
        </Button>
        <Button
          variant="primary"
          size="small"
          onClick={handleClickPublish}
          loading={isPublishing}
        >
          {t('publish')}
        </Button>
      </Stack>
    </Stack>
  );
};
