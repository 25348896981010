import { Experience } from '@understory-io/experiences-types';
import * as yup from 'yup';

import { getAllTicketsFromVariants } from '../../../Utils/ticket';

type SchemaArguments = {
  tickets: ReturnType<typeof getAllTicketsFromVariants>;
  locationIds: Experience['locationIds'];
};

export const resourceRuleSchema = ({ tickets, locationIds }: SchemaArguments) =>
  yup.object().shape({
    ruleId: yup.string().required(),
    allocationType: yup
      .string()
      .oneOf(['event', 'booking', 'ticket'])
      .required(),
    resourceTypeIdByLocation: yup
      .object()
      .required()
      .test(
        'resourceMissingLocation',
        'Resource type must have valid locations',
        function (value: any, context) {
          if (!locationIds) return true;

          const errors = locationIds.flatMap((locationId) => {
            if (value[locationId]) return [];

            return this.createError({
              path: `${context.path}.${locationId}`,
              message: 'Resource type must have valid locations, ' + locationId,
            });
          });

          if (errors.length === 0) {
            return true;
          }

          return new yup.ValidationError(errors);
        }
      ),
    ticketId: yup
      .string()
      .optional()
      .when('allocationType', {
        is: (value: string) => value === 'ticket',
        then: () =>
          yup
            .string()
            .required()
            .test(
              'invalidTicketId',
              'Ticket id is missing or invalid',
              function (ticketId, context) {
                if (
                  !ticketId ||
                  !tickets.find((ticket) => ticket.id === ticketId)
                ) {
                  return this.createError({
                    message: 'Ticket id is missing or invalid',
                    path: `${context.path}.ticketId`,
                  });
                }

                return true;
              }
            ),
      }),
  });

export const resourcesRuleSchema = (args: SchemaArguments) =>
  yup.object().shape({
    version: yup.number().oneOf([1]).required(),
    rules: yup.array(resourceRuleSchema(args)).required(),
  });
