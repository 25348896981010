import * as yup from 'yup';

import { getAllTicketsFromVariants } from '../../../Utils/ticket';
import { cutoffSchema } from './cutoffSchema';
import { headlineSchema } from './headlineSchema';
import { infoForGuestsSchema } from './infoForGuestsSchema';
import { informationRequestSchema } from './information-request-schema';
import { languageSchema } from './languageSchema';
import { locationSchema } from './locationSchema';
import { mediaSchema } from './mediaSchema';
import { practicalInfoSchema } from './practicalInfoSchema';
import { resourcesRuleSchema } from './resourceRuleSchema';
import { seatSchema } from './seatSchema';
import { statusSchema } from './statusSchema';
import { tagSchema } from './tagSchema';
import { ticketSchema } from './ticketSchema';
import { visibilitySchema } from './visibilitySchema';
import { whatsIncludedSchema } from './whatsIncludedSchema';

export const experienceSchema = yup.object().shape({
  // Ids
  id: yup.string().required(), // This field should always be included
  companyId: yup.string().required(), // This field should always be included
  ownerExperienceId: yup.string().required(), // This field should always be included
  locationIds: locationSchema.optional(),
  tagIds: tagSchema.optional(),
  // Visible settings
  status: statusSchema,
  visibility: visibilitySchema,
  // General info - used for storefront
  headline: headlineSchema,
  practicalInfo: practicalInfoSchema,
  infoForGuests: infoForGuestsSchema.optional(),
  whatsIncluded: whatsIncludedSchema,
  media: mediaSchema.optional(),
  informationRequests: yup.array(informationRequestSchema()).optional(),
  languages: languageSchema.optional(),
  // Ticket specific
  price: ticketSchema,
  seats: seatSchema,
  cutoff: cutoffSchema.optional(),
  resourceRules: yup
    .mixed()
    .when(['locationIds', 'price.variants'], ([locationIds, variants]) =>
      resourcesRuleSchema({
        locationIds,
        tickets: getAllTicketsFromVariants(variants),
      })
        .optional()
        .default(undefined)
    ),
});

export type Experience = yup.InferType<typeof experienceSchema>;
