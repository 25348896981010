import { Close } from '@mui/icons-material';
import {
  Button as MuiButton,
  Dialog,
  Fade,
  IconButton,
  Slide,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { forwardRef, PropsWithChildren, useMemo } from 'react';
import { Form, useLocation, useNavigate, useNavigation } from 'react-router';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import routes from '../../../../../Utils/routes';
import { useNavigateWithParams } from '../../utils/use-navigate-with-params';

type DialogContentProps = {
  title?: string;
  description?: string;
  shouldClose?: boolean;
  experienceId: string;
  isDelete?: boolean;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  noValidate?: boolean;
};

type EditExperienceDialogProps = PropsWithChildren & DialogContentProps;
const DIALOG_BORDER_RADIUS = 16;
export const fullscreenStyles = {
  overflow: 'auto',
  position: 'fixed',
  padding: 16,
  paddingTop: 24,
  bottom: 0,
  width: '100%',

  borderTopLeftRadius: DIALOG_BORDER_RADIUS,
  borderTopRightRadius: DIALOG_BORDER_RADIUS,
} as const;

export const defaultStyles = {
  width: '100%',
  maxWidth: 750,
  padding: 24,
} as const;

export const EditExperienceDialog = ({
  title,
  description,
  children,
  shouldClose,
  experienceId,
  isDelete,
  onSubmit,
  noValidate,
}: EditExperienceDialogProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateWithParams = useNavigateWithParams();
  const { state } = useNavigation();
  const isSm = useMediaQuery('@media(max-width: 749px)');

  const isMobile = isSm;

  const disableButton = state === 'submitting' || state === 'loading';

  if (shouldClose) {
    navigateWithParams(routes.experience.details(experienceId).edit.index, {
      replace: true,
    });
  }

  const handleClose = () => {
    if (location.key === 'default') {
      navigateWithParams(routes.experience.details(experienceId).edit.index, {
        replace: true,
      });
    } else {
      navigate(-1);
    }
  };

  const hideControls = isMobile && !isDelete;

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullWidth
      maxWidth={false}
      TransitionComponent={isMobile ? Transition : Fade}
      fullScreen={isMobile}
      disableRestoreFocus
      PaperProps={{
        style: isMobile
          ? { ...fullscreenStyles, height: isDelete ? 'auto' : '90%' }
          : defaultStyles,
      }}
    >
      <Form method="post" onSubmit={onSubmit} noValidate={noValidate}>
        {isMobile && !isDelete && (
          <DialogControlsMobile
            disableButton={disableButton}
            handleClose={handleClose}
          />
        )}
        <DialogHeading
          title={title}
          description={description}
          handleClose={handleClose}
        />
        {children}
        {!hideControls && (
          <DialogControls
            isDelete={isDelete}
            disableButton={disableButton}
            handleClose={handleClose}
          />
        )}
      </Form>
    </Dialog>
  );
};

interface DialogControlsMobileProps {
  handleClose: () => void;
  disableButton?: boolean;
}

const customButtonStyles = {
  height: 'auto',
  padding: 0,
  fontWeight: '400',
  minWidth: 0,
};

function DialogControlsMobile({
  handleClose,
  disableButton = false,
}: DialogControlsMobileProps) {
  const { t } = useTranslate('experience.edit.dialog.actions');
  return (
    <Stack
      sx={{
        gap: 2,
        mb: 3,
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'end',
        }}
      >
        <MuiButton
          sx={{
            ...customButtonStyles,
            fontWeight: 400,
            color: disableButton
              ? lightTheme.palette.neutral.n200
              : lightTheme.palette.neutral.n300,
          }}
          variant="text"
          size="small"
          onClick={disableButton ? undefined : handleClose}
        >
          {t('cancel')}
        </MuiButton>
        <MuiButton
          sx={{
            ...customButtonStyles,
            fontWeight: 500,
            color: lightTheme.palette.action.a300,
          }}
          disabled={disableButton}
          type="submit"
          variant="text"
          size="small"
        >
          {t('save')}
        </MuiButton>
      </Stack>
    </Stack>
  );
}

interface DialogControlsProps
  extends Pick<EditExperienceDialogProps, 'isDelete'> {
  disableButton: boolean;
  handleClose: () => void;
}

function DialogControls({
  isDelete,
  disableButton,
  handleClose,
}: DialogControlsProps) {
  const { t } = useTranslate('experience.edit.dialog.actions');

  return (
    <Stack
      sx={{
        bottom: 0,
        left: 0,
        right: 0,
        mb: 2,
        mt: 3,
        gap: 2,
        flexDirection: { xs: 'column-reverse', sm: 'row' },
      }}
    >
      {isDelete && (
        <Button
          type="button"
          size="large"
          variant="secondary"
          disabled={disableButton}
          onClick={handleClose}
          fullWidth
          style={{
            flexShrink: 'unset',
          }}
        >
          {t('cancel')}
        </Button>
      )}
      <Button
        type="submit"
        size="large"
        variant={isDelete ? 'danger' : 'primary'}
        loading={disableButton}
        fullWidth
        style={{
          flexShrink: 'unset',
        }}
      >
        {isDelete ? t('delete') : t('save')}
      </Button>
    </Stack>
  );
}

interface DialogHeadingProps
  extends Pick<DialogContentProps, 'title' | 'description'> {
  handleClose: () => void;
}

export function DialogHeading({
  title,
  description,
  handleClose,
}: DialogHeadingProps) {
  const hasContent = useMemo(
    () => !!title || !!description,
    [title, description]
  );

  return (
    <Stack
      sx={{
        position: 'relative',
        gap: 1,
        mb: hasContent ? 3 : 0,
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: title ? 'space-between' : 'flex-end',
          alignItems: 'flex-start',
        }}
      >
        {title && (
          <Text
            color={lightTheme.palette.contrast.black}
            variant="medium"
            fontSize="xlarge"
          >
            {title}
          </Text>
        )}
        <IconButton
          onClick={handleClose}
          size="small"
          sx={{
            display: { xs: 'none', sm: 'block' },
            position: hasContent ? 'unset' : 'absolute',
            zIndex: 10,
            top: 8,
            right: 0,
            color: lightTheme.palette.contrast.black,
          }}
        >
          <Close />
        </IconButton>
      </Stack>
      {description && (
        <Text
          color={lightTheme.palette.neutral.n400}
          variant="normal"
          fontSize="small"
        >
          {description}
        </Text>
      )}
    </Stack>
  );
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
