import { Stack, styled } from '@mui/material';
import { lightTheme, Skeleton, Text } from '@understory-io/pixel';
import { EventResourceRule } from '@understory-io/utils-types';
import { FC, useMemo } from 'react';
import { Link } from 'react-router';

import { useTicketsAndAddons } from '../../../Hooks/use-tickets-and-addons';
import { IExperience } from '../../../Hooks/useExperience';
import { useLocalizedStringFormatter } from '../../../Hooks/useLocalizedStringFormatter';
import { useTranslate } from '../../../Hooks/useTranslate';
import routes from '../../../Utils/routes';
import { useResourceTypes } from '../../resource-management/data/use-resource-types';

type Props = {
  rules: Array<EventResourceRule>;
  experience?: IExperience;
};
export const ResourceRulesDetails: FC<Props> = ({ rules, experience }) => {
  const tickets = useTicketsAndAddons(experience);

  const resourceTypes = useResourceTypes();

  const localizer = useLocalizedStringFormatter();

  const sortedRules = useMemo(() => sortResourceRules(rules), [rules]);

  const { t } = useTranslate('experience.edit.details');

  if (resourceTypes.isLoading || !resourceTypes.data) {
    return (
      <div>
        {Array.from({ length: rules.length }).map((_, index) => (
          <Skeleton key={index} variant={'text'} height="21px" width="200px" />
        ))}
      </div>
    );
  }

  return (
    <div>
      {sortedRules.map((rule) => {
        const { ruleId, allocationType, resourceTypeId } = rule;
        const resourceType = resourceTypes.data?.find(
          ({ resourceTypeId: id }) => id === resourceTypeId
        );

        if (!resourceType) return null;

        const ticketName =
          allocationType === 'ticket' &&
          tickets.find(({ id }) => id === rule.ticketId)?.name;

        return (
          <Stack key={ruleId} direction="row" gap={1}>
            <UnderlineLink
              to={routes.resourceManagement.details(resourceTypeId).index}
            >
              {resourceType.name}
            </UnderlineLink>
            <Text
              variant="medium"
              fontSize="small"
              color={lightTheme.palette.neutral.n300}
            >
              ({t(`resourceRules.per.${allocationType}`)}
              {ticketName ? `: ${localizer(ticketName)}` : undefined})
            </Text>
          </Stack>
        );
      })}
    </div>
  );
};

const UnderlineLink = styled(Link)`
  text-decoration: underline;
  text-underline-offset: auto;
  text-underline-position: under;
`;

const sortOrder = {
  event: 1,
  booking: 2,
  ticket: 3,
};
function sortResourceRules(resourceRules: Array<EventResourceRule>) {
  return resourceRules.sort(
    (a, b) => sortOrder[a.allocationType] - sortOrder[b.allocationType]
  );
}
