import { RegularExperience } from '@understory-io/experiences-types';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  redirect,
  useActionData,
  useLoaderData,
  useRouteLoaderData,
} from 'react-router';

import { saveExperienceDraft } from '../../../../../Api/Experience';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import routes from '../../../../../Utils/routes';
import { EditExperienceDialog } from '../../components/dialogs/edit-experience-dialog';
import {
  LoaderData as RouterLoaderData,
  loaderName,
} from '../../edit-experience';
import { getExperience } from '../../queries';
import { useNavigateWithParams } from '../../utils/use-navigate-with-params';

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export async function loader({ params }: LoaderFunctionArgs) {
  const { ruleId } = params;

  return { ruleId };
}

export function DeleteResourceRule() {
  const navigate = useNavigateWithParams();

  const { ruleId } = useLoaderData<LoaderData>();
  const { experience } = useRouteLoaderData(loaderName) as RouterLoaderData;
  const actionData = useActionData() as ActionData;
  const { t } = useTranslate('experience.edit.dialog.deleteResourceRule');

  const rule = experience.resourceRules?.rules?.find(
    (rule) => rule.ruleId === ruleId
  );

  if (!rule) {
    navigate(routes.experience.details(experience.id).edit.index);
    return null;
  }

  return (
    <EditExperienceDialog
      title={t('title')}
      description={t('description')}
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
      isDelete
    />
  );
}

type ActionData = {
  shouldClose?: boolean;
} | null;

export const action = async ({ params }: ActionFunctionArgs) => {
  const id = params.id;
  const ruleId = params.ruleId;

  if (!id) {
    return redirect(routes.experience.overview);
  }

  if (!ruleId) {
    return redirect(routes.experience.details(id).edit.index);
  }

  try {
    const experience = await getExperience(id);

    const rules = experience.resourceRules?.rules ?? [];

    const experienceToSave: RegularExperience = {
      ...experience,
      resourceRules: {
        version: 1,
        rules: rules.filter((rule) => rule.ruleId !== ruleId),
      },
    };

    await saveExperienceDraft(id, experienceToSave);

    return { shouldClose: true };
  } catch (error) {
    // TODO: Error handling
    return null;
  }
};
