import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box } from '@mui/material';
import { MediaItem as MediaItemType } from '@understory-io/utils-types';
import { memo } from 'react';
import { useState } from 'react';

import { MediaItem } from './MediaItem';

type LocalMediaItem = MediaItemType & { localUrl?: string; mimeType?: string };

interface MediaProps {
  onUpdate?: (arr: LocalMediaItem[]) => void;
  onDelete?: (mediaItem: MediaItemType) => void;
  media: LocalMediaItem[];
}

export const Media = memo(({ media, onUpdate, onDelete }: MediaProps) => {
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 200, tolerance: 10 },
    })
  );

  const [activeSrc, setActiveSrc] = useState<string | null>(null);

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveSrc(active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!!over && active.id !== over.id) {
      onUpdate?.(
        arrayMove(
          media,
          active.data.current?.sortable.index,
          over.data.current?.sortable.index
        )
      );
    }

    setActiveSrc(null);
  };

  const items = media.map(getURLFromMediaItem);

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(165px, 1fr))',
          gridGap: 8,
        }}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={items}>
            {media.map((mediaItem) => {
              const url = getURLFromMediaItem(mediaItem);
              const mimeType = getMimeTypeFromMediaItem(mediaItem);

              return (
                <SortableMedia
                  key={url}
                  source={url}
                  isLocal={!!mediaItem.localUrl}
                  id={url}
                  onDelete={() => onDelete?.(mediaItem)}
                  type={mediaItem.type}
                  mimeType={mimeType}
                />
              );
            })}
          </SortableContext>
          <DragOverlay>
            {activeSrc ? (
              <MediaItem isMoving={true} source={activeSrc} />
            ) : null}
          </DragOverlay>
        </DndContext>
      </Box>
    </>
  );
});

Media.displayName = 'Media';

const SortableMedia = (
  props: React.ComponentPropsWithoutRef<typeof MediaItem>
) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isOver,
    isSorting,
  } = useSortable({ id: props.id ?? props.source });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.4 : 1,
  };

  return (
    <MediaItem
      {...props}
      ref={setNodeRef}
      sx={style}
      isMoving={isSorting || isDragging || isOver}
      isLocal={!!props.isLocal}
      attributes={attributes}
      listeners={listeners}
    />
  );
};

export const getURLFromMediaItem = (mediaItem: LocalMediaItem) => {
  if (mediaItem.localUrl) return mediaItem.localUrl;

  let url = '';

  if (mediaItem.type === 'image') {
    url = mediaItem.url;
  } else {
    switch (mediaItem.provider) {
      case 'holdbar':
        url = mediaItem.playback.hls;
        break;
      case 'understory':
        url = mediaItem.playback.hls;
        break;
      default:
        url = '';
    }
  }

  return url;
};

export const getMimeTypeFromMediaItem = (mediaItem: LocalMediaItem) => {
  return mediaItem?.mimeType?.includes('quicktime')
    ? 'video/mp4'
    : mediaItem?.mimeType;
};
