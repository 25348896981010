import { Checkbox, Stack, styled } from '@mui/material';
import { RegularExperience } from '@understory-io/experiences-types';
import { Text } from '@understory-io/pixel';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { f } from 'react-router/dist/development/fog-of-war-DLtn2OLr';

import { Tooltip } from '../../../../../../../Components/tooltip/tooltip';
import { useExperience } from '../../../../../../../Hooks/useExperience';
import { theme } from '../../../../../../../Utils/theme';
import { useEventUpsert } from '../../../../domain/event_upsert_context';
import { EventUpsertTimeFormTypes } from './use_time_form_validation';

export const EventUpsertTimeFormEndTimeToggle = () => {
  const { t } = useTranslation();
  const { control, setValue, watch } =
    useFormContext<Pick<EventUpsertTimeFormTypes, 'endDateTimeDisabled'>>();
  const { updateEventField, currentEvent } = useEventUpsert();
  const {
    experience: { data: experience },
  } = useExperience(currentEvent.experienceId);

  const endDateTimeDisabled = watch('endDateTimeDisabled');

  const toggleCheckbox = () => {
    if (!canToggleEndDate) {
      return;
    }

    const newValue = !endDateTimeDisabled;
    setValue('endDateTimeDisabled', newValue);
    updateEventField('endDateTimeDisabled', newValue);
  };

  const canToggleEndDate = useMemo(
    () => (experience?.resourceRules?.rules.length ?? 0) === 0,
    [experience?.resourceRules]
  );

  const cursorPointer = canToggleEndDate ? 'pointer' : 'default';
  const toolTip = !canToggleEndDate
    ? t('eventUpsert.flow.time.form.endDateToggle.tooltip')
    : undefined;

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={0.5}
      onClick={toggleCheckbox}
      sx={{
        cursor: cursorPointer,
        width: 'fit-content',
      }}
    >
      <Controller
        name="endDateTimeDisabled"
        control={control}
        render={({ field }) => (
          <Checkbox
            {...field}
            checked={field.value}
            disabled={!canToggleEndDate}
            sx={{
              cursor: cursorPointer,
              padding: 0,
            }}
            onClick={(e) => e.stopPropagation()}
          />
        )}
      />
      <Tooltip title={toolTip}>
        <Text
          fontSize="small"
          style={{
            cursor: cursorPointer,
            color: !canToggleEndDate ? theme.palette.text.disabled : undefined,
          }}
        >
          {t('eventUpsert.flow.time.form.endDateToggle.label')}
        </Text>
      </Tooltip>
    </Stack>
  );
};
