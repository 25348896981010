import { Box, Card, Stack, Tooltip, Typography } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { useState } from 'react';
import { useController } from 'react-hook-form';

import * as api from '../../../../../Api';
import { ShowForScope } from '../../../../../Components/AllowForScope/AllowForScope';
import { useProfile } from '../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { DomainPopover } from '../domain-popover';

export const CompanyFormDomainWidget = () => {
  const { t } = useTranslate('settings.company.domain');

  const { companyProfileUrl } = useProfile();

  const [domainAnchor, setDomainAnchor] = useState<HTMLElement | null>(null);
  const handleClosePopover = () => setDomainAnchor(null);

  const { field: domains } = useController({ name: 'domains' });

  const handleSubmitDomain = (value: string) => {
    domains.onChange([value]);
    api.createDomain({ domain: value, type: 'subdomain' });
    handleClosePopover();
  };
  const [domain] = domains.value ?? [];
  const url = companyProfileUrl(domain);

  const handleChangeDomain = () => {
    setDomainAnchor(document.getElementById('domain-anchor'));
  };

  return (
    <Stack
      component={Card}
      position={'relative'}
      width={'100%'}
      maxWidth={420}
      minWidth={360}
      gap={2}
    >
      <Typography variant={'h5'} fontWeight={700}>
        {t('title')}
      </Typography>
      <Tooltip title={url} placement={'top'} arrow>
        <a href={url} target={'_blank'} rel="noreferrer">
          <Box
            p={2}
            pb={1}
            pt={1}
            component={Typography}
            borderRadius={1}
            border={'1px solid #C7C7CC'}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {companyProfileUrl(domain)}
          </Box>
        </a>
      </Tooltip>
      <ShowForScope scopes={[`company.write`]}>
        <Button
          variant={'primary'}
          size={'medium'}
          width={'fit-content'}
          id={'domain-anchor'}
          onClick={handleChangeDomain}
        >
          {t('changeDomain')}
        </Button>
      </ShowForScope>
      {domainAnchor && (
        <DomainPopover
          anchorEl={domainAnchor}
          domain={domain}
          onClose={handleClosePopover}
          onSubmit={handleSubmitDomain}
        />
      )}
    </Stack>
  );
};
