import { Stack } from '@mui/material';
import { Skeleton } from '@understory-io/pixel';
import { FC } from 'react';

const SKELETON_LABEL_HEIGHT = '21px';
const SKELETON_LABEL_WIDTH = '175px';
const SKELETON_SELECT_HEIGHT = '45px';

export const ResourceTypeSelectSkeleton: FC = () => {
  return (
    <Stack gap={2} sx={{ width: '100%' }}>
      <Skeleton
        width={SKELETON_LABEL_WIDTH}
        height={SKELETON_LABEL_HEIGHT}
        variant="text"
      />

      <Skeleton
        variant={'rounded'}
        height={SKELETON_SELECT_HEIGHT}
        width={'100%'}
      />
    </Stack>
  );
};
