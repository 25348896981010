import { CircularProgress, Slide, Stack, styled } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { FC, useState } from 'react';

import { useBookings } from '../../../Hooks/useBookings';
import { useReceipt } from '../../../Hooks/useReceipt';
import { trackBookingCheckInCompleted } from '../../../tracking/bookings/details';
import { deserializeQr } from '../../../Utils/understory-qr-deserializer';
import { TicketPopoverContent } from './ticket-popover-content';

const SUCCESS_MESSAGE_DURATION_MS = 500;

type Props = {
  containerRef: React.RefObject<HTMLDivElement | null>;
  qrCodeValue: string | null;
  onClose: () => void;
};

export const TicketPopover: FC<Props> = ({
  containerRef,
  qrCodeValue,
  onClose,
}) => {
  const qr = deserializeQr(qrCodeValue ?? '');
  const { booking, checkInBooking } = useBookings(undefined, qr?.bookingId);
  const { receipt } = useReceipt(qr?.bookingId);
  const [checkInLoading, setCheckInLoading] = useState(false);
  const [isCheckedIn, setIsCheckedIn] = useState(false);

  async function handleCheckIn() {
    if (!booking?.data?.id) {
      return;
    }

    try {
      setCheckInLoading(true);
      await checkInBooking.mutateAsync({
        id: booking?.data?.id,
        method: 'backoffice-qr-scan',
        ticketId: qr?.ticketId,
      });
      setCheckInLoading(false);
      setIsCheckedIn(true);

      trackBookingCheckInCompleted('qr_code', booking.data, receipt.data);

      setTimeout(() => {
        onClose();
        setTimeout(() => setIsCheckedIn(false), 200);
      }, SUCCESS_MESSAGE_DURATION_MS);
    } catch {
      setCheckInLoading(false);
    }
  }

  if (booking.isLoading) {
    return <CircularProgress sx={{ color: lightTheme.palette.neutral.n100 }} />;
  }

  return (
    <Slide in={!!qrCodeValue} container={containerRef.current} direction="up">
      <StyledStack>
        <TicketPopoverContent
          isCheckedIn={isCheckedIn}
          checkInLoading={checkInLoading}
          qr={qr}
          booking={booking}
          checkInBooking={checkInBooking}
          onClose={onClose}
          onCheckIn={handleCheckIn}
        />
      </StyledStack>
    </Slide>
  );
};

const StyledStack = styled(Stack)`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(3)};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: white;
  display: flex;
  align-items: stretch;
  min-height: 60vh;
`;
