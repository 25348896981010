import { Stack } from '@mui/material';

import {
  AllowGuestsCancelBookingFeature,
  AllowGuestsMoveBookingFeature,
  BookingReminderFeature,
  EventRequestsFeature,
  GiftCardsFeature,
  PassOnBookingFeeFeature,
  ShowUpcomingEventsInStorefrontFeature,
  TicketingFeature,
} from './features';

export const StorefrontFeaturesSection = () => {
  return (
    <Stack gap={3}>
      <GiftCardsFeature />
      <EventRequestsFeature />
      <AllowGuestsMoveBookingFeature />
      <AllowGuestsCancelBookingFeature />
      <ShowUpcomingEventsInStorefrontFeature />
      <BookingReminderFeature />
      <PassOnBookingFeeFeature />
      <TicketingFeature />
    </Stack>
  );
};
