import NiceModal from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import { AddRounded, ClearRounded } from '@mui/icons-material';
import { IconButton, Skeleton, Stack } from '@mui/material';
import { lightTheme, LinkButton, Text } from '@understory-io/pixel';
import { Role } from '@understory-io/utils-types';
import {
  Link,
  Outlet,
  useHref,
  useLinkClickHandler,
  useNavigate,
} from 'react-router';
import { toast } from 'react-toastify';

import { ampli } from '../../../../Ampli';
import { DialogWrapper } from '../../../../features/connect/components/dialog-wrapper';
import { useRoles } from '../../../../Hooks/data/useRoles';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { ConfirmDialog } from '../../Integrations/ui/shared/confirm-dialog';

const STANDARD_ROLE_IDS = ['admin', 'manager', 'guide'];

export const SettingsTeamRolesDialog = () => {
  const { t } = useTranslate('settings.team.roles');
  const { isSm } = useResponsive();
  const navigate = useNavigate();
  const { activeRoles: roles } = useRoles();

  const handleClose = () => {
    navigate('../');
  };

  const createRoleLink = useHref('create');
  const createRoleClickHandler = useLinkClickHandler(createRoleLink);

  const standardRoles =
    roles?.filter((role) => STANDARD_ROLE_IDS.includes(role.id)) ?? [];
  const customRoles =
    roles?.filter((role) => !STANDARD_ROLE_IDS.includes(role.id)) ?? [];

  return (
    <DialogWrapper
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('editRoles')}
    >
      <Stack gap={4} mt={4}>
        {roles ? (
          <>
            <Stack gap={1.5}>
              <Text fontSize="xsmall" color={lightTheme.palette.neutral.n400}>
                {t('standard')}
              </Text>
              <StyledStack gap={3}>
                <Stack gap={3}>
                  {standardRoles.map((role) => (
                    <Stack key={role.id} gap={1} direction={'row'}>
                      <Text
                        fontSize="small"
                        variant="medium"
                        style={{ minWidth: '30%' }}
                      >
                        {role.name}
                      </Text>
                      <Text fontSize="small">
                        {t(`${role.id}.description`)}
                      </Text>
                    </Stack>
                  ))}
                </Stack>
              </StyledStack>
            </Stack>
            <Stack gap={1.5} alignItems="flex-start">
              {customRoles.length > 0 && (
                <>
                  <Text
                    fontSize="xsmall"
                    color={lightTheme.palette.neutral.n400}
                  >
                    {t('custom')}
                  </Text>
                  {customRoles.map((role) => (
                    <RoleListItem key={role.id} role={role} />
                  ))}
                </>
              )}
              <LinkButton
                href={createRoleLink}
                variant="primary"
                size="small"
                leftIcon={<AddRounded />}
                onClick={(event) => {
                  createRoleClickHandler(event);
                  ampli.rolesCreateRoleClicked();
                }}
              >
                {t('addNewRole')}
              </LinkButton>
            </Stack>
          </>
        ) : (
          <Skeleton variant="rectangular" height={120} />
        )}
      </Stack>
      <Outlet />
    </DialogWrapper>
  );
};

const RoleListItem = ({ role }: { role: Role }) => {
  const { t } = useTranslate('settings.team.roles');
  const { disableRole } = useRoles();

  const handleDeleteRole = (e: React.MouseEvent, role: Role) => {
    e.preventDefault();
    ampli.rolesDeleteRoleStarted();

    NiceModal.show(ConfirmDialog, {
      title: t('title', 'dialogs.confirmDelete'),
      description: t('headline', 'dialogs.confirmDelete', {
        type: `"${role.name}"`,
      }),
      cta: t('primary', 'dialogs.confirmDelete.actions'),
      onConfirm: async () => {
        try {
          await disableRole(role);
          toast.success(t('success', 'toast.saving'));
          ampli.rolesDeleteRoleCompleted();
        } catch {
          toast.error(t('error', 'toast.saving'));
        }
      },
    });
  };

  return (
    <Link
      to={`edit/${role.id}`}
      onClick={() => ampli.rolesEditRoleClicked()}
      style={{ width: '100%' }}
    >
      <StyledStack
        direction={'row'}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" gap={1} alignItems="center" sx={{ flex: 1 }}>
          <Text fontSize="small" variant="medium" style={{ minWidth: '30%' }}>
            {role.name}
          </Text>
          <Text fontSize="small">{role.description}</Text>
        </Stack>
        <IconButton
          onClick={(e) => handleDeleteRole(e, role)}
          sx={{
            color: lightTheme.palette.neutral.n300,
            '&:hover': {
              color: lightTheme.palette.neutral.n500,
            },
          }}
        >
          <ClearRounded fontSize="small" />
        </IconButton>
      </StyledStack>
    </Link>
  );
};

const StyledStack = styled(Stack)`
  border: 1px solid ${lightTheme.palette.neutral.n100};
  border-radius: 12px;
  padding: 16px;
`;
