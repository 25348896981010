import { Stack, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { ampli } from '../../../../../../Ampli';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { RoleFormSchema } from './use-role-form-validation';

export const RoleFormDetails = () => {
  const { t } = useTranslate('settings.team.roles.form');
  const {
    register,
    formState: { errors },
  } = useFormContext<RoleFormSchema>();

  return (
    <Stack gap={2} mt={3}>
      <TextField
        {...register('name')}
        label={t('name')}
        error={!!errors.name}
        helperText={errors.name?.message}
        onFocus={() => ampli.rolesFlowNameOpened()}
        fullWidth
      />
      <TextField
        {...register('description')}
        label={t('description')}
        error={!!errors.description}
        helperText={errors.description?.message}
        onFocus={() => ampli.rolesFlowDescriptionOpened()}
        multiline
        rows={2}
        fullWidth
      />
    </Stack>
  );
};
