import { RegularExperience } from '@understory-io/experiences-types';
import { ValidationError } from 'yup';

import { isLanguage, Language, t } from '../../../../i18n/config';
import { SeatType } from '../../schemas/seatSchema';
import { CustomSelectProps } from '../components/custom-select';
import { PublishErrors } from '../edit-experience';

export function getFormDataValue(input: FormDataEntryValue | null) {
  if (typeof input !== 'string') return '';
  return input.trim();
}

export function convertFormDataValue(input: FormDataEntryValue | null) {
  if (!input) return undefined;

  // Convert to string first
  const value = input.toString().trim();

  // Handle empty string
  if (value === '') return undefined;

  // Handle boolean values
  if (value.toLowerCase() === 'true') return true;
  if (value.toLowerCase() === 'false') return false;

  // Handle numeric values
  const numberValue = parseInt(value);
  if (!isNaN(numberValue)) return numberValue;

  // Return as string if no other type matches
  return value;
}

export function hasLocalizedValue(obj: unknown) {
  if (!obj || typeof obj !== 'object') return false;

  return Object.values(obj).some(
    (value) => typeof value === 'string' && value.trim() !== ''
  );
}

export const UNLIMITED_VALUE = Number.MAX_SAFE_INTEGER;
export function isUnlimited(value: number) {
  return value === UNLIMITED_VALUE;
}

function parsePathSegments(path: string): string[] {
  return path
    .replace(/\[(\d+)\]/g, '.$1') // convert [0] to .0
    .split('.')
    .filter(Boolean);
}

function setNestedValue(obj: any, path: string, value: string) {
  const segments = parsePathSegments(path);
  let current = obj;

  for (let i = 0; i < segments.length - 1; i++) {
    const key = segments[i];
    const nextKey = segments[i + 1];
    const isNextKeyNumeric = /^\d+$/.test(nextKey);

    if (isNextKeyNumeric && !Array.isArray(current[key])) {
      current[key] = [];
    } else if (!isNextKeyNumeric && !current[key]) {
      current[key] = {};
    }

    current = current[key];
  }

  const lastKey = segments[segments.length - 1];
  current[lastKey] = value;
}

export function createErrorObject<T extends object>(
  error: ValidationError
): Partial<Record<keyof T, any>> {
  const errorObj: Partial<Record<keyof T, any>> = {};

  error.inner.forEach((err) => {
    if (err.path) {
      setNestedValue(errorObj, err.path, err.message);
    }
  });

  return errorObj;
}

export const getParticipantCount = (
  seatType: SeatType,
  value: number | undefined
): number | undefined => {
  switch (seatType) {
    case 'single':
      return undefined;
    case 'couple':
      return 2;
    case 'group':
      return value;
    default:
      return undefined;
  }
};

export function getLanguageErrors(error: ValidationError) {
  const languageErrors: PublishErrors = {
    da: [],
    en: [],
    no: [],
    sv: [],
    de: [],
    nl: [],
    general: [],
  };

  error.inner.forEach((err) => {
    if (err.path) {
      const segments = parsePathSegments(err.path);

      const lastKey = segments[segments.length - 1];

      // Find index of relevant item, e.g. ticket or information request
      const possibleIndex = segments.find((segment) => !isNaN(Number(segment)));
      const index = possibleIndex ? Number(possibleIndex) : undefined;

      if (isLanguage(lastKey)) {
        languageErrors[lastKey].push({
          key: segments[0] as keyof RegularExperience,
          index,
        });
      } else {
        languageErrors.general.push({
          key: segments[0] as keyof RegularExperience,
          index,
        });
      }
    }
  });

  for (const key of Object.keys(languageErrors)) {
    if (languageErrors[key as Language].length === 0) {
      delete languageErrors[key as Language];
    }
  }

  return languageErrors;
}

export const renderSelectValue = (
  value: CustomSelectProps['selectedValue'],
  options: CustomSelectProps['options'],
  multiple: CustomSelectProps['multiple'],
  emptyLabel: CustomSelectProps['emptyLabel']
) => {
  if (!value || value.length === 0) return emptyLabel;

  if (Array.isArray(value) && multiple) {
    if (value.length > 1) {
      return `${value.length} ${t('experience.edit.details.selected')}`;
    }
    return options.find((option) => option.value === value[0])?.label;
  }

  return options.find((option) => option.value === value)?.label;
};
