import type { EditorOptions } from '@tiptap/core';
import { Bold } from '@tiptap/extension-bold';
import { BulletList } from '@tiptap/extension-bullet-list';
import { Document } from '@tiptap/extension-document';
import { HardBreak } from '@tiptap/extension-hard-break';
import { Heading } from '@tiptap/extension-heading';
import { History } from '@tiptap/extension-history';
import { Italic } from '@tiptap/extension-italic';
import { Link } from '@tiptap/extension-link';
import { ListItem } from '@tiptap/extension-list-item';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Text } from '@tiptap/extension-text';
import { Underline } from '@tiptap/extension-underline';
import { LinkBubbleMenuHandler } from 'mui-tiptap';
import { useMemo } from 'react';
import { Markdown } from 'tiptap-markdown';

// Available extensions can be found here: https://tiptap.dev/docs/editor/extensions/overview

type UseEditorExtensionsOptions = {
  placeholder?: string;
};

export default function useEditorExtensions({
  placeholder,
}: UseEditorExtensionsOptions = {}): EditorOptions['extensions'] {
  return useMemo(() => {
    return [
      Document,
      Paragraph,
      Text,
      Link.configure({
        openOnClick: false,
        HTMLAttributes: {
          style: 'text-decoration: underline;',
        },
      }),
      LinkBubbleMenuHandler,
      ListItem,
      BulletList,
      OrderedList,
      Bold,
      Italic,
      Underline,
      Heading.configure({ levels: [1, 2] }),
      Placeholder.configure({ placeholder }),
      HardBreak,
      History,
      Markdown,
    ];
  }, [placeholder]);
}
