import { captureException } from '@sentry/react';
import { DraftExperience } from '@understory-io/experiences-types';
import { lightTheme, Text } from '@understory-io/pixel';
import { useState } from 'react';
import {
  type ActionFunctionArgs,
  useActionData,
  useRouteLoaderData,
} from 'react-router';
import { ValidationError } from 'yup';

import { saveExperienceDraft } from '../../../../Api/Experience';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { Language } from '../../../../i18n/config';
import { localizedSchema } from '../../schemas/genericSchemas';
import { EditExperienceDialog } from '../components/dialogs/edit-experience-dialog';
import MarkdownEditor from '../components/markdown-editor/markdown-editor';
import { LoaderData, loaderName } from '../edit-experience';
import { getCompanyProfile, getExperience } from '../queries';
import { getFormDataValue } from '../utils/form-helpers';
import { getActiveLanguage } from '../utils/get-active-language';
import { translateInput } from '../utils/translate-input';

const PROPERTY_NAME = 'infoForGuests';

export default function PracticalInfoDialog() {
  const actionData = useActionData() as ActionData;
  const { experience, activeLanguage } = useRouteLoaderData(
    loaderName
  ) as LoaderData;

  const { t } = useTranslate('experience.edit.dialog.practicalInfo');

  const [practicalInfo, setPracticalInfo] = useState(
    experience.infoForGuests?.yes?.[activeLanguage] ?? ''
  );

  return (
    <EditExperienceDialog
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
    >
      <input name={PROPERTY_NAME} type="hidden" value={practicalInfo} />
      <MarkdownEditor
        autoFocus={true}
        placeholder={t('input.placeholder')}
        value={practicalInfo}
        onChange={(e) => {
          setPracticalInfo(e);
        }}
      />
      {actionData?.error && (
        <Text color={lightTheme.palette.error.e400} variant="normal">
          {t(actionData.error)}
        </Text>
      )}
    </EditExperienceDialog>
  );
}

type ActionData = {
  shouldClose?: boolean;
  error?: string;
} | null;

export async function action({
  params,
  request,
}: ActionFunctionArgs): Promise<ActionData> {
  const id = params.id;

  if (!id) {
    throw new Response('Invalid id', { status: 404 });
  }

  try {
    // Validate input
    const formData = await request.formData();
    const practicalInfo = getFormDataValue(formData.get(PROPERTY_NAME));

    const [experience, companyProfile] = await Promise.all([
      getExperience(id),
      getCompanyProfile(),
    ]);

    //
    if (practicalInfo === '') {
      const experienceToSave = {
        ...experience,
        infoForGuests: {
          selectedOptionKey: 'no',
        },
      } as DraftExperience;

      await saveExperienceDraft(id, experienceToSave);

      return { shouldClose: true };
    }

    const activeLanguage = getActiveLanguage(request, companyProfile);

    const translatedPracticalInfo = await translateInput(
      practicalInfo,
      experience.infoForGuests?.yes,
      activeLanguage,
      companyProfile.languages as Language[],
      experience.autoTranslateEnabled
    );

    const result = localizedSchema(activeLanguage).validateSync(
      translatedPracticalInfo
    );

    const experienceToSave = {
      ...experience,
      infoForGuests: {
        selectedOptionKey: 'yes',
        yes: result,
      },
    } as DraftExperience;

    await saveExperienceDraft(id, experienceToSave);

    return { shouldClose: true };
  } catch (error) {
    if (error instanceof ValidationError) {
      return {
        error: error.message,
      };
    }

    captureException(error);
    return null;
  }
}
