import { Stack, StackTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { lightTheme } from '@understory-io/pixel';
import { ComponentProps } from 'react';

export const ResourceManagementListItem: typeof Stack = (
  props: ComponentProps<OverridableComponent<StackTypeMap>>
) => {
  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      gap={1.5}
      px={3}
      py={2}
      border={`1px solid ${lightTheme.palette.neutral.n100}`}
      borderRadius={2}
      sx={{
        backgroundColor: lightTheme.palette.neutral.n50,
        overflow: 'hidden',
      }}
      {...props}
    />
  );
};
