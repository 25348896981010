import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { captureException } from '@sentry/react';
import { ExperienceTag } from '@understory-io/utils-types';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { useExperienceTags } from '../../../../Hooks/useExperienceTags';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { trackStorefrontTagManagementTagEdited } from '../../../../tracking/storefront/tags-events';
import { TagDialogWrapper } from './tag-dialog-wrapper';
import { ExperienceTagForm } from './tag-form';

type UpdateTagDialogProps = {
  tag: ExperienceTag;
};

export const UpdateTagDialog = NiceModal.create(
  ({ tag }: UpdateTagDialogProps) => {
    const { t } = useTranslate('tags.dialog.update');
    const [isUpdating, setIsUpdating] = useState(false);

    const { updateTag } = useExperienceTags();

    const modal = useModal();

    const handleClose = useCallback(() => {
      modal.resolve();
      modal.remove();
    }, [modal]);

    const handleSubmit = useCallback(
      (data: ExperienceTag['name']) => {
        if (!tag) return;
        setIsUpdating(true);
        const payload = { ...tag, name: data };

        updateTag
          .mutateAsync(payload)
          .then(() => {
            handleClose();
            trackStorefrontTagManagementTagEdited();
          })
          .catch((error) => {
            captureException(error);
            toast.error(t('utils.errors.generic'));
          })
          .finally(() => setIsUpdating(false));
      },
      [tag, updateTag, handleClose, t]
    );

    if (!tag) return null;

    return (
      <TagDialogWrapper
        open={modal.visible}
        handleClose={handleClose}
        title={t('title')}
        description={t('description')}
      >
        <ExperienceTagForm
          onSubmit={handleSubmit}
          handleCancel={handleClose}
          defaultValues={tag.name}
          isSubmitting={isUpdating}
        />
      </TagDialogWrapper>
    );
  }
);
