import './styles.css';

import { LinkBubbleMenu, RichTextEditor } from 'mui-tiptap';
import { PropsWithChildren } from 'react';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import CustomBubbleMenu from './components/custom-bubble-menu';
import CustomFloatingMenu from './components/custom-floating-menu';
import EditorDesktopControls from './editor-desktop-controls';
import useExtensions from './use-extensions';

type MarkdownEditorProps = {
  placeholder?: string;
  value?: string;
  onChange?: (e: string) => void;
  preview?: boolean;
  autoFocus?: boolean;
};

function MarkdownEditor({
  children,
  value,
  placeholder,
  onChange,
  preview = false,
  autoFocus = false,
}: PropsWithChildren<MarkdownEditorProps>) {
  const { isSm } = useResponsive();
  const extensions = useExtensions({
    placeholder,
  });

  return (
    <RichTextEditor
      extensions={extensions}
      injectCSS={false}
      editable={!preview}
      enablePasteRules={extensions}
      renderControls={() =>
        !isSm && <EditorDesktopControls>{children}</EditorDesktopControls>
      }
      autofocus={autoFocus}
      RichTextFieldProps={{
        variant: 'standard',
        disabled: preview,
        MenuBarProps: {
          disableSticky: true,
          hide: preview,
        },
      }}
      onUpdate={(e) => {
        const markdownString =
          e.editor.storage.markdown.getMarkdown() as string;

        onChange && onChange(markdownString);
      }}
      content={value}
    >
      {() => (
        <>
          <LinkBubbleMenu />
          {isSm && (
            <>
              <CustomBubbleMenu>{children}</CustomBubbleMenu>
            </>
          )}
        </>
      )}
    </RichTextEditor>
  );
}

export default MarkdownEditor;
