import { CloseRounded } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { Button, Text } from '@understory-io/pixel';
import { Ticket } from '@understory-io/utils-types';
import { FC, useMemo } from 'react';

import { StatusBadge } from '../../../Components/badge/status-badge';
import { Link } from '../../../Components/Link/Link';
import { TBooking } from '../../../Hooks/useBookings';
import { useExperience } from '../../../Hooks/useExperience';
import { useTranslate } from '../../../Hooks/useTranslate';
import { getGuestCount, renderGuestTypes } from '../../../Utils/eventHelpers';
import { TicketDetailsEventDate } from './ticket-details-event-date';
import { TicketDetailsExperienceName } from './ticket-details-experience-name';
import { TicketDetailsPaymentStatus } from './ticket-details-payment-status';

type Props = {
  booking: TBooking;
  isCheckInLoading: boolean;
  ticketId?: string;
  onCheckIn: () => void;
  onClose: () => void;
};

export const TicketDetails: FC<Props> = ({
  booking,
  isCheckInLoading: checkInLoading,
  ticketId,
  onCheckIn,
  onClose,
}) => {
  const { t, i18n } = useTranslate('utils.tables.header');

  const {
    experience: { data: experience },
  } = useExperience(booking.experienceId);

  const { ticketStatus, guestCount, variants, addons } = useMemo(() => {
    const priceVariants = experience?.price?.variants;
    const lang = i18n.language;

    const getFormattedGuestTypes = (
      source: TBooking | Ticket,
      type: 'variant' | 'addon'
    ) => renderGuestTypes(source, type, lang, priceVariants).join(', ');

    if (!booking) {
      return {
        ticketStatus: 'expired' as Ticket['status'],
        variants: '',
        addons: '',
      };
    }

    const targetObject =
      ticketId && booking.tickets
        ? booking.tickets.find((ticket) => ticket.id === ticketId)
        : booking;

    if (!targetObject) {
      return {
        ticketStatus: 'expired' as Ticket['status'],
        variants: '',
        addons: '',
      };
    }

    return {
      ticketStatus: targetObject.status,
      guestCount: getGuestCount(targetObject.items),
      variants: getFormattedGuestTypes(targetObject, 'variant'),
      addons: getFormattedGuestTypes(targetObject, 'addon'),
    };
  }, [booking, experience?.price?.variants, i18n.language, ticketId]);

  const disableCheckInButton = !['active', 'unpaid'].includes(ticketStatus);

  return (
    <>
      <IconButton
        sx={{ position: 'absolute', top: 16, right: 16 }}
        onClick={onClose}
      >
        <CloseRounded htmlColor="black" />
      </IconButton>

      <Stack gap={3} flex={1}>
        <Stack gap={0.5}>
          <Stack direction="row" gap={1}>
            {guestCount && (
              <Text variant="medium" fontSize="large">
                {guestCount} {guestCount > 1 ? t('guests') : t('guest')}
              </Text>
            )}

            <StatusBadge size={'small'} type={'booking'} state={ticketStatus} />
          </Stack>
          <Text variant="normal" fontSize="medium">
            {variants}
          </Text>
          <Text variant="normal" fontSize="medium">
            {addons}
          </Text>
        </Stack>
        <Stack gap={0.5}>
          <TicketDetailsExperienceName experienceId={booking.experienceId} />
          <TicketDetailsEventDate eventId={booking.eventId} />
          <TicketDetailsPaymentStatus receiptId={booking.receiptId} />

          <Text variant="normal" fontSize="medium">
            {booking.customer.name}
          </Text>
        </Stack>

        <Link href={`/booking/${booking.id}`}>
          {t('viewBookingDetails', 'checkin.scan.ticketDetails')}
        </Link>

        <Stack flex={1} justifyContent={'flex-end'}>
          <Button
            size={'large'}
            variant={'primary'}
            fullWidth
            onClick={() => onCheckIn()}
            disabled={disableCheckInButton || checkInLoading}
          >
            {t(ticketStatus, 'checkin.scan.ticketDetails.button', {
              defaultValue: t('checkIn', 'dialogs.booking.actions'),
            })}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
