import { OpenInNew } from '@mui/icons-material';
import { Link, Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { renderDateTime } from '@understory-io/utils-date';
import { Ticket } from '@understory-io/utils-types';

import { StatusBadge } from '../../../../../../../../Components/badge/status-badge';
import { TBooking } from '../../../../../../../../Hooks/useBookings';
import { useTranslate } from '../../../../../../../../Hooks/useTranslate';
import { renderGuestTypes } from '../../../domain/use_booking_details_summary';

type BookingDetailsSummaryBookingTicketsModalListProps = {
  booking: TBooking | undefined;
  tickets: Ticket[];
  language?: string;
  checkInTicket: (ticketId: string) => void;
};

export const BookingDetailsSummaryBookingTicketsModalList = ({
  booking,
  tickets,
  language,
  checkInTicket,
}: BookingDetailsSummaryBookingTicketsModalListProps) => {
  const { t } = useTranslate('dialogs.booking.tickets');

  return (
    <Stack gap={1} width={'100%'}>
      <Text variant="medium">
        {t(tickets[0].status, 'utils.statusOptions')}
      </Text>
      {tickets.map((ticket) => (
        <Stack
          key={ticket.id}
          gap={1.5}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{
            border: `1px solid ${lightTheme.palette.neutral.n200}`,
            borderRadius: 1.5,
            paddingX: 3,
            paddingY: 2,
          }}
        >
          <Stack gap={1}>
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              <Text>
                {t('ticket')} {ticket.counter}
              </Text>
              <Link href={ticket.ticketUrl} target="_blank">
                <OpenInNew fontSize="small" />
              </Link>
            </Stack>
            <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
              {renderGuestTypes(
                {
                  items: ticket.items,
                  variants: booking?.variants,
                },
                undefined,
                language
              )}
            </Text>
          </Stack>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            {ticket.status === 'active' && (
              <Button
                size="small"
                variant="primary"
                onClick={() => checkInTicket(ticket.id)}
              >
                {t('checkIn', 'dialogs.booking.actions')}
              </Button>
            )}
            {ticket.status === 'checked-in' && ticket.checkIn && (
              <Stack gap={1} alignItems={'flex-end'}>
                <StatusBadge type="booking" state="checked-in" size="small" />
                <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
                  {renderDateTime(ticket.checkIn.date)}
                </Text>
              </Stack>
            )}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
