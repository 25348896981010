import { createFilterOptions, FilterOptionsState } from '@mui/material';

import { TagFilterItem } from './tags';

const filter = createFilterOptions<TagFilterItem>();

export const filterOptions = (
  tags: TagFilterItem[],
  params: FilterOptionsState<TagFilterItem>
) => filter(tags, params);
