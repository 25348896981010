import {
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { TBookingRow } from '../../../domain/types';
import { useBookingDetailsContext } from '../../../domain/use_booking_details_context';
import { useBookingDetailsActions } from '../../booking_details_actions/domain/use_booking_details_actions';

type BookingDetailsSummaryTableProps = {
  rows: TBookingRow[];
};

export const BookingDetailsSummaryTable = ({
  rows,
}: BookingDetailsSummaryTableProps) => {
  const { t, i18n } = useTranslate('dialogs.booking');

  const { booking, eventMovedFrom, eventMovedTo } = useBookingDetailsContext();
  const { handleCheckInBooking } = useBookingDetailsActions();

  return (
    <StyledTable>
      <TableBody>
        {rows.map((row) => {
          if (
            booking &&
            !row.value(booking, t, {
              language: i18n.language ?? 'da',
              eventMovedFrom,
              eventMovedTo,
              handleCheckInBooking,
            })
          ) {
            return null;
          }

          return (
            <TableRow key={row.key}>
              <TableCell component="th" scope="row" sx={{ display: 'block' }}>
                {t(row.key)}
              </TableCell>
              <TableCell>
                {booking ? (
                  row.value(booking, t, {
                    language: i18n.language ?? 'da',
                    eventMovedFrom,
                    eventMovedTo,
                    handleCheckInBooking,
                  })
                ) : (
                  <Skeleton width="50%" />
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

export const StyledTable = styled(Table)(() => ({
  border: 'none',
  borderCollapse: 'collapse',
  '& th, td': {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    fontSize: 'inherit',
    color: '#000',
    border: 'none',
    padding: 0,
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  '& td:first-of-type': {
    width: '40%',
  },
  '& td:last-child': {
    width: '60%',
  },
}));
