import { RoleFormCapabilitiesExperienceAccess } from './experience-access/role-form-capabilities-experience-access';
import { RoleFormCapabilitiesGuideAccess } from './role-form-capabilities-guide-access';

export const RoleFormCapabilities = () => {
  return (
    <>
      <RoleFormCapabilitiesExperienceAccess />
      <RoleFormCapabilitiesGuideAccess />
    </>
  );
};
