import { DraftExperience } from '@understory-io/experiences-types';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useActionData,
  useLoaderData,
  useRouteLoaderData,
} from 'react-router';

import { saveExperienceDraft } from '../../../../../Api/Experience';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { EditExperienceDialog } from '../../components/dialogs/edit-experience-dialog';
import {
  LoaderData as RouterLoaderData,
  loaderName,
} from '../../edit-experience';
import { getExperience } from '../../queries';

export type LoaderData =
  ReturnType<typeof loader> extends Promise<infer R> ? R : never;

export async function loader({ params }: LoaderFunctionArgs) {
  const requestId = params.requestId;

  return { requestId };
}

export default function DeleteInfoRequestForm() {
  const { requestId } = useLoaderData() as LoaderData;
  const { experience } = useRouteLoaderData(loaderName) as RouterLoaderData;
  const actionData = useActionData() as ActionData;
  const { t } = useTranslate('experience.edit.dialog.deleteInfoRequest');

  const request = experience.informationRequests?.find(
    (request) => request.id === requestId
  );

  // Make sure the request exists
  if (!request) {
    throw new Response('Request not found', { status: 404 });
  }

  return (
    <EditExperienceDialog
      title={t('title')}
      description={t('description')}
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
      isDelete
    />
  );
}

type ActionData = {
  shouldClose?: boolean;
} | null;

export async function action({ params }: ActionFunctionArgs) {
  const id = params.id;
  const requestId = params.requestId;

  if (!id || !requestId) {
    throw new Response('Invalid id', { status: 400 });
  }

  try {
    const experience = await getExperience(id);

    const experienceToSave = {
      ...experience,
      informationRequests:
        experience.informationRequests?.filter(
          (request) => request.id !== requestId
        ) ?? [],
    } as DraftExperience;

    await saveExperienceDraft(id, experienceToSave);

    return { shouldClose: true };
  } catch (error) {
    // TODO: Error handling
    return null;
  }
}
