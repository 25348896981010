import { TFunction } from 'i18next';

import { Cutoff, RelativeToType } from '../schemas/cutoffSchema';

const renderDuration = (t: TFunction, ...params: string[]) => {
  const last = params.pop();
  if (params.length === 0) {
    return last ?? '';
  }
  return params.join(', ') + ` ${t('and')} ` + last;
};

export const renderDurationString = (
  t: TFunction,
  mins: number | undefined
) => {
  if (typeof mins !== 'number') {
    return '';
  }
  const days = Math.floor(mins / (60 * 24));
  const hours = Math.floor((mins % (60 * 24)) / 60);
  const minutes = mins % 60;

  const units = [
    { keySingular: 'day', keyPlural: 'days', value: days },
    { keySingular: 'hour', keyPlural: 'hours', value: hours },
    { keySingular: 'minute', keyPlural: 'minutes', value: minutes },
  ]
    .filter((unit) => unit.value > 0)
    .map(({ keySingular, keyPlural, value }) => {
      const plural = value > 1;
      return `${value} ${t(plural ? keyPlural : keySingular)}`;
    });

  return renderDuration(t, ...units);
};

const getCutoffLabelKey = (
  seconds: number,
  relativeTo: RelativeToType
): string => {
  if (seconds === 0) {
    return ['beforeEventStart', 'afterEventStart'].includes(relativeTo)
      ? 'eventStart'
      : 'eventEnd';
  }
  return relativeTo;
};

export const renderCutoffLabel = (
  t: TFunction,
  cutoff?: Cutoff,
  cutoffTimeSeconds?: number
): string => {
  const seconds = cutoff?.timeInSeconds ?? cutoffTimeSeconds ?? 0;
  const relativeTo = cutoff?.relativeTo ?? 'beforeEventStart';

  const durationString = renderDurationString(t, seconds / 60);
  const labelKey = getCutoffLabelKey(seconds, relativeTo);
  const translatedLabel = t(
    `experience.edit.dialog.cutoff.options.${labelKey}`
  );

  return `${durationString} ${seconds > 0 ? translatedLabel.toLowerCase() : translatedLabel}`;
};
