import { Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { Controller } from 'react-hook-form';

import { CountrySelect } from '../../../../../Components/CountrySelect/CountrySelect';
import { useAuth } from '../../../../../Hooks/useAuth';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { StyledTextField } from '../../../shared/StyledTextField';

export const CompanyFormAddress = () => {
  const { t } = useTranslate('settings.company');
  const { canAccess } = useAuth();

  const disableFields = !canAccess('company.write');

  return (
    <Stack spacing={2}>
      <Text variant="medium">{t('address.title')}</Text>
      <Controller
        name={'location.address'}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t('address', 'utils.generic')}
            disabled={disableFields}
          />
        )}
      />
      <Controller
        name={'location.zipCode'}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t('zipCode', 'utils.generic')}
            type={'number'}
            disabled={disableFields}
          />
        )}
      />
      <Controller
        name={'location.city'}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t('city', 'utils.generic')}
            disabled={disableFields}
          />
        )}
      />
      <Controller
        name={'location.country'}
        render={({ field }) => (
          <CountrySelect
            value={field.value}
            onChange={field.onChange}
            disabled={disableFields}
            sx={{ minWidth: 320, maxWidth: 320 }}
          />
        )}
      />
    </Stack>
  );
};
