import { useEffect } from 'react';
import { Outlet } from 'react-router';

import { ampli } from '../Ampli';
import { PageTabs } from '../Components/Page/PageTabs';
import { useFireOnce } from '../Hooks/useFireOnce';
import { useTranslate } from '../Hooks/useTranslate';

export const GrowthPage = () => {
  const { t } = useTranslate('growth');
  const fireOnce = useFireOnce();

  const tabs = [
    {
      key: 'opportunities',
      scopes: ['marketing'],
      allowedOnMobile: true,
      trackingFn: () => ampli.growthGetStartedPageViewed(),
    },
    {
      key: 'tracking',
      scopes: ['marketing'],
      trackingFn: () => ampli.growthTrackingViewed(),
    },
    {
      key: 'discounts',
      scopes: ['marketing'],
      // Tracking function moved to GrowthDiscountsSection.tsx
    },
    {
      key: 'emails',
      scopes: ['marketing'],
      trackingFn: () => ampli.growthEmailsPermissionsViewed(),
    },
  ];

  useEffect(() => {
    fireOnce(() => ampli.growthPageViewed());
  }, [fireOnce]);

  return (
    <PageTabs title={t('title')} tabs={tabs}>
      <Outlet />
    </PageTabs>
  );
};
