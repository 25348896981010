import { ErrorOutlineOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  MenuItem,
  MenuItemProps,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
} from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ReactNode, useCallback } from 'react';

import { renderSelectValue } from '../utils/form-helpers';

const ICON_SIZE_PX = 18;

interface CustomMenuItemProps extends MenuItemProps {
  label: string;
  icon?: ReactNode;
  hasError?: boolean;
}

export type CustomSelectProps = {
  selectedValue: string | string[];
  options: Omit<CustomMenuItemProps, 'children'>[];
  footerOptions?: CustomMenuItemProps[];
  onChange: (value: string | string[]) => void;
  onClose?: () => void;
  onOpen?: () => void;
  open?: boolean;
  multiple?: boolean;
  emptyLabel?: string;
  icon?: ReactNode;
  error?: boolean;
  helperText?: string;
};

const CustomSelectWrapper = styled(Select)(({ error, sx }) => ({
  color: lightTheme.palette.neutral.n400,
  backgroundColor: lightTheme.palette.contrast.surface1,
  padding: 0,
  overflow: 'hidden',
  '& .MuiSelect-icon': {
    color: lightTheme.palette.contrast.black,
    FontSize: 12,
  },
  // Controls the inner parts of the select
  '& .MuiInputBase-input': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    padding: 8,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  // Controls the focus state of the select
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: error
      ? lightTheme.palette.error.e300
      : lightTheme.palette.neutral.n200, // Set the border color to gray when focused
  },
  sx,
}));

export function CustomSelect({
  selectedValue,
  options,
  footerOptions = [],
  onChange,
  error = false,
  multiple = false,
  emptyLabel,
  icon,
  ...props
}: CustomSelectProps) {
  const handleChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const value = e.target.value as string | string[];
      if (Array.isArray(value)) {
        const filteredValues = value.filter((v) => v !== undefined);
        onChange(filteredValues);
      } else {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <CustomSelectWrapper
      multiple={multiple}
      value={selectedValue}
      defaultValue={selectedValue}
      sx={{ borderRadius: 1 }}
      MenuProps={{
        MenuListProps: {
          sx: {
            color: lightTheme.palette.contrast.black,
          },
        },
      }}
      // Icon to the right of the select
      IconComponent={ExpandMoreIcon}
      // IN
      renderValue={(value: unknown) => (
        <Stack
          sx={{
            color: lightTheme.palette.contrast.black,
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              flexGrow: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
              <Stack
                sx={{
                  width: ICON_SIZE_PX,
                  height: ICON_SIZE_PX,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {/* Either use the prefined icon or use icon from options */}
                {(Array.isArray(value) && multiple) || !value
                  ? icon
                  : options.find((option) => option.value === value)?.icon}
              </Stack>
              <Text variant="medium" fontSize="small">
                {renderSelectValue(
                  value as string | string[],
                  options,
                  multiple,
                  emptyLabel
                )}
              </Text>
            </Stack>
          </Stack>
        </Stack>
      )}
      onChange={(e) => handleChange(e)}
      error={error}
      displayEmpty
      {...props}
    >
      {options.map((props, index) => (
        <CustomMenuItem key={index} {...props} />
      ))}
      {/* footerOptions seperated from the primary options with a border inbetween */}
      {footerOptions.map(
        ({ key, label, icon, hasError, children, ...props }, index) => (
          <MenuItem
            sx={{
              color: hasError
                ? lightTheme.palette.error.e300
                : lightTheme.palette.contrast.black,
              borderTop: 1,
              borderColor:
                index === 0 ? lightTheme.palette.neutral.n100 : 'transparent',
            }}
            key={key}
            {...props}
          >
            {children ? (
              children
            ) : (
              <Stack
                sx={{
                  flexDirection: 'row',
                  flexGrow: 1,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Stack
                  sx={{ flexDirection: 'row', gap: 0.5, alignItems: 'center' }}
                >
                  {icon && <Box>{icon}</Box>}
                  <Text variant="medium" fontSize="small">
                    {label}
                  </Text>
                </Stack>
                {hasError && (
                  <ErrorOutlineOutlined
                    sx={{
                      marginLeft: 'auto',
                      color: lightTheme.palette.error.e300,
                    }}
                  />
                )}
              </Stack>
            )}
          </MenuItem>
        )
      )}
    </CustomSelectWrapper>
  );
}

function CustomMenuItem({
  hasError,
  value,
  icon,
  label,
  sx,
  ...props
}: CustomMenuItemProps) {
  return (
    <MenuItem
      sx={{
        display: 'flex',
        color: hasError
          ? lightTheme.palette.error.e300
          : lightTheme.palette.contrast.black,
        paddingX: 1.5,
        paddingY: 1,
        gap: 1,
        ...sx,
      }}
      value={value}
      {...props}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          flexGrow: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
          {icon && (
            <Stack
              sx={{
                width: ICON_SIZE_PX,
                height: ICON_SIZE_PX,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {icon}
            </Stack>
          )}
          <Text variant="medium" fontSize="small">
            {label}
          </Text>
        </Stack>
        {hasError && (
          <ErrorOutlineOutlined
            sx={{
              marginLeft: 'auto',
              color: lightTheme.palette.error.e300,
            }}
          />
        )}
      </Stack>
    </MenuItem>
  );
}
