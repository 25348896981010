import { Box, Stack, TextField } from '@mui/material';
import { Button, Text } from '@understory-io/pixel';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form, useSubmit } from 'react-router';

import { TimeSpanPicker } from '../../../../Components/TimeSpanPicker/timeSpanPicker';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { CreateResourceTypeInputs } from './create-resource-type-inputs';

const TIMESPAN_PICKER_WIDTH = '200px';

export const CreateResourceTypeForm: FC = () => {
  const { t } = useTranslate('resourceManagement.resourceType');

  const formMethods = useForm<CreateResourceTypeInputs>({
    defaultValues: {
      name: '',
      description: '',
      capacity: 1,
      recoverTimeInSeconds: 0,
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setValue,
  } = formMethods;

  const submit = useSubmit();
  const onSubmit = async (data: CreateResourceTypeInputs) =>
    submit(data, { method: 'post', encType: 'application/json' });

  return (
    <FormProvider {...formMethods}>
      <Stack
        component={Form}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        gap={2}
      >
        <TextField
          {...register('name', {
            required: {
              value: true,
              message: t('required', 'utils.errors'),
            },
          })}
          size="medium"
          fullWidth
          type="text"
          label={t('name', 'utils.generic')}
          placeholder={t('createNew.placeholder.name')}
          error={!!errors.name?.message}
          helperText={errors.name?.message}
          InputProps={{ sx: { maxHeight: 48 } }}
          InputLabelProps={{ shrink: true }}
          sx={{ mt: 2 }}
        />
        <TextField
          {...register('description')}
          size="medium"
          fullWidth
          type="text"
          label={t('description')}
          placeholder={t('createNew.placeholder.description')}
          error={!!errors.description?.message}
          helperText={errors.description?.message}
          InputProps={{ sx: { maxHeight: 48 } }}
          InputLabelProps={{ shrink: true }}
          sx={{ mt: 2 }}
        />
        <TextField
          {...register('capacity', {
            required: {
              value: true,
              message: t('required', 'utils.errors'),
            },
          })}
          size="medium"
          fullWidth
          type="number"
          label={t('capacity')}
          error={!!errors.capacity?.message}
          helperText={errors.capacity?.message}
          InputProps={{ sx: { maxHeight: 48 }, inputProps: { min: 1 } }}
          InputLabelProps={{ shrink: true }}
          sx={{ mt: 2 }}
        />
        <Stack mb={1}>
          <Text fontSize="small" variant="medium">
            {t('followUpTime')}
          </Text>
          <Text fontSize="xsmall">{t('followUpTimeDescription')}</Text>
          <Box maxWidth={TIMESPAN_PICKER_WIDTH} mt={2}>
            <TimeSpanPicker
              size="small"
              initialValueSeconds={0}
              onChange={(seconds) => {
                setValue('recoverTimeInSeconds', seconds);
              }}
              hideDays
            />
          </Box>
        </Stack>
        <Button
          type="submit"
          variant="primary"
          size="large"
          disabled={isSubmitting}
        >
          {t('createNew.submitLabel')}
        </Button>
      </Stack>
    </FormProvider>
  );
};
