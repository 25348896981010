import { ArrowBackOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';

import routes from '../../../../Utils/routes';

type GoBackProps = {
  experienceId: string;
};

const ICON_SIZE_PX = { xs: 24, md: 32 };

export const GoBack = ({ experienceId }: GoBackProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleBack = () => {
    if (location.key === 'default') {
      navigate(routes.experience.details(experienceId).index, {
        replace: true,
      });
    } else {
      navigate(-1);
    }
  };

  return (
    <IconButton
      size="small"
      onClick={handleBack}
      sx={{
        width: ICON_SIZE_PX,
        height: ICON_SIZE_PX,
        ':focus-visible': { outline: 1 },
      }}
    >
      <ArrowBackOutlined />
    </IconButton>
  );
};
