import { Divider, MenuItem, SelectChangeEvent } from '@mui/material';
import {
  CompanyProfile,
  ReducedVat,
  VatCompliance,
  VatSetting,
} from '@understory-io/utils-types';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ampli } from '../../../../Ampli';
import FeatureItem from '../../../../Components/FeatureItem/FeatureItem';
import { useProfile } from '../../../../Hooks/useProfile';
import {
  TranslateFunction,
  useTranslate,
} from '../../../../Hooks/useTranslate';
import { filteredVatRates } from '../../../SettingsSections/Company/domain/helpers';
import { useIntegrations } from '../../../SettingsSections/Integrations/domain/use-integrations';
import FeatureSelect from '../inputs/FeatureSelect';

const FEATURE_ID = 'passOnBookingFee';

export const PassOnBookingFeeFeature = () => {
  const { t } = useTranslate('settings.features');
  const {
    company: { data: company },
  } = useProfile();
  const flags = useFlags();

  const vatRates = useVatRates(company);

  const configuredVatRate =
    company?.features.passOnBookingFee?.params?.feeVatCategory;

  const isDisabled = !useAllowedIntegrations();

  const showVat = (company?.vatCompliance?.vatRegistrations?.length ?? 0) > 0;

  const trackToggling = (enabled: boolean) => {
    if (enabled) {
      ampli.passOnBookingFeeToggledOn({ feature_enabled: 'passOnBookingFee' });
    } else {
      ampli.passOnBookingFeeToggledOff();
    }
  };
  const trackOpening = () => {
    ampli.passOnBookingFeeVatDropdownOpened();
  };
  const trackApplied = (e: SelectChangeEvent<unknown>) => {
    ampli.passOnBookingFeeVatDropdownApplied({
      // set a synthetic default value to represent the company default select option within our analytics
      pass_on_vat_category: (e.target.value as string) || 'company-default',
    });
  };

  if (!flags.featurePassOnBookingFee) {
    return null;
  }

  return (
    <FeatureItem
      disabled={isDisabled}
      feature={FEATURE_ID}
      onToggle={trackToggling}
    >
      {showVat && (
        <FeatureSelect
          name="feeVatCategory"
          disabled={isDisabled}
          defaultValue={configuredVatRate}
          displayEmpty
          labelProps={{ shrink: true }}
          onOpen={trackOpening}
          onChange={trackApplied}
        >
          <MenuItem value="">
            {t('passOnBookingFee.feeVatCategory.companyDefaultLabel')}
          </MenuItem>
          <Divider variant="middle" />
          {vatRates.map((rate) =>
            Object.entries(rate)
              .map((entries: [string, VatSetting]) =>
                formatVatRateLabel(t, entries)
              )
              .map(({ key, label, value }) => (
                <MenuItem key={key} value={value}>
                  {label}
                </MenuItem>
              ))
          )}
        </FeatureSelect>
      )}
    </FeatureItem>
  );
};

const formatVatRateLabel = (
  t: TranslateFunction,
  [category, rate]: [string, VatSetting]
) => {
  const isExempt = rate?.exempt;
  const rateLabel = isExempt
    ? t('vatExempt', 'utils.generic')
    : `${((rate?.rate ?? rate) * 100).toFixed(1)}%`;

  return {
    key: category,
    label: `${rateLabel} (${t(category, 'vat.categories')})`,
    value: category,
  };
};

// we only allow the feature if a single payment provider is active and it is
// stripe. This array should be updated if we add more payment providers.
const paymentIntegrationIds = ['stripe', 'quickpay', 'paypal'];

const useAllowedIntegrations = () => {
  const integrations = useIntegrations();

  const paymentProviders = integrations.activeIntegrations.filter(
    (integration) => paymentIntegrationIds.includes(integration.id)
  );

  const isOnlyUsingStripe =
    paymentProviders.length === 1 && paymentProviders[0].id === 'stripe';

  // we do not allow this feature if economic is enabled
  const hasEconomicEnabled = integrations.activeIntegrations.some(
    (integration) => integration.id === 'economic'
  );

  return isOnlyUsingStripe && !hasEconomicEnabled;
};

const useVatRates = (
  company: CompanyProfile | undefined
): Array<{
  [catagory: string]: VatSetting;
}> => {
  const vatRates = getVatRates(
    company?.vatCompliance?.vatRegistrations[0]?.rates
  );

  return vatRates.map((el) => {
    const [category, rate] = Object.entries(el)[0];
    return {
      [category]: {
        country: company?.vatCompliance?.vatRegistrations[0]?.country ?? '',
        vatCategory: category,
        ...rate,
      },
    };
  });
};

const getVatRates = (rates?: VatCompliance['vatRegistrations'][0]['rates']) => {
  if (!rates) return [];

  const result = [] as {
    [category: string]: ReducedVat;
  }[];
  if (rates.standard) {
    result.push({
      standard: {
        rate: rates.standard,
        exempt: false,
      },
    });
  }
  if (rates.superReduced) {
    result.push({
      superReduced: {
        rate: rates.superReduced,
        exempt: false,
      },
    });
  }
  if (rates.reduced) {
    result.push(...rates.reduced);
  }

  return result.filter(
    (rate) => !filteredVatRates.includes(Object.keys(rate)[0])
  );
};
