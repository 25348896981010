import * as yup from 'yup';

const experineceVisibility = ['public', 'private'] as const;
type ExperineceVisibility = (typeof experineceVisibility)[number];

export const visibilitySchema = yup
  .mixed<ExperineceVisibility>()
  .oneOf([...experineceVisibility], 'utils.validationErrors.invalidEnum')
  .default('public');

export type Visibility = yup.InferType<typeof visibilitySchema>;
