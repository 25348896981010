import { FC } from 'react';

import { NoExperienceTitle } from '../../../../../../features/experiences/overview/experience-item';
import { getLocalized } from '../../../../../../Hooks/useBookings';
import { useExperience } from '../../../../../../Hooks/useExperience';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { PageBreadcrumbItem } from '../page-breadcrumb-item';

type Props = {
  experienceId: string | undefined;
};

export const PageBreadcrumbBreadcrumbsExperienceDetails: FC<Props> = ({
  experienceId,
}) => {
  const { i18n } = useTranslate('utils.primaryNavigation');

  const {
    experience: { data: experience },
  } = useExperience(experienceId);

  const link = experience?.id ? `/experience/${experience.id}` : '/experiences';
  const headline = getLocalized(experience?.headline, i18n.language);

  return (
    <PageBreadcrumbItem title={headline || <NoExperienceTitle />} href={link} />
  );
};
