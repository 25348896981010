import { ampli } from '../../../../../Ampli';
import { TUser } from '../../../../../Hooks/useUsers';

export const trackEventFlowGuideSelected = (
  userId: string,
  users: TUser[] | undefined,
  selectedGuidesIds: string[],
  currentGuidesIds: string[]
) => {
  const added_self =
    selectedGuidesIds.includes(userId) && !currentGuidesIds.includes(userId);
  const removed_self =
    !selectedGuidesIds.includes(userId) && currentGuidesIds.includes(userId);
  const number_of_guides = selectedGuidesIds.length;
  const roles_added = users
    ?.filter(
      (user) =>
        selectedGuidesIds.includes(user.id ?? '') &&
        !currentGuidesIds.includes(user.id ?? '')
    )
    .map((user) => user.role);
  const roles_removed = users
    ?.filter(
      (user) =>
        !selectedGuidesIds.includes(user.id ?? '') &&
        currentGuidesIds.includes(user.id ?? '')
    )
    .map((user) => user.role);

  ampli.eventFlowGuideSelected({
    added_self,
    removed_self,
    number_of_guides,
    roles_added,
    roles_removed,
  });
};
