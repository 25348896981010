import { Box } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ResourceType } from '@understory-io/resource-management-types';
import { Location } from '@understory-io/utils-types';
import { intervalToDuration } from 'date-fns';
import { FC, ReactNode } from 'react';

import { useTranslate } from '../../../../Hooks/useTranslate';

type Props = {
  resourceType: ResourceType;
  locations: Promise<Array<Location>>;
};

export const ResourceTypeDetailsCard = ({ resourceType }: Props) => {
  const { t } = useTranslate('resourceManagement');

  return (
    <Box
      display="grid"
      px={4}
      py={3}
      gap={1.5}
      gridTemplateColumns="1fr 1fr"
      sx={{
        backgroundColor: lightTheme.palette.contrast.white,
      }}
      border={`1px solid ${lightTheme.palette.neutral.n100}`}
      borderRadius={1}
    >
      <GridRow label={t('general.capacity')} value={resourceType.capacity} />
      <GridRow
        label={t('resourceType.followUpTime')}
        value={
          <SecondsToTimeString
            seconds={resourceType.recoverTimeInSeconds ?? 0}
          />
        }
      />
    </Box>
  );
};

type SecondsToTimeStringProps = { seconds: number };
const SecondsToTimeString: FC<SecondsToTimeStringProps> = ({
  seconds,
}: {
  seconds: number;
}) => {
  const { t } = useTranslate('utils.generic.units');

  const duration = intervalToDuration({
    start: 0,
    end: seconds * 1000,
  });

  const hours = duration.hours ?? 0;
  const minutes = duration.minutes ?? 0;

  return (
    <>
      {hours > 0 && t('countHours', { count: hours })}
      {t('countMinutes', { count: minutes })}
    </>
  );
};

type GridRowProps = { label: string; value: ReactNode };
const GridRow: FC<GridRowProps> = ({ label, value }) => {
  return (
    <>
      <Box
        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        letterSpacing="0.4px"
      >
        <Text fontSize="xsmall" color={lightTheme.palette.neutral.n400}>
          {label}
        </Text>
      </Box>
      <Box
        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        letterSpacing="0.4px"
      >
        <Text fontSize="xsmall" variant="medium">
          {value}
        </Text>
      </Box>
    </>
  );
};
