import NiceModal from '@ebay/nice-modal-react';
import { DeleteForeverRounded } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  capitalize,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  StackProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { Button, lightTheme } from '@understory-io/pixel';
import {
  DefaultPriceBreakdown,
  VatCompliance,
} from '@understory-io/utils-types';
import randomBytes from 'randombytes';
import { useEffect, useMemo } from 'react';
import { useController, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ArrayContainer } from '../../Components/ArrayContainer/ArrayContainer';
import { Input, TInputProps } from '../../Components/Input/Input';
import { Tip } from '../../Components/Tip/Tip';
import { TranslatableGroup } from '../../Components/TranslatableGroup/translatable-group';
import { formatMoney } from '../../Components/VariantSelect/VariantSelect';
import useResponsive from '../../Hooks/layout/useResponsive';
import { useStorefrontLanguages } from '../../Hooks/translatable/use-storefront-languages';
import { useProfile } from '../../Hooks/useProfile';
import { TranslateFunction } from '../../Hooks/useTranslate';
import { VatFormDialog } from '../../Modals/vat-form/ui/VatFormDialog';
import { SyiSection } from '../../Pages/SyiPage/SyiSection';
import { useErrors } from '../../Pages/SyiPage/useErrors';
import { filteredVatRates } from '../SettingsSections/Company/domain/helpers';
import { SOURCE_LANG } from './ExperienceSyiSectionDescription';
import { seatTypeOptions } from './ExperienceSyiSectionSeats';

interface IVariant {
  id: string;
  name: { [key: string]: string };
  priceBreakdown: DefaultPriceBreakdown;
  hasExplanation: boolean;
  explanation?: { [key: string]: string };
  hasVatRate: boolean;
  vatRate: number;
  addons?: IAddon[];
}

interface IAddon extends Omit<IVariant, 'addons'> {}

const anId = () => randomBytes(16).toString('hex');

type TElement = { type: 'input' | 'editor'; props?: TInputProps };

export const ExperienceSyiSectionPrice = () => {
  const { t } = useTranslation();

  const { register, setValue, watch } = useFormContext();
  const { getError, clearError } = useErrors();

  const {
    company: { data: company },
    defaultCurrency,
  } = useProfile();

  const showVat = useMemo(
    () => (company?.vatCompliance?.vatRegistrations?.length ?? 0) > 0,
    [company]
  );

  const vatRates = useMemo(() => {
    if (!showVat) return [];

    return getVatRates(company?.vatCompliance?.vatRegistrations[0]?.rates).map(
      (el) => {
        const [category, rate] = Object.entries(el)[0];
        const isExempt = rate?.exempt;
        const rateLabel = isExempt
          ? t('utils.generic.vatExempt')
          : `${((rate?.rate ?? rate) * 100).toFixed(1)}%`;

        return {
          key: category,
          label: `${rateLabel} (${t('vat.categories.' + category)})`,
        };
      }
    );
  }, [company]);

  const availableVariants = watch('price.variants', []) as IVariant[];
  const storefrontDisplayVariants: { key: string; label: string }[] =
    company && availableVariants
      ? availableVariants.map((variant) => ({
          key: variant.id,
          label: `${Object.values(variant.name ?? {})?.[0] ?? ''} (${formatMoney(
            t,
            true
          )({
            value: (variant?.priceBreakdown?.vatInclusivePriceCents ?? 0) / 100,
            nativeCurrency: company.defaultCurrency,
          })})`,
        }))
      : [];

  const { field: displayPrice } = useController({
    name: 'price.displayVariantId',
  });

  // Set default display variant if only one variant is available
  // Force user to take action if there are multiple variants
  if (
    availableVariants?.length === 1 &&
    availableVariants[0].id !== displayPrice.value
  ) {
    setValue('price.displayVariantId', availableVariants[0].id);
  }

  const defaultVatRate = useMemo(() => {
    if (!showVat) return { key: '', label: '' };

    return {
      key:
        company?.vatCompliance?.vatRegistrations[0]?.defaultVatCategory ?? '',
      label: getVatLabel(t, company?.vatCompliance) ?? '',
    };
  }, [company]);

  const { field: seatsType } = useController({ name: 'seats.type' });

  const { field: languages } = useController({
    name: 'languages',
    defaultValue: [SOURCE_LANG],
  });

  const selectedType = useMemo(() => {
    return seatTypeOptions.find((el) => el.key === seatsType.value);
  }, [seatsType.value]);

  const variantToggleOptions: {
    key: string;
    element: TElement;
    label: (i: number) => string;
  }[] = [
    {
      key: 'explanation',
      label: () =>
        t('experience.create.price.tickets.options.true.explanation.title'),
      element: {
        type: 'editor',
        props: {
          placeholder: t(
            'experience.create.price.tickets.options.true.explanation.inputPlaceholder'
          ),
          fullWidth: true,
          minRows: 2,
          multiline: true,
        },
      },
    },
  ];

  const addonToggleOptions: (addon: IAddon) => {
    key: string;
    element: TElement;
    label: (i: number) => string;
  }[] = () => [
    {
      key: 'explanation',
      label: (i) =>
        `${t('experience.create.price.tickets.addon.explanation.title')} ${i}`,
      element: {
        type: 'editor',
        props: {
          placeholder: t(
            'experience.create.price.tickets.addon.explanation.inputPlaceholder'
          ),
          fullWidth: true,
          minRows: 2,
          multiline: true,
        },
      },
    },
  ];

  const getLocalized = (text: string) => {
    return { [languages.value?.[0] ?? 'da']: text };
  };

  return (
    <>
      <Typography variant={'h4'} mb={2}>
        {t('experience.create.progress.price')}
      </Typography>
      <Stack mt={2} spacing={4}>
        <SyiSection
          title={t('events.detailsPage.bookingsSummary.ticketTypes')}
          error={getError('price.variants')}
        >
          <Tip
            mb={0.5}
            label={t('experience.create.price.tickets.titleHelpText1')}
          />
          <Tip
            mb={2.5}
            label={t('experience.create.price.tickets.titleHelpText2')}
          />
          <ArrayContainer
            max={selectedType?.key === 'unique' ? 1 : 20}
            emptyState={[
              {
                id: anId(),
                name: getLocalized(capitalize(t('utils.generic.person'))),
                price: '',
                hasExplanation: false,
                hasVatRate: false,
                addons: [],
              },
            ]}
            addButtonProps={{
              label: t(
                'experience.create.price.tickets.options.true.buttonLabel'
              ),
              variant: 'primary',
            }}
            controllerKey={'price.variants'}
            defaultValue={() => ({
              id: anId(),
              price: '',
              hasExplanation: false,
              hasVatRate: true,
              addons: [],
            })}
            onAdd={() => clearError('price.variants')}
            renderItem={(el, i, { onDeleteItem }) => (
              <VariantItem
                id={el.id}
                index={i}
                key={`price.variants.${i}`}
                register={register}
                nameInputProps={{
                  helperText: t(
                    'experience.create.price.tickets.options.true.inputHelpText'
                  ),
                  label: `${t(
                    'experience.create.price.tickets.options.true.inputPlaceholder'
                  )}`,
                }}
                priceInputProps={{
                  label: `${t(
                    'experience.create.price.tickets.options.true.inputPrice',
                    { currency: t(`utils.currency.${defaultCurrency}`) }
                  )}`,
                }}
                setValue={setValue}
                toggleOptions={variantToggleOptions}
                parentKey={'price.variants'}
                showVat={showVat}
                vatRates={vatRates}
                defaultVatRate={defaultVatRate}
                mt={3}
                onDelete={() => {
                  onDeleteItem();
                  clearError(`price.variants[${i}].name`);
                  clearError(
                    `price.variants[${i}].priceBreakdown.vatInclusivePriceCents`
                  );
                }}
                nameError={getError(`price.variants[${i}].name`)}
                priceError={getError(
                  `price.variants[${i}].priceBreakdown.vatInclusivePriceCents`
                )}
                vatError={getError(
                  `price.variants[${i}].priceBreakdown.vatSetting.vatCategory`
                )}
                onChange={() => {
                  clearError(`price.variants[${i}].name`);
                  clearError(
                    `price.variants[${i}].priceBreakdown.vatInclusivePriceCents`
                  );
                }}
                clearError={() => {
                  clearError(`price.variants[${i}].name`);
                  clearError(
                    `price.variants[${i}].priceBreakdown.vatInclusivePriceCents`
                  );
                }}
              >
                <ArrayContainer
                  addButtonProps={{
                    label: t(
                      'experience.create.price.tickets.addon.buttonLabel'
                    ),
                  }}
                  controllerKey={`price.variants.${i}.addons`}
                  defaultValue={() => ({
                    id: anId(),
                    hasExplanation: false,
                    hasVatRate: true,
                    price: '',
                  })}
                  renderItem={(el, index, { onDeleteItem: _onDeleteItem }) => (
                    <VariantItem
                      id={el.id}
                      index={index}
                      withContainer={false}
                      mt={3}
                      key={`price.variants.${i}.addons.${index}`}
                      toggleOptions={addonToggleOptions(el)}
                      nameInputProps={{
                        helperText: t(
                          'experience.create.price.tickets.addon.inputHelpText'
                        ),
                        label: `${t(
                          'experience.create.price.tickets.addon.name'
                        )} ${index + 1}`,
                        sx: {
                          fieldset: {
                            borderStyle: 'dashed',
                          },
                        },
                      }}
                      priceInputProps={{
                        label: `${t(
                          'experience.create.price.tickets.addon.price',
                          {
                            currency: t(`utils.currency.${defaultCurrency}`),
                          }
                        )}`,
                        sx: {
                          fieldset: {
                            borderStyle: 'dashed',
                          },
                        },
                      }}
                      register={register}
                      setValue={setValue}
                      parentKey={`price.variants.${i}.addons`}
                      showVat={showVat}
                      vatRates={vatRates}
                      defaultVatRate={defaultVatRate}
                      nameError={getError(
                        `price.variants[${i}].addons[${index}].name`
                      )}
                      priceError={getError(
                        `price.variants[${i}].addons[${index}].priceBreakdown.vatInclusivePriceCents`
                      )}
                      vatError={getError(
                        `price.variants[${i}].addons[${index}].priceBreakdown.vatSetting.vatCategory`
                      )}
                      onDelete={() => {
                        _onDeleteItem();
                        clearError(
                          `price.variants[${i}].addons[${index}].name`
                        );
                        clearError(
                          `price.variants[${i}].addons[${index}].priceBreakdown.vatInclusivePriceCents`
                        );
                      }}
                      clearError={clearError}
                    />
                  )}
                />
              </VariantItem>
            )}
          />
        </SyiSection>
        {availableVariants?.length > 1 && (
          <Box>
            <Input
              {...register('price.displayVariantId')}
              {...displayPrice}
              error={Boolean(getError('price.displayVariantId'))}
              label={t('experience.create.price.display.options.label')}
              type={'select'}
              options={storefrontDisplayVariants}
              onChange={(event) => {
                displayPrice.onChange(event);
                clearError('price.displayVariantId');
              }}
              sx={{ width: '50%', maxWidth: '50%' }}
            />
          </Box>
        )}
      </Stack>
    </>
  );
};

const VariantItem = ({
  register,
  withContainer = true,
  toggleOptions,
  children,
  onDelete,
  setValue,
  clearError,
  priceInputProps,
  nameInputProps,
  index,
  parentKey,
  id,
  priceError,
  nameError,
  vatError,
  showVat,
  vatRates,
  defaultVatRate,
  ...props
}: BoxProps & {
  toggleOptions?: {
    key: string;
    element: TElement;
    label: (i: number) => string;
  }[];
  withContainer?: boolean;
  onDelete: () => void;
  clearError: (key: string) => void;
  priceInputProps?: TextFieldProps;
  nameInputProps?: TextFieldProps;
  index: number;
  parentKey: string;
  register: UseFormReturn['register'];
  setValue: UseFormReturn['setValue'];
  priceError?: string;
  nameError?: string;
  vatError?: string;
  showVat?: boolean;
  vatRates?: { key: string; label: string }[];
  defaultVatRate?: { key: string; label: string };
}) => {
  const { t } = useTranslation();
  const { isSm } = useResponsive();

  const storefrontLanguages = useStorefrontLanguages() ?? [];

  const { field: vatInclusivePriceCents } = useController({
    name: `${parentKey}.${index}.priceBreakdown.vatInclusivePriceCents`,
  });
  vatInclusivePriceCents.value =
    vatInclusivePriceCents.value !== undefined &&
    vatInclusivePriceCents.value !== ''
      ? vatInclusivePriceCents.value / 100
      : '';

  const { field: vatCategory } = useController({
    name: `${parentKey}.${index}.priceBreakdown.vatSetting.vatCategory`,
    defaultValue: defaultVatRate?.key,
  });
  useEffect(() => {
    if (vatCategory.value === '' && defaultVatRate?.key) {
      setValue(
        `${parentKey}.${index}.priceBreakdown.vatSetting.vatCategory`,
        defaultVatRate.key
      );
    }
  }, [defaultVatRate]);

  const containerProps: BoxProps = withContainer
    ? {
        border: '1px solid #C7C7CC',
        borderRadius: 3,
        p: 3,
        position: 'relative',
      }
    : {};

  return (
    <Box {...containerProps} {...props}>
      {withContainer && (
        <Typography
          variant={'h5'}
          sx={{
            position: 'absolute',
            left: 20,
            top: -12,
            backgroundColor: lightTheme.palette.contrast.white,
            pl: 0.5,
            pr: 0.5,
          }}
        >{`${t('utils.generic.ticketType')} ${index + 1}`}</Typography>
      )}
      <Stack direction={isSm ? 'column' : 'row'} gap={2} alignItems={'start'}>
        <TranslatableGroup
          sx={{ mt: 0, width: isSm ? '100%' : '50%' }}
          id={`${parentKey}.${index}.name`}
          input={{
            type: 'input',
            placeholder: nameInputProps?.helperText as string,
            props: nameInputProps,
          }}
          langs={storefrontLanguages}
          error={nameError}
          clearError={(key) => clearError(key)}
        />
        <Stack direction="row" gap={1} width={isSm ? '100%' : '50%'}>
          <TextField
            type={'number'}
            sx={{ width: '50%', maxWidth: '50%' }}
            {...priceInputProps}
            {...vatInclusivePriceCents}
            inputProps={{ min: 0 }}
            error={Boolean(priceError)}
            onChange={(v) => {
              vatInclusivePriceCents.onChange(
                parseInt(v.target.value ?? 0) * 100
              );
              clearError(
                `${parentKey}.${index}.priceBreakdown.vatInclusivePriceCents`
              );
            }}
          />
          {showVat ? (
            <Input
              {...vatCategory}
              label={t(
                'experience.create.price.tickets.options.true.vat.inputPlaceholder'
              )}
              type={'select'}
              options={vatRates}
              error={Boolean(vatError)}
              onChange={(v) => {
                vatCategory.onChange(v);
                clearError(
                  `${parentKey}.${index}.priceBreakdown.vatSetting.vatCategory`
                );
              }}
              sx={{ width: '50%', maxWidth: '50%' }}
            />
          ) : (
            <Button
              variant={'secondary'}
              size={'medium'}
              style={{ height: 'unset', lineHeight: 'unset' }}
              onClick={() => {
                NiceModal.show(VatFormDialog);
              }}
            >
              {t(
                'experience.create.price.tickets.options.true.vat.inputPlaceholder'
              )}
            </Button>
          )}
          {index > 0 || parentKey !== 'price.variants' ? (
            <IconButton onClick={onDelete} sx={{ pb: 2 }}>
              <DeleteForeverRounded />
            </IconButton>
          ) : null}
        </Stack>
      </Stack>

      {toggleOptions && (
        <Stack mt={2} ml={withContainer ? 0 : 3}>
          {toggleOptions.map((el) => (
            <CheckboxOption
              pr={3}
              key={el.key}
              name={el.key}
              element={el.element}
              parentKey={`${parentKey}.${index}`}
              label={el.label(index + 1)}
            />
          ))}
        </Stack>
      )}

      {children}
    </Box>
  );
};

const CheckboxOption = ({
  name,
  label,
  children,
  parentKey,
  element,
  ...props
}: StackProps & {
  name: string;
  parentKey: string;
  element: TElement;
  label: string;
}) => {
  const { field: toggle } = useController({
    name: `${parentKey}.has${capitalize(name)}`,
    defaultValue: false,
  });

  return (
    <Stack {...props} direction={'column'} gap={5}>
      <FormControlLabel
        componentsProps={{ typography: { fontSize: '0.88rem' } }}
        control={<Checkbox />}
        label={label}
        checked={toggle.value}
        {...toggle}
      />

      {Boolean(toggle.value) && (
        <CheckboxOptionInput {...element} name={`${parentKey}.${name}`} />
      )}
    </Stack>
  );
};

const CheckboxOptionInput = ({
  name,
  type,
  props,
}: TElement & { name: string }) => {
  const { register, getValues } = useFormContext();
  const formValue = getValues(name);

  const storefrontLanguages = useStorefrontLanguages();
  if (!storefrontLanguages) return null;

  return type === 'input' ? (
    <Box mt={1.5} mb={1.5}>
      <Input {...props} defaultValue={formValue || ''} {...register(name)} />
    </Box>
  ) : (
    <TranslatableGroup
      mt={storefrontLanguages?.length > 1 ? -4 : 0}
      mb={1.5}
      id={name}
      input={{
        type: 'input',
        props,
      }}
      langs={storefrontLanguages}
    />
  );
};

const getVatRates = (rates?: VatCompliance['vatRegistrations'][0]['rates']) => {
  if (!rates) return [];

  const result = [] as {
    [key: string]: any;
  }[];
  if (rates.standard) {
    result.push({ standard: rates.standard });
  }
  if (rates.superReduced) {
    result.push({ superReduced: rates.superReduced });
  }
  if (rates.reduced) {
    result.push(...rates.reduced);
  }

  return result.filter(
    (rate) => !filteredVatRates.includes(Object.keys(rate)[0])
  );
};

const getVatLabel = (t: TranslateFunction, vatCompliance?: VatCompliance) => {
  if (!vatCompliance) return '';

  const { vatRegistrations } = vatCompliance;
  const { defaultVatCategory } = vatRegistrations[0];
  const vatRates = getVatRates(vatRegistrations[0].rates);
  const vatRate = vatRates.find((el) => el[defaultVatCategory]);

  const [name, rate] = Object.entries(vatRate ?? {})[0];
  const isExempt = rate?.exempt;
  const label = isExempt
    ? t('utils.generic.vatExempt')
    : `${((rate?.rate ?? rate) * 100).toFixed(1)}%`;

  return `${name} (${label})`;
};
