import * as yup from 'yup';

const experienceStatus = ['active', 'inactive', 'draft'] as const;
type ExperienceStatus = (typeof experienceStatus)[number];

export const statusSchema = yup
  .mixed<ExperienceStatus>()
  .oneOf([...experienceStatus], 'utils.validationErrors.invalidEnum')
  .default('active');

export type Status = yup.InferType<typeof statusSchema>;
