import { Booking, CheckIn } from '@understory-io/utils-types';

import { TBooking, TCreateBookingPayload } from '../Hooks/useBookings';
import { fetchAllPages } from '../Utils/api-pagination';
import { apiServices } from './apiServices';
import api from './index';

export const getBookings = (eventId?: string) => {
  if (typeof apiServices.bookings === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  return fetchAllPages<TBooking>(api, apiServices.bookings, {
    eventId,
  });
};

export const getBookingsByDate = (date: string) => {
  if (typeof apiServices.bookings === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  return fetchAllPages<TBooking>(api, apiServices.bookings, {
    date,
  });
};

export const getBooking = async (id: string) => {
  if (typeof apiServices.bookings === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  const { data } = await api.get<TBooking>(`${apiServices.bookings}/${id}`);

  return data;
};

export const createBooking = async (
  id: string,
  payload: TCreateBookingPayload
) => {
  if (typeof apiServices.bookings === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  const { data } = await api.post<Booking>(`${apiServices.bookings}/${id}`, {
    ...payload,
  });

  return data;
};

export const updateBooking = async <T>(id: string, payload: T) => {
  if (typeof apiServices.bookings === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  await api.put(`${apiServices.bookings}/${id}`, {
    ...payload,
  });
};

export const cancelBooking = async (
  id: string | undefined,
  shouldRefund: boolean = false
) => {
  if (!id) return;

  if (typeof apiServices.bookings === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  await api.post<unknown>(`${apiServices.bookings}/${id}/cancel`, {
    shouldRefund,
  });
};

export const moveBooking = async (id: string, toEventId: string) => {
  if (typeof apiServices.bookings === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  const { data } = await api.post<{
    newBookingId: string;
    newEventId: string;
  }>(`${apiServices.bookings}/${id}/move`, {
    eventId: toEventId,
  });

  return data;
};

export const checkInBooking = async (
  id: string,
  method: CheckIn['method'],
  ticketId?: string
) => {
  if (typeof apiServices.bookings === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  await api.post<unknown>(`${apiServices.bookings}/${id}/check-in`, {
    method,
    ticketId,
  });
};
