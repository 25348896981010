import { RegularExperience } from '@understory-io/experiences-types';
import { useMemo } from 'react';

import { getAllTicketsFromVariants } from '../Utils/ticket';

/**
 * Gets id and name of all tickets and addons of an experience.
 */
export function useTicketsAndAddons(
  experience?: Pick<RegularExperience, 'price'>
) {
  return useMemo(
    () => getAllTicketsFromVariants(experience?.price?.variants),
    [experience?.price?.variants]
  );
}
