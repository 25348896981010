import { ChevronLeftRounded } from '@mui/icons-material';
import { Dialog, Stack } from '@mui/material';
import { Button, lightTheme } from '@understory-io/pixel';
import { useMemo, useState } from 'react';
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useRouteLoaderData,
} from 'react-router';

import { Loading } from '../../../../Components/Loading/Loading';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { useAuthStore } from '../../../../Store/useAuthStore';
import { EXPERIENCE_URL } from '../../../../Utils/constants';
import { getHostingEnvironmentName } from '../../../../Utils/environment';
import routes from '../../../../Utils/routes';
import { LoaderData, loaderName } from '../edit-experience';
import { getCompanyProfile, getDomain } from '../queries';
import { useNavigateWithParams } from '../utils/use-navigate-with-params';

export async function loader() {
  const companyProfile = await getCompanyProfile();
  const activeDomain = await getDomain(companyProfile.id);

  const isLocal = getHostingEnvironmentName() === 'local';
  const baseURL = isLocal ? `http://{{domain}}.localhost:3005` : EXPERIENCE_URL;
  const storefrontDomain =
    activeDomain.type === 'subdomain' || isLocal
      ? baseURL.replace('{{domain}}', activeDomain.domain)
      : `https://${activeDomain.domain}`;

  return storefrontDomain;
}

export default function PreviewDraftDialog() {
  const storefrontDomain = useLoaderData() as string;
  const { experience, activeLanguage } = useRouteLoaderData(
    loaderName
  ) as LoaderData;
  const { t } = useTranslate('experience.edit.dialog.preview');
  const navigate = useNavigate();
  const navigateWithParams = useNavigateWithParams();
  const location = useLocation();

  const handleClose = () => {
    if (location.key === 'default') {
      navigateWithParams(routes.experience.details(experience.id).edit.index, {
        replace: true,
      });
    } else {
      navigate(-1);
    }
  };

  const { auth } = useAuthStore();
  const previewUrl = useMemo(
    () =>
      auth
        ? `${storefrontDomain}/${activeLanguage}/experience/${experience.id}?previewDraft=true&token=${auth.access_token}`
        : null,
    [activeLanguage, auth, experience.id, storefrontDomain]
  );

  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Dialog open={true} onClose={handleClose} fullWidth fullScreen={true}>
      <Stack sx={{ gap: 4, flexGrow: 1, paddingTop: { xs: 9, md: 12 } }}>
        <Stack
          sx={{
            position: 'absolute',
            backdropFilter: 'blur(20px)',
            backgroundColor: lightTheme.palette.contrast.surface2,
            top: 0,
            left: 0,
            right: 0,
            zIndex: 15,
            padding: { xs: 2, md: 4 },
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            size="medium"
            variant="primary"
            onClick={handleClose}
            leftIcon={<ChevronLeftRounded />}
          >
            {t('buttonLabel')}
          </Button>
        </Stack>
        {previewUrl && (
          <Stack width="100%" height="100%" flexGrow={1} position="relative">
            <iframe
              onLoad={() => setIsLoaded(true)}
              style={{
                height: '100%',
                border: 'none',
              }}
              src={previewUrl}
            />
            {!isLoaded && <Loading isLoading={true} />}
          </Stack>
        )}
      </Stack>
    </Dialog>
  );
}
