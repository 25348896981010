import { Box, Link } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { PropsWithChildren } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

function PreviewMarkdown({
  children,
}: PropsWithChildren<{ children: string }>) {
  return (
    <Box
      sx={{
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        color: lightTheme.palette.neutral.n300,
        maxHeight: '4rem', // To allow for 3 lines including a heading
        fontSize: lightTheme.typography.small.normal.fontSize,
      }}
    >
      <ReactMarkdown
        remarkPlugins={[remarkBreaks]}
        components={{
          ul: ({ node, ordered, ...props }) => (
            <ul
              style={{
                paddingInlineStart: '20px',
              }}
              {...props}
            />
          ),
          ol: ({ node, ordered, ...props }) => (
            <ol style={{ paddingInlineStart: '20px' }} {...props} />
          ),
          li: ({ node, ordered, ...props }) => <li {...props} />,
          p: (props) => <Text color="inherit" fontSize="small" {...props} />,
          h1: (props) => <Text color="inherit" fontSize="small" {...props} />,
          h2: (props) => <Text color="inherit" fontSize="small" {...props} />,
          h3: (props) => <Text color="inherit" fontSize="small" {...props} />,
          h4: (props) => <Text color="inherit" fontSize="small" {...props} />,
          h5: (props) => <Text color="inherit" fontSize="small" {...props} />,
          h6: (props) => <Text color="inherit" fontSize="small" {...props} />,
          a: (props) => (
            <Link
              {...props}
              target={'_blank'}
              sx={{
                color: 'inherit',
                textDecorationLine: 'underline',
                pointerEvents: 'none', // Added so people dont misclick the link
              }}
            />
          ),
        }}
      >
        {children}
      </ReactMarkdown>
    </Box>
  );
}

export default PreviewMarkdown;
