import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import { RoleFormSchema } from '../use-role-form-validation';
import { RoleFormScopesTable } from './role-form-scopes-table';

export const RoleFormPermissions = () => {
  const { t } = useTranslate('settings.team.roles.form');
  const {
    formState: { errors },
  } = useFormContext<RoleFormSchema>();

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2} alignItems="center">
        <Text
          fontSize="large"
          variant="medium"
          color={lightTheme.palette.neutral.n400}
        >
          {t('permissions.title')}
        </Text>
        {errors.scopes && (
          <Text fontSize="xsmall" color={lightTheme.palette.error.e300}>
            {t(errors.scopes.message as string)}
          </Text>
        )}
      </Stack>
      <RoleFormScopesTable />
    </Stack>
  );
};
