import { AddOutlined } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import { useTranslate } from '../../../../../Hooks/useTranslate';

interface CreateAddonProps {
  handleCreateAddon: () => void;
}

const TEXT_MAX_WIDTH_PX = 500;

export function CreateTicketAddon({ handleCreateAddon }: CreateAddonProps) {
  const { t } = useTranslate('experience.edit.dialog.tickets');
  return (
    <Stack
      role="button"
      onClick={handleCreateAddon}
      sx={{ width: '100%', cursor: 'pointer' }}
    >
      <Stack justifyContent="space-between" flexDirection="row">
        <Text fontSize="medium" variant="medium">
          {t('addAddon')}
        </Text>
        <IconButton
          sx={{
            width: 32,
            height: 32,
          }}
        >
          <AddOutlined sx={{ color: lightTheme.palette.contrast.black }} />
        </IconButton>
      </Stack>
      <Box
        sx={{
          maxWidth: TEXT_MAX_WIDTH_PX,
        }}
      >
        <Text
          color={lightTheme.palette.neutral.n300}
          fontSize="small"
          variant="normal"
        >
          {t('descriptionAddon')}
        </Text>
      </Box>
    </Stack>
  );
}
