/** Backward compatibility for all version ticket QR formats */
const IDENTIFIER_TAGS = ['US', 'HB'];

/**
 * Deserialize a Understory QR code string
 *
 * The ticket format is currently: {identifier}:TICKET:{version}:{bookingId}:{ticketId?}
 *
 * @param qrText
 * @returns
 */
export const deserializeQr = (qrText: string) => {
  try {
    const parts = qrText.split(':');
    if (parts.length < 2) return null;

    const [identifierTag, type, ...rest] = parts;
    if (!IDENTIFIER_TAGS.includes(identifierTag)) return null;

    if (type === 'TICKET') {
      return deserializeQrTicket(rest);
    }

    return null;
  } catch (error) {
    console.error('Failed to deserialize QR code', error);
    return null;
  }
};

/**
 * Deserialize a Understory ticket QR code string
 *
 * The ticket format is currently: {identifier}:TICKET:{version}:{bookingId}:{ticketId?}
 *
 * @param parts
 * @returns
 */
const deserializeQrTicket = (
  parts: string[]
): { bookingId: string; ticketId?: string } | null => {
  if (parts.length < 2) return null;

  const [, bookingId, ticketId] = parts;

  return {
    bookingId: bookingId.toLowerCase(),
    ticketId: ticketId?.toLowerCase(),
  };
};
