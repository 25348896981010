import { useMemo } from 'react';

import { MultiSelectFilter } from '../../../../../../../../features/calendar/filters/view/MultiSelectFilter';
import { useExperience } from '../../../../../../../../Hooks/useExperience';
import { useLocalizedStringFormatter } from '../../../../../../../../Hooks/useLocalizedStringFormatter';

export const RoleFormExperienceSelect = ({
  values,
  updateField,
}: {
  values: string[];
  updateField: (value: string[]) => void;
}) => {
  const localized = useLocalizedStringFormatter();
  const {
    experiences: { data, isLoading },
  } = useExperience();

  const options = useMemo(() => {
    if (!data) return [];

    return data
      .filter(({ status }) => status === 'active')
      .map(({ id, headline }) => ({
        id,
        name: localized(headline),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [data, localized]);

  return (
    <MultiSelectFilter
      inputId="experiences-filter"
      label={''}
      options={options}
      checkedIds={values}
      onChange={(value) => {
        updateField(value as string[]);
      }}
      isLoading={isLoading}
      fullWidth
      sx={{ maxWidth: '100%', '& .MuiInputBase-root': { height: 48 } }}
    />
  );
};
