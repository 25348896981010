import { Avatar } from '@mui/material';

import { useLanguages } from '../../../../Hooks/locales/use-languages';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { isLanguage, type Language } from '../../../../i18n/config';
import type { PublishErrors } from '../edit-experience';
import { AutoTranslateToggle } from '../header/auto-translate-toggle';
import { CustomSelect } from './custom-select';

const ICON_SIZE_PX = 18;

interface LanguageOptionsProps {
  activeLanguage: Language;
  availableLanguages: Language[];
  handleChangeLanguage: (language: Language) => void;
  isAutoTranslateEnabled: boolean;
  handleChangeAutoTranslate: (checked: boolean) => void;
  publishErrors?: PublishErrors;
}

export function LanguageOptions({
  activeLanguage,
  availableLanguages,
  handleChangeAutoTranslate,
  handleChangeLanguage,
  isAutoTranslateEnabled,
  publishErrors,
}: LanguageOptionsProps) {
  const { t } = useTranslate('experience.edit.details.label');
  const { languageOptions } = useLanguages();
  const onLanguageChange = (newValue: string | string[]) => {
    if (Array.isArray(newValue)) return;

    if (isLanguage(newValue)) {
      handleChangeLanguage(newValue);
    }
  };

  return (
    <CustomSelect
      selectedValue={activeLanguage}
      options={availableLanguages.map((lang) => ({
        value: lang,
        label: languageOptions(lang).label,
        icon: (
          <Avatar
            sx={{ width: ICON_SIZE_PX, height: ICON_SIZE_PX }}
            src={languageOptions(lang).flagSrc}
          />
        ),
        hasError: Boolean(publishErrors?.[lang]?.length),
      }))}
      footerOptions={[
        {
          key: 'auto-translate',
          label: t('autoTranslate'),
          children: (
            <AutoTranslateToggle
              isAutoTranslateEnabled={isAutoTranslateEnabled}
              onChange={handleChangeAutoTranslate}
            />
          ),
        },
      ]}
      onChange={onLanguageChange}
      error={
        publishErrors ? Object.keys(publishErrors).some(isLanguage) : false
      }
    />
  );
}
