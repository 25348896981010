import { ResourceType } from '@understory-io/resource-management-types';
import { FC } from 'react';

import { sizeVariants } from '../../../../Components/badge/badge';
import { ResourceManagementListItemSkeleton } from '../list/resource-management-list-item-skeleton';
import {
  RESOURCE_LIST_ITEM_STATUS_BADGE_SIZE,
  RESOURCE_LIST_ITEM_Y_PADDING_PX,
} from './resources-list-item';

const BADGE_HEIGHT =
  typeof sizeVariants[RESOURCE_LIST_ITEM_STATUS_BADGE_SIZE].height === 'number'
    ? sizeVariants[RESOURCE_LIST_ITEM_STATUS_BADGE_SIZE].height
    : 30;

/** Calculated height based on Badge height and list item y-padding */
const RESOURCE_LIST_ITEM_SKELETON_HEIGHT =
  2 * RESOURCE_LIST_ITEM_Y_PADDING_PX + BADGE_HEIGHT;

type Props = {
  resourceType: ResourceType;
};

export const ResourcesListSkeleton: FC<Props> = ({ resourceType }) => {
  return (
    <>
      {Array.from({ length: resourceType.totalResources }, (_, i) => (
        <ResourceManagementListItemSkeleton
          key={i}
          height={RESOURCE_LIST_ITEM_SKELETON_HEIGHT}
        />
      ))}
    </>
  );
};
