import { useLocation, useNavigate } from 'react-router';

export const useHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const hasHistory = location.key !== 'default';
  return {
    goBack: (fallbackPath: string) => {
      if (hasHistory) {
        // When editing an event we create a new id which can
        // interfere with the history stack, in that case we
        // want to go back twice, skipping the virtual id
        navigate(location.state?.goBackTwice ? -2 : -1);
      } else {
        navigate(fallbackPath, { replace: true });
      }
    },
  };
};
