import { CircularProgress, Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

import { defaultItemStyling } from './media';

type MediaLoaderProps = {
  file: {
    progress: number;
  };
};

function MediaLoader({ file }: MediaLoaderProps) {
  return (
    <Stack
      sx={{
        ...defaultItemStyling,
        borderColor: lightTheme.palette.neutral.n100,
        borderStyle: 'dashed',
      }}
    >
      <CircularProgress
        variant="determinate"
        value={file.progress < 20 ? 20 : file.progress} // Ensure progress is visible
      />
    </Stack>
  );
}

export default MediaLoader;
