import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { CloseOutlined } from '@mui/icons-material';
import { Dialog, Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { useExperience } from '../../../Hooks/useExperience';
import routes from '../../../Utils/routes';

export const DeleteExperienceDialog = NiceModal.create(
  ({ experienceId }: { experienceId: string }) => {
    const { t } = useTranslation();
    const modal = useModal();

    const handleClose = () => {
      modal.resolve();
      modal.remove();
    };

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { deleteExperience } = useExperience(experienceId);

    const handleDelete = async () => {
      try {
        setIsLoading(true);

        await deleteExperience.mutateAsync(experienceId);

        handleClose();
        toast.success(t('experience.details.delete.success'));
        navigate(routes.experience.overview, { replace: true });
      } catch (error) {
        toast.error(t('experience.details.delete.error'));
        setIsLoading(false);
        captureException(error);
      }
    };

    return (
      <Dialog
        key={experienceId}
        open={modal.visible}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        disableRestoreFocus
      >
        <Stack
          sx={{
            paddingTop: { xs: 5.5, sm: 4 },
            paddingInline: { xs: 2, sm: 4 },
            paddingBottom: 4,
            gap: 4,
            flexGrow: 1,
          }}
        >
          <CloseOutlined
            sx={{
              position: 'absolute',
              top: { xs: 24, sm: 32 },
              right: { xs: 24, sm: 32 },
              zIndex: 1,
              ':hover': { cursor: 'pointer' },
            }}
            onClick={handleClose}
          />
          <Stack gap={1}>
            <Text variant="medium" fontSize="xlarge">
              {t('experience.details.delete.title')}
            </Text>
            <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
              {t('experience.details.delete.description')}
            </Text>
          </Stack>
          <Stack
            sx={{
              gap: 2,
              flexDirection: { xs: 'column-reverse', sm: 'row' },
            }}
          >
            <Button
              type="button"
              size="large"
              variant="secondary"
              onClick={handleClose}
              fullWidth
              style={{
                flexShrink: 'unset',
              }}
              disabled={isLoading}
            >
              {t('utils.generic.cancel')}
            </Button>
            <Button
              type="button"
              size="large"
              variant="danger"
              fullWidth
              style={{
                flexShrink: 'unset',
              }}
              loading={isLoading}
              onClick={handleDelete}
            >
              {t('utils.generic.delete')}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    );
  }
);
