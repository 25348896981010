import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import {
  Resource,
  ResourceType,
} from '@understory-io/resource-management-types';
import { Suspense } from 'react';
import { Await } from 'react-router';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { ResourcesListEmpty } from './resources-list-empty';
import { ResourcesListItem } from './resources-list-item';
import { ResourcesListSkeleton } from './resources-list-skeleton';

type Props = {
  resourceType: ResourceType;
  resourcesPromise: Promise<Array<Resource>>;
};

export const ResourcesList = ({ resourceType, resourcesPromise }: Props) => {
  const { t } = useTranslate('resourceManagement.details.list');

  if (resourceType.totalResources === 0) {
    return <ResourcesListEmpty />;
  }

  return (
    <Stack gap={2}>
      <Text color={lightTheme.palette.neutral.n300} fontSize="small">
        {t('allResources')} ({resourceType.totalResources})
      </Text>
      <Suspense
        fallback={<ResourcesListSkeleton resourceType={resourceType} />}
      >
        <Await resolve={resourcesPromise}>
          {(resources) =>
            resources.map((resource) => (
              <ResourcesListItem
                key={resource.resourceId}
                resource={resource}
              />
            ))
          }
        </Await>
      </Suspense>
    </Stack>
  );
};
