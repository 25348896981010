import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { Controller, useFormContext } from 'react-hook-form';

import { ampli } from '../../../../../../../../Ampli';
import { useTranslate } from '../../../../../../../../Hooks/useTranslate';
import { RoleFormSchema } from '../../use-role-form-validation';
import { RoleFormExperienceSelect } from './role-form-experience-select';

export const RoleFormCapabilitiesExperienceAccess = () => {
  const { t } = useTranslate('settings.team.roles.form');
  const { control } = useFormContext<RoleFormSchema>();

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Text
          fontSize="large"
          variant="medium"
          color={lightTheme.palette.neutral.n400}
        >
          {t('capabilities.experienceAccess.title')}
        </Text>
        <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
          {t('capabilities.experienceAccess.description')}
        </Text>
      </Stack>
      <Controller
        control={control}
        name="experienceAccessIds"
        render={({ field }) => (
          <RoleFormExperienceSelect
            values={field.value}
            updateField={(value) => {
              field.onChange(value);
              ampli.rolesFlowLimitExperiencesSelected({
                experiences: value,
              });
            }}
          />
        )}
      />
    </Stack>
  );
};
