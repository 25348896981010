import { MenuSelectHeading, type MenuSelectHeadingProps } from 'mui-tiptap';

import { useTranslate } from '../../../../../../Hooks/useTranslate';

type CustomMenuSelectHeadingProps = MenuSelectHeadingProps;

export default function CustomMenuSelectHeading({
  ...props
}: CustomMenuSelectHeadingProps) {
  const { t } = useTranslate('utils.toolbar');

  return (
    <MenuSelectHeading
      tooltipTitle={t('textStyle')}
      labels={{
        paragraph: t('formatSize.paragraph'),
        heading1: t('formatSize.heading1'),
        heading2: t('formatSize.heading2'),
      }}
      {...props}
    />
  );
}
