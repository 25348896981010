import styled from '@emotion/styled';
import { Text } from '@understory-io/pixel';
import { Resource } from '@understory-io/resource-management-types';
import { FC } from 'react';
import { useSubmit } from 'react-router';

import { StatusBadge } from '../../../../Components/badge/status-badge';
import { useAuth } from '../../../../Hooks/useAuth';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { RESOURCE_MANAGEMENT_SCOPES } from '../../auth/resource-management-scopes';
import { ResourceManagementListItem } from '../list/resource-management-list-item';
import { ResourceTypeDetailsActionPayload } from './actions';

export const RESOURCE_LIST_ITEM_Y_PADDING_PX = 12;
export const RESOURCE_LIST_ITEM_STATUS_BADGE_SIZE = 'medium';

type Props = {
  resource: Resource;
};

export const ResourcesListItem: FC<Props> = ({ resource }) => {
  const { canAccess } = useAuth();
  const canUpdateAvailability =
    RESOURCE_MANAGEMENT_SCOPES.MANAGE_RESOURCES.some(canAccess);

  const { t } = useTranslate('resourceManagement.details.status');

  const { available, label } = resource;

  const submit = useSubmit();

  return (
    <ResourceManagementListItem
      component="form"
      py={RESOURCE_LIST_ITEM_Y_PADDING_PX / 8}
      onSubmit={(e) => {
        e.preventDefault();
        return false;
      }}
    >
      <StyledResourceLabel>{label}</StyledResourceLabel>
      <StatusBadge
        type="resource-status"
        state={available ? 'available' : 'out-of-order'}
        size={RESOURCE_LIST_ITEM_STATUS_BADGE_SIZE}
        options={
          canUpdateAvailability
            ? [
                {
                  label: t(available ? 'out-of-order' : 'available'),
                  onClick: () => {
                    const payload: ResourceTypeDetailsActionPayload = {
                      type: 'change-resource-availability',
                      resourceTypeId: resource.resourceTypeId,
                      resourceId: resource.resourceId,
                      available: !available,
                    };
                    submit(payload, {
                      method: 'post',
                      encType: 'application/json',
                    });
                  },
                },
              ]
            : undefined
        }
      />
    </ResourceManagementListItem>
  );
};

const StyledResourceLabel = styled(Text)`
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.15px;
`;
