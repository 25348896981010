import React, { useMemo } from 'react';

import { useTranslate } from '../../../../Hooks/useTranslate';
import routes from '../../../../Utils/routes';
import { PublishErrors } from '../edit-experience';
import { useNavigateWithParams } from '../utils/use-navigate-with-params';
type AvailableActions = SingularAction[];

type SingularAction = {
  label: string;
  variant: 'primary' | 'secondary' | 'text' | 'danger';
  onClick: () => void;
};

interface ErrorReturnOptions {
  title: string;
  description: string;
  options: {
    hasGeneralError: boolean;
    hasTranslationError: boolean;
    hasLocationError: boolean;
    hasTicketError: boolean;
  };
  actions: AvailableActions;
}

interface ErrorContextProps {
  errors: PublishErrors | undefined;
  experienceId: string;
  closeDialog: () => void;
  render: (options: ErrorReturnOptions) => React.ReactNode;
}

export function ErrorContext({
  errors,
  experienceId,
  render,
  closeDialog,
}: ErrorContextProps) {
  const navigateWithParams = useNavigateWithParams();
  const { t } = useTranslate('experience.edit.dialog.errors');

  const options: ErrorReturnOptions = useMemo(() => {
    const generalTypes: string[] =
      errors?.general?.map((item) => item.key) || [];

    const totalErrorCount = Object.values(errors ?? {}).reduce(
      (sum, value) => sum + value.length,
      0
    );

    const hasGeneralError = generalTypes.length > 0;
    const hasTranslationError = !errors?.general && totalErrorCount > 0;
    const hasLocationError = generalTypes.includes('locationIds');
    const hasTicketError = generalTypes.includes('price');

    const optionsObj = {
      totalErrorCount,
      generalTypes,
      hasTranslationError,
      hasGeneralError,
      hasLocationError,
      hasTicketError,
    };

    if (hasTranslationError && !hasGeneralError) {
      return {
        title: t('translation.title'),
        description: t('translation.description'),
        options: optionsObj,
        actions: [
          {
            label: t('buttonLabel'),
            variant: 'primary',
            onClick: closeDialog,
          },
        ],
      };
    }

    if (generalTypes?.length === 1 && totalErrorCount === 1) {
      if (generalTypes[0] === 'locationIds') {
        return {
          title: t('location.title'),
          description: t('location.description'),
          options: optionsObj,
          actions: [
            {
              label: t('buttonLabel'),
              variant: 'primary',
              onClick: closeDialog,
            },
          ],
        };
      }

      if (generalTypes[0] === 'price') {
        return {
          title: t('tickets.title'),
          description: t('tickets.description'),
          options: optionsObj,
          actions: [
            {
              label: t('tickets.fixManually'),
              variant: 'primary',
              onClick: () => {
                navigateWithParams(
                  routes.experience.details(experienceId).edit.tickets.index
                );
                closeDialog();
              },
            },
          ],
        };
      }
    }

    return {
      title: t('generic.title'),
      description: t('generic.description'),
      options: optionsObj,
      actions: [
        {
          label: t('buttonLabel'),
          variant: 'primary',
          onClick: closeDialog,
        },
      ],
    };
  }, [errors, t, closeDialog, experienceId, navigateWithParams]);

  return <>{render(options)}</>;
}
