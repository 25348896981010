import { yupResolver } from '@hookform/resolvers/yup';
import { InfoOutlined } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import { ResourceRule as ResourceRuleType } from '@understory-io/experiences-types';
import { lightTheme, Text } from '@understory-io/pixel';
import { Value } from '@understory-io/utils-types/typebox';
import randomBytes from 'randombytes';
import { useEffect } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useActionData,
  useLoaderData,
  useRouteLoaderData,
  useSubmit,
} from 'react-router';
import { toast } from 'react-toastify';
import { InferType } from 'yup';

import { saveExperienceDraft } from '../../../../../Api/Experience';
import { locationsQuery } from '../../../../../Hooks/data/useLocations';
import { useTicketsAndAddons } from '../../../../../Hooks/use-tickets-and-addons';
import { userInfoQuery } from '../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { t } from '../../../../../i18n/config';
import { getAllTicketsFromVariants } from '../../../../../Utils/ticket';
import { resourceTypesQuery } from '../../../../resource-management/data/use-resource-types';
import { resourceRuleSchema } from '../../../schemas/resourceRuleSchema';
import { EditExperienceDialog } from '../../components/dialogs/edit-experience-dialog';
import {
  LoaderData as RouterLoaderData,
  loaderName,
} from '../../edit-experience';
import { getExperience } from '../../queries';
import { AllocationTypeSelect } from './assignment-type-select';
import { ResourceTypeSelectLoader } from './resource-type-select/resource-type-select-loader';

export type LoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>;

export const loader =
  (client: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const { ruleId } = params;

    const { companyId } = await client.fetchQuery(userInfoQuery());
    if (!companyId) {
      throw new Response('Unauthorized', { status: 401 });
    }

    const resourceTypesPromise = client.fetchQuery(resourceTypesQuery());
    const locationsPromise = client.fetchQuery(locationsQuery(companyId));

    const resourceTypesAndLocationsPromise = Promise.all([
      resourceTypesPromise,
      locationsPromise,
    ]);

    return { ruleId, resourceTypesAndLocationsPromise };
  };

export function ResourceRuleForm() {
  const { ruleId, resourceTypesAndLocationsPromise } =
    useLoaderData<LoaderData>();
  const { experience } = useRouteLoaderData(loaderName) as RouterLoaderData;
  const actionData = useActionData() as ActionData;
  const { t } = useTranslate('experience.edit.dialog.resourceRule');

  const resourceRule = experience.resourceRules?.rules?.find(
    (rule) => rule.ruleId === ruleId
  ) ?? {
    ruleId: randomBytes(16).toString('hex'),
    allocationType: 'event',
    resourceTypeIdByLocation: {},
  };

  const tickets = useTicketsAndAddons(experience);

  const isEditFlow = !!ruleId;

  const schema = resourceRuleSchema({
    locationIds: experience.locationIds,
    tickets,
  });

  const formMethods = useForm<InferType<typeof schema>>({
    defaultValues: resourceRule,
    resolver: yupResolver(schema),
    shouldUseNativeValidation: false,
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (!isEditFlow) return;

    formMethods.trigger();
  }, [formMethods, isEditFlow]);

  const submit = useSubmit();

  const onSubmit = (data: FieldValues) => {
    submit(data, {
      method: 'post',
      action: '',
      encType: 'application/json',
    });
  };

  return (
    <FormProvider {...formMethods}>
      <EditExperienceDialog
        title={t('title')}
        description={t('description')}
        shouldClose={actionData?.shouldClose}
        experienceId={experience.id}
        onSubmit={formMethods.handleSubmit(onSubmit)}
        noValidate
      >
        <input hidden {...formMethods.register('ruleId')} />
        <Stack sx={{ gap: 3, width: '100%', alignItems: 'start' }}>
          {experience.locationIds?.map((locationId) => (
            <ResourceTypeSelectLoader
              key={locationId}
              resourceTypesAndLocationsPromise={
                resourceTypesAndLocationsPromise
              }
              locationId={locationId}
            />
          ))}
          <AllocationTypeSelect experience={experience} />
          <StyledInfoBox direction="row" gap={1} alignItems="center">
            <InfoOutlined
              sx={{ width: 24, color: lightTheme.palette.neutral.n300 }}
            />
            <Text color={lightTheme.palette.action.a300} fontSize="small">
              {t('availabilityInfo')}
            </Text>
          </StyledInfoBox>
        </Stack>
      </EditExperienceDialog>
    </FormProvider>
  );
}

const StyledInfoBox = styled(Stack)`
  width: 100%;
  padding: 24px 16px;
  border-radius: 8px;
  background-color: ${lightTheme.palette.neutral.n100};
  border: 1px solid ${lightTheme.palette.neutral.n200};
`;

type ActionData = {
  shouldClose?: boolean;
} | null;

export const action = async ({ params, request }: ActionFunctionArgs) => {
  const { id } = params;

  try {
    if (!id) {
      throw new Response('Invalid id', { status: 400 });
    }

    const experience = await getExperience(id);

    const unknownPayload = await request.json();

    const payload = Value.Clean(ResourceRuleType, unknownPayload);

    if (!Value.Check(ResourceRuleType, payload)) {
      throw new Response('Incorrect payload', { status: 400 });
    }

    resourceRuleSchema({
      locationIds: experience.locationIds,
      tickets: getAllTicketsFromVariants(experience.price.variants),
    }).validateSync(payload);

    const rules = (experience.resourceRules?.rules ?? []).map((rule) => {
      if (rule.ruleId === payload.ruleId) {
        return payload;
      }

      return rule;
    });

    if (!rules.some((rule) => rule.ruleId === payload.ruleId)) {
      rules.push(payload);
    }

    const experienceToSave = {
      ...experience,
      resourceRules: {
        version: 1,
        rules,
      },
    } as const;

    await saveExperienceDraft(id, experienceToSave);

    return { shouldClose: true };
  } catch (error) {
    toast.error(t('utils.errors.generic'), { autoClose: 5000 });
    return null;
  }
};
