import {
  type DraftExperience,
  type Experience,
  type RegularExperience,
} from '@understory-io/experiences-types';
import {
  ExperienceSortOption,
  UnderstoryApiCollection,
  UnderstoryApiSingleResource,
} from '@understory-io/utils-types';

import type { IExperience } from '../Hooks/useExperience';
import { queryClient } from '../query-client';
import { apiServices } from './apiServices';
import api, { IShallowSuccessResponse } from './index';

export const getExperience = async (id: string) => {
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EXPERIENCES_URL is not defined'
    );
  }

  const { data } = await api.get<IExperience>(
    `${apiServices.experiences}/${id}`
  );

  return data;
};

export const getDraftExperience = async (id: string) => {
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const {
    data: { item },
  } = await api.get<UnderstoryApiSingleResource<RegularExperience>>(
    `${apiServices.internalApi}/v1/experiences/${id}?includeDrafts=true`
  );

  return item;
};

export const getPublishedExperience = async (id: string) => {
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const {
    data: { item },
  } = await api.get<UnderstoryApiSingleResource<Experience>>(
    `${apiServices.internalApi}/v1/experiences/${id}`
  );

  return item;
};

export const getExperiences = async (params?: ExperienceQueryOptions) => {
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const {
    data: { items },
  } = await api.get<UnderstoryApiCollection<Experience>>(
    `${apiServices.internalApi}/v1/experiences/`,
    { params }
  );

  return items;
};

export const saveExperienceDraft = async (
  id: string,
  data: DraftExperience
) => {
  await queryClient.invalidateQueries({
    queryKey: ['experience', id, 'edit'],
  });
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.put(`${apiServices.internalApi}/v1/experiences/${id}/draft`, {
    item: data,
  });
};

export const updateExperienceMetaData = async (
  id: string,
  data: Experience['metadata']
) => {
  await queryClient.invalidateQueries({
    queryKey: ['experience', id, 'edit'],
  });
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.post(
    `${apiServices.internalApi}/v1/experiences/${id}/metadata`,
    data
  );
};

export const publishExperienceDraft = async (id: string) => {
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.post(`${apiServices.internalApi}/v1/experiences/${id}/publish`);
};

export type ExperienceQueryOptions = {
  sortExperiencesBy?: ExperienceSortOption;
  defaultLanguage?: string;
  language?: string;
  type?: 'all' | 'shared' | 'owned';
  includeDrafts?: boolean;
};

export const updateExperience = async (
  id: string,
  payload: Record<string, any>
) => {
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EXPERIENCES_URL is not defined'
    );
  }

  const { data } = await api.put<IShallowSuccessResponse>(
    `${apiServices.experiences}/${id}`,
    payload
  );

  return data;
};

export const deleteExperience = async (experienceId: string) => {
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const response = await api.delete(
    `${apiServices.internalApi}/v1/experiences/${experienceId}`
  );

  return response;
};
