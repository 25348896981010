import { RegularExperience } from '@understory-io/experiences-types';
import {
  CompanyProfile,
  Domain,
  ExperienceTag,
  Location,
} from '@understory-io/utils-types';

import * as api from '../../../Api';
import { getCompanyDomain } from '../../../Api/Domains';
import { QueryKeys } from '../../../Hooks/data/useLocations';
import { queryClient } from '../../../query-client';

export async function getExperience(id: string) {
  const experience = await queryClient.fetchQuery<RegularExperience>({
    queryKey: ['experience', id, 'edit'],
    queryFn: () => api.getDraftExperience(id),
  });

  return experience;
}

export async function getCompanyProfile() {
  const companyProfile = await queryClient.fetchQuery<CompanyProfile>({
    queryKey: ['company'],
    queryFn: () => api.getCompanyProfile(),
  });

  return companyProfile;
}

export async function getLocations(companyId: string) {
  const locations = await queryClient.fetchQuery<Location[]>({
    queryKey: [QueryKeys.locations, companyId],
    queryFn: () => api.getLocations(companyId),
  });

  return locations;
}

export async function getDomain(companyId: string) {
  const activeDomain = await queryClient.fetchQuery<Domain>({
    queryKey: ['domain', companyId],
    queryFn: () => getCompanyDomain(companyId),
  });

  return activeDomain;
}

export async function getTags() {
  const tags = await queryClient.fetchQuery<ExperienceTag[]>({
    queryKey: ['tags'],
    queryFn: () => api.getExperienceTags(),
  });

  return tags;
}
