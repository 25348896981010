import { Ticket } from '@understory-io/utils-types';
import { useMemo } from 'react';

export const useTicketGroups = (tickets?: Ticket[]) => {
  return useMemo(() => {
    if (!tickets)
      return { activeTickets: [], checkedInTickets: [], cancelledTickets: [] };

    return {
      activeTickets: tickets.filter((ticket) => ticket.status === 'active'),
      checkedInTickets: tickets.filter(
        (ticket) => ticket.status === 'checked-in'
      ),
      cancelledTickets: tickets.filter(
        (ticket) => ticket.status === 'cancelled'
      ),
    };
  }, [tickets]);
};
