import { Domain as DomainType } from '@understory-io/utils-types';

import { apiServices } from './apiServices';
import api from './index';

export type Domain = {
  type: 'custom' | 'subdomain';
  domain: string;
};

export const domainAvailable = async (domain: string) => {
  domain = domain.toLowerCase();

  if (typeof apiServices.domains === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DOMAINS_URL is not defined'
    );
  }

  const { data } = await api.get<{ available: boolean }>(
    `${apiServices.domains}/${domain}/available`
  );

  return data;
};

export const createDomain = async (payload: Domain) => {
  payload.domain = payload.domain.toLowerCase();

  if (typeof apiServices.domains === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DOMAINS_URL is not defined'
    );
  }

  await api.post(`${apiServices.domains}`, { ...payload });
};

export const deleteDomain = async (domain: string) => {
  domain = domain.toLowerCase();

  if (typeof apiServices.domains === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DOMAINS_URL is not defined'
    );
  }

  await api.delete(`${apiServices.domains}/${domain}`);
};

export const getCompanyDomain = async (companyId: string) => {
  if (typeof apiServices.domains === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DOMAINS_URL is not defined'
    );
  }

  const url = `${apiServices.domains}/companies/${companyId}/domain`;

  const response = await api.get<DomainType>(url);

  if (response.status !== 200) {
    throw new Error('Failed to get company domain');
  }

  return response.data;
};
