import { useParams } from 'react-router';

import { ShowForScope } from '../../../../Components/AllowForScope/AllowForScope';
import { RouterLinkButton } from '../../../../Components/button/router-link-button';
import { useTranslate } from '../../../../Hooks/useTranslate';
import routes from '../../../../Utils/routes';
import { RESOURCE_MANAGEMENT_SCOPES } from '../../auth/resource-management-scopes';
import { ResourceManagementEmptyList } from '../list/resource-management-empty-list';

export const ResourcesListEmpty = () => {
  const { resourceTypeId } = useParams();

  const { t } = useTranslate('resourceManagement.details');

  return (
    <ResourceManagementEmptyList
      title={t('list.empty.title')}
      description={t('list.empty.description')}
      action={
        resourceTypeId && (
          <ShowForScope scopes={RESOURCE_MANAGEMENT_SCOPES.MANAGE_RESOURCES}>
            <RouterLinkButton
              variant="secondary"
              size="medium"
              href={
                routes.resourceManagement.details(resourceTypeId).createResource
              }
            >
              {t('addResource')}
            </RouterLinkButton>
          </ShowForScope>
        )
      }
    />
  );
};
