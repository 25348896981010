import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabelOwnProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import { useId } from 'react';
import { useController } from 'react-hook-form';

import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { useFeatureSetting } from '../context/feature_setting_context';

export type TFeatureRadio = RadioGroupProps & {
  name: string;
  options: { value: string; label: string }[];
  required?: boolean;
  labelProps?: InputLabelOwnProps;
};

const FeatureRadio = ({
  name,
  labelProps = {},
  options,
  onChange,
  defaultValue,
  ...props
}: TFeatureRadio) => {
  const { control, save, feature } = useFeatureSetting();

  const { t } = useTranslate(`settings.features.${feature}.${name}`);

  const id = useId();

  const {
    company: { data: company },
  } = useProfile();

  const featureParams = company?.features?.[feature]?.params as Record<
    string,
    string | number
  >;
  const defaultVal = defaultValue || featureParams?.[name];

  const { field } = useController({
    name: `params.${name}`,
    defaultValue: defaultVal ?? '',
    control,
  });

  return (
    <FormControl fullWidth sx={{ gap: 1 }}>
      <FormLabel {...labelProps} id={`${id}-label`}>
        {t('label')}
      </FormLabel>
      <RadioGroup
        id={id}
        name={name}
        defaultValue={field.value}
        onChange={(event, child) => {
          field.onChange(event);
          onChange?.(event, child);
          save();
        }}
        {...props}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            label={option.label}
            control={<Radio size="small" />}
            disableTypography
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default FeatureRadio;
