import { Stack } from '@mui/material';
import { Controller, Path, useFormContext } from 'react-hook-form';

import ControlledPhoneInput from '../../../../../Components/PhoneInput/ControlledPhoneInput';
import { useAuth } from '../../../../../Hooks/useAuth';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { StyledTextField } from '../../../shared/StyledTextField';
import { CompanyFormData } from '../../domain/use-company-form-validation';

export const CompanyFormDetails = () => {
  const { t } = useTranslate('settings.company');
  const { canAccess } = useAuth();

  const {
    formState: { errors },
    control,
  } = useFormContext<CompanyFormData>();

  const disableFields = !canAccess('company.write');

  return (
    <Stack gap={2}>
      <Controller
        name={'name'}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t('companyName')}
            error={!!errors.name}
            helperText={errors.name?.message}
            disabled={disableFields}
          />
        )}
      />
      <Controller
        name={'website'}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t('website')}
            error={!!errors.website}
            helperText={errors.website?.message}
            disabled={disableFields}
          />
        )}
      />
      <Controller
        name={'cvrNr'}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t('cvrNr')}
            error={!!errors.cvrNr}
            helperText={errors.cvrNr?.message}
            disabled={disableFields}
          />
        )}
      />
      <Controller
        name={'companyEmail'}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t('email')}
            error={!!errors.companyEmail}
            helperText={errors.companyEmail?.message}
            disabled={disableFields}
          />
        )}
      />
      <ControlledPhoneInput
        name={'companyPhone' as Path<CompanyFormData>}
        control={control}
        disabled={disableFields}
        sx={{ maxWidth: 320, minWidth: 320 }}
      />
    </Stack>
  );
};
