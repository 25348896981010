import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { useTranslation } from 'react-i18next';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { InformationRequest } from '../../../schemas/information-request-schema';

type RequestFormProps = {
  defaultValues: Omit<InformationRequest, 'id' | 'request'> & {
    request?: string;
  };
  errors?: Partial<Record<keyof InformationRequest, string>>;
};

export const RequestForm = ({ defaultValues, errors }: RequestFormProps) => {
  const { t } = useTranslate('experience.edit.dialog.guestInfo.input');
  const { t: tError } = useTranslation();

  return (
    <Stack sx={{ width: '100%', gap: 1 }}>
      <TextField
        name="request"
        label={t('label.request')}
        placeholder={t('placeholder.request')}
        InputLabelProps={{ shrink: true }}
        defaultValue={defaultValues.request}
        error={!!errors?.request}
        helperText={errors?.request ? tError(errors.request) : undefined}
        fullWidth
      />
      <FormControlLabel
        name="required"
        label={t('label.required')}
        control={
          <Checkbox size="small" defaultChecked={defaultValues.required} />
        }
      />
      <FormControl error={!!errors?.scope}>
        <RadioGroup name="scope" defaultValue={defaultValues.scope}>
          <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
            {t('label.scope')}
          </Text>
          <FormControlLabel
            value="ticket"
            control={<Radio size="small" />}
            label={t('scope.option.ticket')}
          />
          <FormControlLabel
            value="booking"
            control={<Radio size="small" />}
            label={t('scope.option.booking')}
          />
        </RadioGroup>
        {!!errors?.scope && (
          <FormHelperText>{tError(errors.scope)}</FormHelperText>
        )}
      </FormControl>
    </Stack>
  );
};
