import NiceModal from '@ebay/nice-modal-react';
import {
  DeleteOutlineOutlined,
  MoreVert,
  PhoneRounded,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  BoxProps,
  capitalize,
  Card,
  Divider,
  Grid,
  IconButton,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { Button, lightTheme } from '@understory-io/pixel';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useInvitations } from '../../Hooks/data/useInvitations';
import { useRoles } from '../../Hooks/data/useRoles';
import { useProfile } from '../../Hooks/useProfile';
import { useTranslate } from '../../Hooks/useTranslate';
import { TUser, useUsers } from '../../Hooks/useUsers';
import { ConfirmDialog } from '../../Modals/ConfirmDialog';
import { StatusBadge } from '../badge/status-badge';
import { CopyLink } from '../CopyLink/CopyLink';
import { Input } from '../Input/Input';
import { ListSkeleton } from '../ListSkeleton/ListSkeleton';

const columns = [
  {
    key: 'none',
    props: {
      xs: 0.75,
    },
    value: (el: TUser) => <Avatar src={el.pictures?.profile?.url} />,
  },
  {
    key: 'name',
    label: 'Navn',
    props: {
      xs: 3,
    },
    value: (el: TUser) => el.name,
  },
  {
    key: 'role',
    label: 'Rolle',
    props: {
      xs: 1,
    },
    value: (el: MappedUser) => capitalize(el.roleName),
  },
  {
    key: 'phone',
    label: 'Telefon',
    props: {
      xs: 1.8,
    },
    value: (el: TUser) => (
      <Box display={'flex'} alignItems={'center'}>
        <PhoneRounded fontSize={'small'} sx={{ mr: 1 }} />
        {el.phone}
      </Box>
    ),
  },
  {
    key: 'email',
    label: 'Email',
    value: (el: TUser) => (
      <CopyLink
        href={`mailto:${el.email}`}
        isEmail
        label={
          <Button
            size="medium"
            variant="primary"
            disabled
            style={{ pointerEvents: 'none' }}
          >
            {el.email}
          </Button>
        }
      />
    ),
    valueProps: {
      fontWeight: 600,
    },
    props: {
      xs: 4,
    },
  },
  {
    key: 'status',
    label: 'Status',
    value: (el: TUser & { status: 'active' | 'invited' }) => (
      <StatusBadge type="user" state={el.status} />
    ),
    valueProps: {
      fontWeight: 600,
    },
    props: {
      xs: true,
    },
  },
];

const UserPopover = ({
  id,
  name,
  email,
  pictures,
  phone,
  role,
  status,
  invitationId,
  onUpdateRole,
  onDelete,
}: MappedUser & {
  onDelete: (id: string, type: string, email: string) => void;
  onUpdateRole: (
    id: string,
    type: string
  ) => (evt: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { t } = useTranslate('settings.team.user');
  const { me } = useProfile();
  const { roles } = useRoles();

  return (
    <Box p={3} minWidth={280}>
      <Box display={'flex'}>
        <Avatar src={pictures?.profile?.url} sx={{ width: 64, height: 64 }} />
        <Stack spacing={0.5} ml={1} justifyContent={'center'}>
          <Typography variant={'h5'}>{name}</Typography>
          <Typography variant={'body2'}>{email}</Typography>
          <Typography variant={'body2'}>{phone}</Typography>
        </Stack>
      </Box>
      <Divider sx={{ mt: 2, mb: 3 }} />
      <Input
        fullWidth
        type={'select'}
        size={'small'}
        InputLabelProps={{ shrink: Boolean(role) }}
        onChange={onUpdateRole(
          id ?? invitationId,
          status === 'active' ? 'user' : 'invitation'
        )}
        helperText={t('changeRoleHelperText')}
        label={t('role')}
        defaultValue={role}
        disabled={me.data.id === id}
        options={roles.data
          ?.filter((r) => r.status === 'active' || r.id === role)
          .map(({ name, id }) => ({ label: name, key: id }))}
      />
      <Divider sx={{ mt: 2, mb: 3 }} />
      <Stack spacing={1}>
        {status === 'active' && me.data.id !== id && (
          <a href={`mailto:${email}`}>
            <Button size="medium" variant="primary" fullWidth>
              {t('actions.writeTo', { userName: name?.split(' ')?.[0] ?? '' })}
            </Button>
          </a>
        )}

        <Button
          size="medium"
          variant="secondary"
          disabled={me.data.id === id}
          fullWidth
          onClick={() =>
            onDelete(
              id ?? invitationId,
              status === 'active' ? 'user' : 'invitation',
              email
            )
          }
          leftIcon={<DeleteOutlineOutlined fontSize="small" />}
        >
          {status === 'active'
            ? t('actions.delete')
            : t('actions.revokeInvitation')}
        </Button>
      </Stack>
    </Box>
  );
};

type MappedUser = TUser & {
  roleName: string;
  status: 'active' | 'invited';
  invitationId: string;
};

export const UsersList = ({
  onUpdate,
  ...props
}: BoxProps & { onUpdate?: (users: any[]) => void }) => {
  const { t } = useTranslate('settings.team');

  const { users, remove, updateRole: updateUserRole } = useUsers();
  const { invitations, revoke, updateRole } = useInvitations();
  const { company } = useProfile();
  const { roles } = useRoles();

  const [openUser, setOpenUser] = useState(-1);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [_users, setUsers] = useState<MappedUser[]>([]);

  useEffect(() => {
    const list: MappedUser[] = [];

    if (users.data) {
      list.push(
        ...users.data.map(
          (el) =>
            ({
              id: el.id,
              email: el.email,
              name: el.name,
              phone: el.phone,
              roleName:
                roles.data?.find((r) => r.id === el.role)?.name ?? el.role,
              role: el.role,
              status: 'active',
              pictures: el.pictures,
            }) as MappedUser
        )
      );
    }

    if (invitations.data) {
      list.push(
        ...invitations.data.map(
          (el) =>
            ({
              id: undefined,
              invitationId: el.id,
              email: el.claims.email,
              name: el.claims.name,
              phone: '',
              roleName:
                roles.data?.find((r) => r.id === el.claims.role)?.name ?? '',
              role: el.claims.role,
              status: 'invited',
            }) as MappedUser
        )
      );
    }

    setUsers(list.sort((a, b) => a.status.localeCompare(b.status)));
  }, [invitations.data, users.data]);

  const handleClick =
    (index: number) => (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
      setOpenUser(index);
    };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenUser(-1);
  };

  const handleUpdateRole =
    (id: string, type: string) =>
    async (evt: ChangeEvent<HTMLInputElement>) => {
      if (type === 'user') {
        // Update invitation role

        handleClose();

        toast.loading(t('loading', 'toast.updateUserRole'), {
          toastId: `start-update-user-role-${id}`,
        });

        await updateUserRole(id, evt.target.value).then(
          () => {
            // Success
            toast.dismiss(`start-update-user-role-${id}`);
            toast.success(t('success', 'toast.updateUserRole'), {
              autoClose: 5000,
            });
          },
          (err) => {
            // Failure
            console.log('Failed to update user role', err);
            toast.dismiss(`start-update-user-role-${id}`);
            toast.error(t('failed', 'toast.updateUserRole'), {
              autoClose: 5000,
            });
          }
        );
      } else {
        // Update invitation role
        handleClose();

        toast.loading(t('loading', 'toast.updateInvitationRole'), {
          toastId: `start-update-invitation-role-${id}`,
        });

        // Update invitation role
        await updateRole(id, evt.target.value).then(
          () => {
            // Success
            toast.dismiss(`start-update-invitation-role-${id}`);
            toast.success(t('success', 'toast.updateInvitationRole'), {
              autoClose: 5000,
            });
          },
          (err) => {
            // Failure
            console.log('Failed to update invitation role', err);
            toast.dismiss(`start-update-invitation-role-${id}`);
            toast.error(t('failed', 'toast.updateInvitationRole'), {
              autoClose: 5000,
            });
          }
        );
      }
    };

  const handleDeleteUser = (id: string, type: string, email: string) => {
    let headline = '';
    let title = '';
    let toastLoading = '';
    let toastSuccess = '';
    let toastError = '';
    let fn: (id: string) => Promise<void>;

    if (type === 'user') {
      headline = t('headlineRevokeAccess', 'dialogs.confirmDelete', { email });
      title = t('titleRevokeAccess', 'dialogs.confirmDelete');
      toastLoading = t('loading', 'toast.revokeUserAccess');
      toastSuccess = t('success', 'toast.revokeUserAccess');
      toastError = t('failed', 'toast.revokeUserAccess');
      fn = remove;
    } else {
      headline = t('headlineRevokeInvitation', 'dialogs.confirmDelete', {
        email,
      });
      title = t('titleRevokeInvitation', 'dialogs.confirmDelete');
      toastLoading = t('loading', 'toast.revokeInvitation');
      toastSuccess = t('success', 'toast.revokeInvitation');
      toastError = t('failed', 'toast.revokeInvitation');
      fn = revoke;
    }

    NiceModal.show(ConfirmDialog, {
      headline,
      title: title,
      confirmLabel: t('actions.primary', 'dialogs.confirmDelete'),
    }).then(() => {
      // Start loading toast
      toast.loading(toastLoading, {
        toastId: `start-remove-user-invitation-${id}`,
      });

      // Begin removal of invitation/user
      fn(id).then(
        () => {
          // Success!
          toast.dismiss(`start-remove-user-invitation-${id}`);
          toast.success(toastSuccess, {
            toastId: `remove-success-${id}`,
            autoClose: 5000,
          });
        },
        () => {
          toast.dismiss(`start-remove-user-invitation-${id}`);
          toast.warn(toastError, {
            toastId: `remove-error-${id}`,
            autoClose: 5000,
          });
        }
      );
    });

    // Close popover
    handleClose();
  };

  return invitations.isLoading || users.isLoading ? (
    <ListSkeleton />
  ) : (
    <Box {...props}>
      <Grid container p={'12px'} mb={1}>
        {columns.map((el) => (
          <Grid
            fontSize={'0.75em'}
            fontWeight={600}
            textTransform={'uppercase'}
            key={el.key}
            item
            {...el.props}
            component={Typography}
          >
            {t(el.key, 'utils.tables.header')}
          </Grid>
        ))}
      </Grid>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <UserPopover
          {..._users[openUser]}
          onDelete={handleDeleteUser}
          onUpdateRole={handleUpdateRole}
        />
      </Popover>

      <Stack spacing={1}>
        {(users.isLoading || company.isLoading) && <ListSkeleton />}

        {_users?.map((user, index) => (
          <Grid
            key={user.id ?? `user-${index}`}
            container
            component={Card}
            p={1.5}
            alignItems={'center'}
            sx={{
              cursor: 'pointer',
              '&:hover': { backgroundColor: lightTheme.palette.neutral.n100 },
            }}
            onClick={handleClick(index)}
          >
            {columns.map((el) => (
              <Grid
                fontSize={'0.88em'}
                key={el.key}
                item
                {...el.props}
                {...el.valueProps}
                component={Typography}
              >
                {el.value?.(user)}
              </Grid>
            ))}
            <Grid item flexGrow={1} textAlign={'right'}>
              <IconButton size={'small'}>
                <MoreVert />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Stack>
    </Box>
  );
};
