import { useFlags } from 'launchdarkly-react-client-sdk';
import { ChangeEvent } from 'react';

import { ampli } from '../../../../Ampli';
import FeatureItem from '../../../../Components/FeatureItem/FeatureItem';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import FeatureRadio from '../inputs/FeatureRadio';

const FEATURE_ID = 'ticketing';

export const TicketingFeature = () => {
  const { t } = useTranslate('settings.features');
  const {
    company: { data: company },
  } = useProfile();
  const flags = useFlags();

  const configuredTicketGrouping =
    company?.features.ticketing?.params?.ticketGrouping || 'booking';

  const trackToggling = (enabled: boolean) => {
    if (enabled) {
      ampli.ticketingToggledOn({ feature_enabled: FEATURE_ID });
    } else {
      ampli.ticketingToggledOff();
    }
  };
  const trackApplied = (e: ChangeEvent<HTMLInputElement>) => {
    ampli.ticketingTicketGroupingApplied({
      ticket_grouping: (e.target.value as string) || 'booking',
    });
  };

  if (!flags.featureTicketingStorefrontFeature) {
    return null;
  }

  return (
    <FeatureItem feature={FEATURE_ID} onToggle={trackToggling}>
      <FeatureRadio
        name="ticketGrouping"
        defaultValue={configuredTicketGrouping}
        options={[
          { value: 'booking', label: t('ticketing.ticketGrouping.booking') },
          { value: 'guest', label: t('ticketing.ticketGrouping.guest') },
        ]}
        onChange={trackApplied}
      />
    </FeatureItem>
  );
};
