import { QueryClient } from '@tanstack/react-query';
import { getValidator } from '@understory-io/utils-types';
import { Static, Type } from '@understory-io/utils-types/typebox';

import { updateResource } from '../../../../Api/resource-management';
import { resourcesQuery } from '../../data/use-resources';

export const ResourceTypeDetailsActionPayload = Type.Object({
  type: Type.Literal('change-resource-availability'),
  resourceTypeId: Type.String(),
  resourceId: Type.String(),
  available: Type.Boolean(),
});
export type ResourceTypeDetailsActionPayload = Static<
  typeof ResourceTypeDetailsActionPayload
>;

export const actionPayloadValidator = getValidator(
  ResourceTypeDetailsActionPayload
);

export async function handlePayload(
  client: QueryClient,
  payload: ResourceTypeDetailsActionPayload
) {
  if (payload.type === 'change-resource-availability') {
    await updateResource(payload.resourceTypeId, payload.resourceId, {
      available: payload.available,
    });

    await client.invalidateQueries({
      queryKey: resourcesQuery(payload.resourceTypeId).queryKey,
    });

    return;
  }
}
