import * as yup from 'yup';

export const locationSchema = yup
  .array()
  .of(
    yup
      .string()
      .required('utils.validationErrors.required')
      .typeError('utils.validationErrors.invalidString')
  )
  .defined()
  .min(1, 'utils.validationErrors.required')
  .default([]);
export type Location = yup.InferType<typeof locationSchema>;
