import { Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import {
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonUnderline,
} from 'mui-tiptap';
import { PropsWithChildren } from 'react';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import CustomMenuSelectHeading from './components/custom-menu-select-heading';

export default function EditorDesktopControls({ children }: PropsWithChildren) {
  const { t } = useTranslate('utils.toolbar');
  return (
    <Stack
      sx={{
        display: { xs: 'none', sm: 'flex' },
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: { xs: 0.5, md: 3 },
      }}
    >
      <CustomMenuSelectHeading
        sx={{ border: `1px solid ${lightTheme.palette.neutral.n100}` }}
      />
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 0.5,
        }}
      >
        <MenuButtonBold tooltipLabel={t('bold')} sx={buttonStylingOverrides} />
        <MenuButtonItalic
          tooltipLabel={t('italic')}
          sx={buttonStylingOverrides}
        />
        <MenuButtonUnderline
          tooltipLabel={t('underline')}
          sx={buttonStylingOverrides}
        />
      </Stack>

      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 0.5,
        }}
      >
        <MenuButtonBulletedList
          tooltipLabel={t('bulletedList')}
          sx={buttonStylingOverrides}
        />
        <MenuButtonOrderedList
          tooltipLabel={t('orderedList')}
          sx={{
            ...buttonStylingOverrides,
          }}
        />
      </Stack>
      <Stack>{children}</Stack>
    </Stack>
  );
}

export const buttonStylingOverrides = {
  border: '1px solid',
  '&.MuiToggleButton-root': {
    border: `1px solid ${lightTheme.palette.neutral.n100} !important`,
  },
};
