import NiceModal from '@ebay/nice-modal-react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router';

import { ErrorElement } from './Components/error-element';
import { InvitePage } from './features/auth/invite/invite-page';
import { InviteView } from './features/auth/invite/invite-view';
import { SignupView } from './features/auth/signup/signup-view';
import {
  AcceptConnectionDialog,
  action as acceptConnectionAction,
  loader as acceptConnectionLoader,
} from './features/connect/accept/accept-dialog';
import {
  action as addConnectionAction,
  AddConnectionDialog,
  loader as addConnectionLoader,
} from './features/connect/add/add-connection-dialog';
import {
  action as declineConnectionAction,
  DeclineConnectionDialog,
  loader as declineConnectionLoader,
} from './features/connect/decline/decline-dialog';
import {
  ConnectionDetailsPage,
  loader as connectionDetailsLoader,
} from './features/connect/details/connection-details-page';
import {
  action as disconnectConnectionAction,
  DisconnectConnectionDialog,
  loader as disconnectConnectionLoader,
} from './features/connect/details/disconnect-dialog';
import {
  action as editConnectionAction,
  EditConnectionDialog,
  loader as editConnectionLoader,
} from './features/connect/details/edit-dialog';
import {
  action as connectInvitationDialogAction,
  InvitationDialog,
  loader as connectInvitationDialogLoader,
} from './features/connect/invitation/invitation-dialog';
import {
  ConnectSettingsList,
  loader as connectSettingsLoader,
} from './features/connect/list/connect-settings-list';
import { DashboardPage } from './features/dashboard/dashboard-page';
import { EventCreate } from './features/events/event_create/ui/EventCreate';
import { EventEdit } from './features/events/event_edit/ui/EventEdit';
import { EventUpsertContextProvider } from './features/events/event_upsert/domain/event_upsert_context';
import {
  editCapacityAction,
  editCapacityLoader,
} from './features/experiences/details/event-list/edit-capacity';
import {
  updateEventStatusAction,
  updateEventStatusLoader,
} from './features/experiences/details/event-list/event-list-item-desktop';
import {
  ExperienceDetailsErrorPage,
  ExperienceDetailsPage,
  loader as experienceDetailsLoader,
  updateStatusAction,
  updateStatusLoader,
} from './features/experiences/details/experience-details-page';
import EditExperience, {
  action as editExperienceAction,
  loader as editExperienceLoader,
  loaderName as editExperienceLoaderName,
} from './features/experiences/edit/edit-experience';
import CapacityForm, {
  action as capacityFormAction,
} from './features/experiences/edit/forms/capacity';
import CutoffTimeForm, {
  action as cutoffTimeFormAction,
} from './features/experiences/edit/forms/cutoff-time/cutoff-time';
import DescriptionDialog, {
  action as descriptionDialogAction,
} from './features/experiences/edit/forms/description';
import DisplayPriceForm, {
  action as displayPriceFormAction,
} from './features/experiences/edit/forms/display-price';
import DeleteInfoRequestForm, {
  action as deleteInfoRequestFormAction,
  loader as deleteInfoRequestFormLoader,
} from './features/experiences/edit/forms/guest-info/delete-info-request';
import GuestInfoForm, {
  action as guestInfoFormAction,
  loader as guestInfoFormLoader,
} from './features/experiences/edit/forms/guest-info/guest-info';
import PracticalInfoDialog, {
  action as practicalInfoDialogAction,
} from './features/experiences/edit/forms/practical-info';
import {
  action as deleteResourceRuleAction,
  DeleteResourceRule,
  loader as deleteResourceRuleLoader,
} from './features/experiences/edit/forms/resources/delete-resource-rule';
import {
  action as resourceRuleFormAction,
  loader as resourceRuleFormLoader,
  ResourceRuleForm,
} from './features/experiences/edit/forms/resources/resource-rule-form';
import TagsDialog, {
  action as tagsFormAction,
} from './features/experiences/edit/forms/tags/tags';
import DeleteTicketForm, {
  action as deleteTicketFormAction,
  loader as deleteTicketFormLoader,
} from './features/experiences/edit/forms/tickets/delete-ticket';
import TicketsForm, {
  action as ticketsFormAction,
  loader as ticketsFormLoader,
} from './features/experiences/edit/forms/tickets/tickets';
import WhatsIncludedDialog, {
  action as whatsIncludedDialogAction,
} from './features/experiences/edit/forms/whats-included';
import PreviewDraftDialog, {
  loader as previewDraftDialogLoader,
} from './features/experiences/edit/preview/preview-dialog';
import AdjustValueDialog, {
  action as adjustValueAction,
} from './features/giftcards/details/adjust-value/adjust-value-dialog';
import DeactivateGiftCardDialog, {
  action as deactivateGiftCardAction,
} from './features/giftcards/details/deactivate/deactivate-gift-card-dialog';
import { ErrorDialog as GiftCardErrorDialog } from './features/giftcards/details/error-dialog';
import GiftCardDetailsPage, {
  GiftCardDetailsErrorPage,
  loader as giftCardDetailsLoader,
} from './features/giftcards/details/gift-card-details-page';
import RefundGiftCarddialog, {
  action as refundGiftCardAction,
} from './features/giftcards/details/refund/refund-gift-card-dialog';
import { GiftCardsView } from './features/giftcards/ui/GiftCardsView';
import { GeneratingPreview } from './features/preview/generating-preview/generating-preview';
import { PreviewView } from './features/preview/ui/PreviewView';
import { RESOURCE_MANAGEMENT_SCOPES } from './features/resource-management/auth/resource-management-scopes';
import {
  action as createResourceTypeAction,
  CreateResourceTypeDialog,
  loader as createResourceTypeLoader,
} from './features/resource-management/resource-types/create/create-resource-type-dialog';
import {
  action as resourceTypeDetailsAction,
  loader as resourceTypeDetailsLoader,
  ResourceTypeDetails,
} from './features/resource-management/resource-types/details/resource-type-details';
import {
  loader as resourceTypesLoader,
  ResourceTypesOverview,
} from './features/resource-management/resource-types/overview/resource-types-overview';
import {
  action as createResourceAction,
  CreateResourceDialog,
  loader as createResourceLoader,
} from './features/resource-management/resources/create/create-resource-dialog';
import { useMenu } from './Hooks/useMenu';
import { AuthPage } from './Pages/Auth/AuthPage';
import { ForgotPasswordPage } from './Pages/Auth/ForgotPasswordPage';
import { LoginPage } from './Pages/Auth/LoginPage';
import { ResetPasswordPage } from './Pages/Auth/ResetPasswordPage';
import { BookingPage } from './Pages/BookingPage';
import { BookingsPage } from './Pages/BookingsPage';
import { CalendarPage } from './Pages/CalendarPage';
import { DiscountPage } from './Pages/DiscountPage';
import { EventPage } from './Pages/EventPage';
import { ExperiencePage } from './Pages/ExperiencePage';
import { ExperiencesPage } from './Pages/ExperiencesPage';
import { GrowthPage } from './Pages/GrowthPage';
import { EconomicConfigurationPage } from './Pages/settings/economic-configuration-page';
import { EconomicDetailsPage } from './Pages/settings/economic-details-page';
import { EconomicFinalizeConnectionPage } from './Pages/settings/economic-finalize-connection-page';
import { QuickpayDetailsPage } from './Pages/settings/quickpay-details-page';
import { SettingsPage } from './Pages/SettingsPage';
import { StorefrontPage } from './Pages/StorefrontPage';
import { queryClient } from './query-client';
import { DiscountSyiSectionDetails } from './Sections/DiscountSyiSections/DiscountSyiSectionDetails';
import { ExperienceSyiSectionCustomerInfo } from './Sections/ExperienceSyiSections/ExperienceSyiSectionCustomerInfo';
import { ExperienceSyiSectionDescription } from './Sections/ExperienceSyiSections/ExperienceSyiSectionDescription';
import { ExperienceSyiSectionPictures } from './Sections/ExperienceSyiSections/ExperienceSyiSectionPictures';
import { ExperienceSyiSectionPracticalities } from './Sections/ExperienceSyiSections/ExperienceSyiSectionPracticalities';
import { ExperienceSyiSectionPrice } from './Sections/ExperienceSyiSections/ExperienceSyiSectionPrice';
import { ExperienceSyiSectionSeats } from './Sections/ExperienceSyiSections/ExperienceSyiSectionSeats';
import { GrowthDiscountsSection } from './Sections/GrowthSections/GrowthDiscountsSection';
import { GrowthEmailsSection } from './Sections/GrowthSections/GrowthEmailSection';
import { GrowthTrackingSection } from './Sections/GrowthSections/GrowthTrackingSection';
import { GrowthOpportunitiesSection } from './Sections/GrowthSections/Opportunities/ui/growth-opportunities-section';
import { SettingsCompanySection } from './Sections/SettingsSections/Company/SettingsCompanySection';
import { SettingsIntegrationsSection } from './Sections/SettingsSections/Integrations/SettingsIntegrationsSection';
import { SettingsProfileSection } from './Sections/SettingsSections/Profile/ui/SettingsProfileSection';
import { SettingsNotificationsSection } from './Sections/SettingsSections/SettingsNotificationsSection';
import { SettingsTeamSection } from './Sections/SettingsSections/team/settings-team-section';
import {
  action as roleFormDialogAction,
  loader as roleFormDialogLoader,
  RoleFormDialog,
} from './Sections/SettingsSections/team/team-roles/role-form-dialog/role-form-dialog';
import { SettingsTeamRolesDialog } from './Sections/SettingsSections/team/team-roles/settings-team-roles-dialog';
import { SettingsTermsSection } from './Sections/SettingsSections/Terms/SettingsTermsSection';
import { StorefrontBookingFlowSection } from './Sections/StorefrontSections/BookingFlowSection/ui/StorefrontBookingFlowSection';
import { CustomizeSection } from './Sections/StorefrontSections/customize-section/customize-section';
import { StorefrontFeaturesSection } from './Sections/StorefrontSections/FeaturesSection/StorefrontFeaturesSection';
import { StorefrontLanguageSection } from './Sections/StorefrontSections/language-section/storefront-language-section';
import { LocationManagementSection } from './Sections/StorefrontSections/location-management/location-management-section';
import { TagManagementSection } from './Sections/StorefrontSections/tag-management/tag-management-section';
import { ExperimentProvider } from './Utils/experiments';
import { PrivateRoute } from './Utils/PrivateRoute';

function NavigateToFrontPageBasedOnScopes() {
  const { frontPageBasedOnScopes } = useMenu();
  const frontPage = frontPageBasedOnScopes || '/dashboard';

  return <Navigate to={frontPage} />;
}

export function createRouter() {
  return createBrowserRouter([
    {
      path: '/',
      element: (
        <NiceModal.Provider>
          <Outlet />
        </NiceModal.Provider>
      ),
      children: [
        {
          path: '/',
          element: <NavigateToFrontPageBasedOnScopes />,
        },
        {
          path: '*',
          element: <NavigateToFrontPageBasedOnScopes />,
        },
        {
          path: 'login',
          element: (
            <AuthPage title="utils.primaryNavigation.login">
              <LoginPage />
            </AuthPage>
          ),
        },
        {
          path: 'preview',
          element: (
            <AuthPage title="auth.preview.titles.start">
              <PreviewView />
            </AuthPage>
          ),
        },
        {
          path: 'generating-preview/:id',
          element: (
            <AuthPage title="auth.preview.titles.start">
              <GeneratingPreview />
            </AuthPage>
          ),
        },
        {
          path: 'signup',
          element: (
            <AuthPage title="auth.titles.createAccount">
              <ExperimentProvider experiment="ForcedQuickOnboardingExperiment">
                <SignupView />
              </ExperimentProvider>
            </AuthPage>
          ),
        },
        {
          path: 'invite',
          element: (
            <AuthPage title="auth.titles.invite">
              <InvitePage>
                <InviteView />
              </InvitePage>
            </AuthPage>
          ),
        },
        {
          path: 'forgot',
          element: (
            <AuthPage title="auth.titles.forgotPassword">
              <ForgotPasswordPage />
            </AuthPage>
          ),
        },
        {
          path: 'reset',
          element: (
            <AuthPage title="auth.titles.resetPassword">
              <ResetPasswordPage />
            </AuthPage>
          ),
        },
        {
          path: 'welcome',
          element: <Navigate to={'/signup'} />,
        },
        {
          path: 'create',
          element: <Navigate to={'/signup'} />,
        },
        {
          path: 'bookings',
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.bookings"
              scopes={['booking']}
            >
              <BookingsPage />
            </PrivateRoute>
          ),
        },
        {
          path: 'calendar',
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.calendar"
              scopes={['event']}
            >
              <CalendarPage />
            </PrivateRoute>
          ),
        },
        {
          path: 'experiences',
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.experiences"
              scopes={['experience']}
            >
              <ExperiencesPage />
            </PrivateRoute>
          ),
        },
        {
          path: 'giftcards',
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.giftcards"
              scopes={['giftCard']}
            >
              <GiftCardsView />
            </PrivateRoute>
          ),
        },
        {
          path: 'discount/:id',
          element: (
            <PrivateRoute showAppShell={false} scopes={['marketing']}>
              <DiscountPage />
            </PrivateRoute>
          ),
          children: [
            {
              path: 'edit',
              children: [
                {
                  index: true,
                  element: <Navigate to={'details'} />,
                },
                {
                  path: 'details',
                  element: <DiscountSyiSectionDetails />,
                },
              ],
            },
            {
              path: 'details',
              element: <DiscountSyiSectionDetails />,
            },
            {
              index: true,
              element: <Navigate to={'details'} />,
            },
          ],
        },
        {
          path: 'event/create',
          element: (
            <PrivateRoute showAppShell={false} scopes={['event.write']}>
              <EventUpsertContextProvider>
                <EventCreate />
              </EventUpsertContextProvider>
            </PrivateRoute>
          ),
        },
        {
          path: 'event/:id/edit',
          element: (
            <PrivateRoute showAppShell={false} scopes={['event.write']}>
              <EventUpsertContextProvider>
                <EventEdit />
              </EventUpsertContextProvider>
            </PrivateRoute>
          ),
        },
        {
          path: 'event/:id',
          element: (
            <PrivateRoute
              changeTitle={false}
              hideContainerPadding
              scopes={['event']}
            >
              <EventPage />
            </PrivateRoute>
          ),
        },
        {
          path: 'experience/create',
          element: (
            <PrivateRoute
              showAppShell={false}
              changeTitle={false}
              scopes={['experience']}
            >
              <ExperiencePage />
            </PrivateRoute>
          ),
          children: [
            {
              index: true,
              element: <Navigate to={'description'} />,
            },
            {
              path: 'description',
              element: <ExperienceSyiSectionDescription />,
            },
            {
              path: 'media',
              element: <ExperienceSyiSectionPictures />,
            },
            {
              path: 'practicalities',
              element: <ExperienceSyiSectionPracticalities />,
            },
            {
              path: 'seats',
              element: <ExperienceSyiSectionSeats />,
            },
            {
              path: 'price',
              element: <ExperienceSyiSectionPrice />,
            },
            {
              path: 'customer-info',
              element: <ExperienceSyiSectionCustomerInfo />,
            },
          ],
        },
        {
          path: 'v2/experience',
          element: (
            <PrivateRoute
              showAppShell={false}
              changeTitle={false}
              scopes={['experience']}
            >
              <Outlet />
            </PrivateRoute>
          ),
          children: [
            {
              path: ':id/edit',
              id: editExperienceLoaderName,
              element: (
                <>
                  <EditExperience />
                  <Outlet />
                </>
              ),
              loader: editExperienceLoader(queryClient),
              action: editExperienceAction,
              children: [
                {
                  path: 'cutoff',
                  element: <CutoffTimeForm />,
                  action: cutoffTimeFormAction,
                },
                {
                  path: 'tags',
                  element: <TagsDialog />,
                  action: tagsFormAction,
                },
                {
                  path: 'tickets/:variantId?',
                  element: <TicketsForm />,
                  loader: ticketsFormLoader,
                  action: ticketsFormAction,
                },
                {
                  path: 'tickets/:variantId/delete',
                  element: <DeleteTicketForm />,
                  loader: deleteTicketFormLoader,
                  action: deleteTicketFormAction,
                },
                {
                  path: 'display-price',
                  element: <DisplayPriceForm />,
                  action: displayPriceFormAction,
                },
                {
                  path: 'description',
                  element: <DescriptionDialog />,
                  action: descriptionDialogAction,
                },
                {
                  path: 'whats-included',
                  element: <WhatsIncludedDialog />,
                  action: whatsIncludedDialogAction,
                },
                {
                  path: 'guest-info/:requestId?',
                  element: <GuestInfoForm />,
                  loader: guestInfoFormLoader,
                  action: guestInfoFormAction,
                },
                {
                  path: 'guest-info/:requestId/delete',
                  element: <DeleteInfoRequestForm />,
                  loader: deleteInfoRequestFormLoader,
                  action: deleteInfoRequestFormAction,
                },
                {
                  path: 'practical-info',
                  element: <PracticalInfoDialog />,
                  action: practicalInfoDialogAction,
                },
                {
                  path: 'capacity',
                  element: <CapacityForm />,
                  action: capacityFormAction,
                },
                {
                  path: 'resources/create',
                  element: <ResourceRuleForm />,
                  action: resourceRuleFormAction,
                  loader: resourceRuleFormLoader(queryClient),
                },
                {
                  path: 'resources/:ruleId/edit',
                  element: <ResourceRuleForm />,
                  action: resourceRuleFormAction,
                  loader: resourceRuleFormLoader(queryClient),
                },
                {
                  path: 'resources/:ruleId/delete',
                  element: <DeleteResourceRule />,
                  action: deleteResourceRuleAction,
                  loader: deleteResourceRuleLoader,
                },
                {
                  path: 'preview',
                  element: <PreviewDraftDialog />,
                  loader: previewDraftDialogLoader,
                },
              ],
            },
          ],
        },
        {
          path: 'experience/:id/edit',
          element: (
            <PrivateRoute
              showAppShell={false}
              changeTitle={false}
              scopes={['experience']}
            >
              <ExperiencePage />
            </PrivateRoute>
          ),
          children: [
            {
              index: true,
              element: <Navigate to={'description'} />,
            },
            {
              path: 'description',
              element: <ExperienceSyiSectionDescription />,
            },
            {
              path: 'media',
              element: <ExperienceSyiSectionPictures />,
            },
            {
              path: 'practicalities',
              element: <ExperienceSyiSectionPracticalities />,
            },
            {
              path: 'seats',
              element: <ExperienceSyiSectionSeats />,
            },
            {
              path: 'price',
              element: <ExperienceSyiSectionPrice />,
            },
            {
              path: 'customer-info',
              element: <ExperienceSyiSectionCustomerInfo />,
            },
          ],
        },
        {
          path: 'booking/:id',
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.bookings"
              hideContainerPadding
              scopes={['booking']}
            >
              <BookingPage />
            </PrivateRoute>
          ),
        },
        {
          path: 'growth',
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.growth"
              scopes={['marketing']}
            >
              <GrowthPage />
            </PrivateRoute>
          ),
          children: [
            {
              index: true,
              element: <Navigate to={'opportunities'} />,
            },
            {
              path: 'opportunities',
              element: <GrowthOpportunitiesSection />,
            },
            {
              path: 'discounts',
              element: <GrowthDiscountsSection />,
            },
            {
              path: 'emails',
              element: <GrowthEmailsSection />,
            },
            {
              path: 'tracking',
              element: <GrowthTrackingSection />,
            },
          ],
        },
        {
          path: 'storefront',
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.storefront"
              scopes={['company.write']}
            >
              <StorefrontPage />
            </PrivateRoute>
          ),
          children: [
            {
              index: true,
              element: <Navigate to={'customize'} />,
            },
            {
              path: 'customize',
              element: <CustomizeSection />,
            },
            {
              path: 'features',
              element: <StorefrontFeaturesSection />,
            },
            {
              path: 'bookingflow',
              element: <StorefrontBookingFlowSection />,
            },
            {
              path: 'language',
              element: <StorefrontLanguageSection />,
            },
            {
              path: 'tags',
              element: <TagManagementSection />,
            },
            {
              path: 'locations',
              element: <LocationManagementSection />,
            },
          ],
        },
        {
          path: 'settings/integrations/economic',
          element: (
            <PrivateRoute showAppShell={false} scopes={['company.write']}>
              <EconomicDetailsPage />
            </PrivateRoute>
          ),
        },
        {
          path: 'settings/integrations/economic/edit',
          element: (
            <PrivateRoute showAppShell={false} scopes={['company.write']}>
              <EconomicConfigurationPage />
            </PrivateRoute>
          ),
        },
        {
          path: 'settings/integrations/economic/finalize',
          element: (
            <PrivateRoute showAppShell={false} scopes={['company.write']}>
              <EconomicFinalizeConnectionPage />
            </PrivateRoute>
          ),
        },
        {
          path: 'settings/integrations/quickpay',
          element: (
            <PrivateRoute showAppShell={false} scopes={['company.write']}>
              <QuickpayDetailsPage />
            </PrivateRoute>
          ),
        },
        {
          path: 'dashboard',
          element: (
            <PrivateRoute
              title="utils.primaryNavigation.dashboard"
              scopes={['company']}
            >
              <ExperimentProvider experiment="ForcedQuickOnboardingExperiment">
                <DashboardPage />
              </ExperimentProvider>
            </PrivateRoute>
          ),
        },
        {
          path: 'settings',
          element: (
            <PrivateRoute
              titleTranslationsKey="utils.primaryNavigation.settings"
              scopes={['profile']}
            >
              <SettingsPage />
            </PrivateRoute>
          ),
          children: [
            { path: '', element: <Navigate to={'profile'} /> },
            { path: 'profile', element: <SettingsProfileSection /> },
            { path: 'company', element: <SettingsCompanySection /> },
            {
              path: 'users',
              element: <SettingsTeamSection />,
              children: [
                {
                  path: 'roles',
                  element: <SettingsTeamRolesDialog />,
                  children: [
                    {
                      path: 'create',
                      element: <RoleFormDialog />,
                      loader: roleFormDialogLoader,
                      action: roleFormDialogAction,
                      errorElement: <ErrorElement />,
                    },
                    {
                      path: 'edit/:id',
                      element: <RoleFormDialog />,
                      loader: roleFormDialogLoader,
                      action: roleFormDialogAction,
                      errorElement: <ErrorElement />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'integrations',
              element: <SettingsIntegrationsSection />,
            },
            {
              path: 'notifications',
              element: <SettingsNotificationsSection />,
            },
            { path: 'terms', element: <SettingsTermsSection /> },
            {
              path: 'connect',
              element: <ConnectSettingsList />,
              loader: connectSettingsLoader,
              errorElement: <ErrorElement />,
              children: [
                {
                  path: 'create',
                  element: <AddConnectionDialog />,
                  action: addConnectionAction,
                  loader: addConnectionLoader,
                  errorElement: <ErrorElement />,
                },
                {
                  path: 'accept/:token',
                  element: <AcceptConnectionDialog />,
                  loader: acceptConnectionLoader,
                  action: acceptConnectionAction,
                  errorElement: <ErrorElement />,
                },
                {
                  path: 'decline/:token',
                  element: <DeclineConnectionDialog />,
                  action: declineConnectionAction,
                  loader: declineConnectionLoader,
                },
                {
                  path: 'invitation/:token',
                  element: <InvitationDialog />,
                  loader: connectInvitationDialogLoader,
                  action: connectInvitationDialogAction,
                },
              ],
            },
          ],
        },
        {
          path: 'settings/connect/details/:id',
          id: 'connection-details',
          element: (
            <>
              <ConnectionDetailsPage />
            </>
          ),
          loader: connectionDetailsLoader,
          children: [
            {
              path: 'disconnect',
              element: <DisconnectConnectionDialog />,
              action: disconnectConnectionAction,
              loader: disconnectConnectionLoader,
            },
            {
              path: 'edit',
              element: <EditConnectionDialog />,
              action: editConnectionAction,
              loader: editConnectionLoader,
            },
          ],
        },
        {
          path: 'experience/:id',
          element: (
            <PrivateRoute
              changeTitle={false}
              scopes={['experience']}
              hideContainerPadding
            >
              <ExperienceDetailsPage />
            </PrivateRoute>
          ),
          errorElement: <ExperienceDetailsErrorPage />,
          loader: experienceDetailsLoader,
          children: [
            {
              path: 'update-status',
              loader: updateStatusLoader,
              action: updateStatusAction,
            },
            {
              path: 'edit-capacity',
              loader: editCapacityLoader,
              action: editCapacityAction,
            },
            {
              path: 'update-event-status',
              loader: updateEventStatusLoader,
              action: updateEventStatusAction,
            },
          ],
        },
        {
          path: 'giftcard/:id',
          id: 'gift-card-details',
          element: (
            <PrivateRoute
              changeTitle={false}
              scopes={['giftCard']}
              hideContainerPadding
            >
              <GiftCardDetailsPage />
            </PrivateRoute>
          ),
          errorElement: <GiftCardDetailsErrorPage />,
          loader: giftCardDetailsLoader,
          children: [
            {
              path: 'adjust-value',
              element: <AdjustValueDialog />,
              errorElement: <GiftCardErrorDialog />,
              action: adjustValueAction,
            },
            {
              path: 'deactivate',
              element: <DeactivateGiftCardDialog />,
              errorElement: <GiftCardErrorDialog />,
              action: deactivateGiftCardAction,
            },
            {
              path: 'refund',
              element: <RefundGiftCarddialog />,
              errorElement: <GiftCardErrorDialog />,
              action: refundGiftCardAction,
            },
          ],
        },
        {
          path: 'resources',
          id: 'resource-management',
          element: (
            <PrivateRoute
              showAppShell={false}
              changeTitle={false}
              scopes={RESOURCE_MANAGEMENT_SCOPES.VIEW_RESOURCES}
            >
              <Outlet />
            </PrivateRoute>
          ),
          children: [
            {
              id: 'resource-type-overview',
              path: '',
              loader: resourceTypesLoader(queryClient),
              element: <ResourceTypesOverview />,
              errorElement: <ErrorElement />,
              children: [
                {
                  id: 'create',
                  path: 'create',
                  element: (
                    <PrivateRoute
                      showAppShell={false}
                      changeTitle={false}
                      scopes={RESOURCE_MANAGEMENT_SCOPES.MANAGE_RESOURCES}
                    >
                      <CreateResourceTypeDialog />
                    </PrivateRoute>
                  ),
                  action: createResourceTypeAction(queryClient),
                  loader: createResourceTypeLoader(queryClient),
                  errorElement: <ErrorElement />,
                },
              ],
            },
            {
              id: 'resource-type-details',
              path: ':resourceTypeId',
              element: <ResourceTypeDetails />,
              loader: resourceTypeDetailsLoader(queryClient),
              action: resourceTypeDetailsAction(queryClient),
              children: [
                {
                  path: 'create',
                  element: (
                    <PrivateRoute
                      showAppShell={false}
                      changeTitle={false}
                      scopes={RESOURCE_MANAGEMENT_SCOPES.MANAGE_RESOURCES}
                    >
                      <CreateResourceDialog />
                    </PrivateRoute>
                  ),
                  action: createResourceAction(queryClient),
                  loader: createResourceLoader(queryClient),
                  errorElement: <ErrorElement />,
                },
              ],
            },
          ],
        },
      ],
    },
  ]);
}
