import * as yup from 'yup';

export const relativeToTypes = [
  'beforeEventStart',
  'afterEventStart',
  'beforeEventEnd',
] as const;
export type RelativeToType = (typeof relativeToTypes)[number];
export const cutoffSchema = yup.object({
  relativeTo: yup
    .mixed<RelativeToType>()
    .oneOf([...relativeToTypes], 'utils.validationErrors.invalidEnum')
    .default('beforeEventStart'),
  timeInSeconds: yup
    .number()
    .typeError('utils.validationErrors.invalidNumber')
    .required('utils.validationErrors.required'),
});

export type Cutoff = yup.InferType<typeof cutoffSchema>;
