import { useQuery } from '@tanstack/react-query';
import { Role, SavedRole } from '@understory-io/utils-types';

import { getRoles, updateRole } from '../../Api/Roles';

export const useRoles = () => {
  const QueryKey = ['roles'];

  const roles = useQuery({
    queryKey: QueryKey,
    queryFn: () => getRoles(),
    enabled: true,
  });

  const activeRoles = roles.data?.filter((role) => role.status === 'active');

  const disableRole = async (role: Role) => {
    const newRole = {
      ...role,
      claims: role.claims.map((claim) => ({
        key: claim.key,
        value: claim.value,
      })),
      status: 'inactive',
    } as Omit<SavedRole, 'roleId' | 'companyId'>;

    await updateRole(role.id, newRole).then(() => {
      roles.refetch();
    });
  };

  return { roles, activeRoles, disableRole };
};
