import { Experience } from '@understory-io/experiences-types';
import { renderDateTime } from '@understory-io/utils-date';
import {
  ConnectedItem,
  Connection,
  PublicCompanyProfile,
} from '@understory-io/utils-types';
import { TFunction } from 'i18next';

import { ampli } from '../../../Ampli';
import { getPublicCompanyProfile } from '../../../Api/Profiles';
import { getLocalized } from '../../../Hooks/useBookings';
import { t } from '../../../i18n/config';
import { trackConnectLinkClicked } from '../../../tracking/connect/connect-events';
import { TEvent } from '../../../Utils/eventHelpers';
import { ExperienceDetailsItem } from '../details/experience-details-grid';
import { renderCutoffLabel } from './render-duration';

export const calculateGuestCounts = ({ events }: { events: TEvent[] }) => {
  return events.reduce(
    (total, event) => {
      const eventGuests =
        event.bookings?.reduce((total, booking) => total + booking.slots, 0) ??
        0;

      return {
        guestCount: total.guestCount + eventGuests,
        slotsCount: total.slotsCount + parseInt(event.seatCount.value, 10),
      };
    },
    { guestCount: 0, slotsCount: 0 }
  );
};

export const getDistributorNames = async (
  connections: Connection[],
  experienceId: string
): Promise<string> => {
  const distributedToCompanies = connections
    .filter(
      (connection) =>
        connection.state === 'connected' &&
        (connection.sharedExperiences === 'all' ||
          connection.sharedExperiences.includes(experienceId))
    )
    .map((connection) => (connection as ConnectedItem).connectedCompanyId);

  const profiles = await Promise.all(
    distributedToCompanies.map((id) => getPublicCompanyProfile(id))
  );

  return profiles.map(({ name }) => name).join(', ');
};

export const generateExperienceDetails = ({
  experience,
  upcomingEventsCount,
  guestCount,
  slotsCount,
  isShared,
  experienceOwnerCompanyProfile,
  distributorCompanyNames,
}: {
  experience: Experience;
  upcomingEventsCount: number;
  guestCount: number;
  slotsCount: number;
  isShared: boolean;
  experienceOwnerCompanyProfile?: PublicCompanyProfile | null;
  distributorCompanyNames: string;
}) => {
  const details: ExperienceDetailsItem = [];

  details.push({
    key: 'events',
    label: t('experience.details.label.events'),
    value: t('experience.details.value.events', {
      count: upcomingEventsCount,
    }),
  });

  details.push({
    key: 'guests',
    label: t('experience.details.label.guests'),
    value: t('experience.details.value.guests', {
      bookedCount: guestCount,
      totalCount: slotsCount,
    }),
  });

  details.push({
    key: 'language',
    label: t('experience.details.label.language'),
    value: experience.languages
      .map((langCode) => t(`utils.languages.${langCode}`))
      .join(', '),
  });

  if (experience.cutoff || experience.cutoffTimeSeconds) {
    details.push({
      key: 'cutoffTime',
      label: t('experience.details.label.cutoffTime'),
      value: renderCutoffLabel(
        t as TFunction,
        experience.cutoff,
        experience.cutoffTimeSeconds
      ),
    });
  }

  if (isShared) {
    details.push({
      key: 'owner',
      label: t('experience.details.label.owner'),
      value: experienceOwnerCompanyProfile?.name ?? '',
      onClickTrackingFn: () => trackConnectLinkClicked('experience'),
    });
  }

  if (distributorCompanyNames) {
    details.push({
      key: 'distributor',
      label: t('experience.details.label.distributor'),
      value: distributorCompanyNames,
      onClickTrackingFn: () => trackConnectLinkClicked('experience'),
    });
  }

  return details;
};

export const generateExperienceOtherDetails = ({
  experience,
  experienceDetailsBaseURL,
  experienceBookingFlowBaseURL,
  isShared,
}: {
  experience: Experience;
  experienceDetailsBaseURL: string;
  experienceBookingFlowBaseURL: string | null;
  isShared: boolean;
}): ExperienceDetailsItem => {
  return [
    {
      key: 'creationDate',
      label: t('experience.details.label.creationDate'),
      value: renderDateTime(experience.dates.created),
    },
    {
      key: 'detailsLink',
      label: t('experience.details.label.detailsPage'),
      value: t('experience.details.value.detailsPage'),
      link: `https://${experienceDetailsBaseURL}/experience/${experience.id}`,
    },
    {
      key: 'bookingLink',
      label: t('experience.details.label.bookingLink'),
      value: t('experience.details.value.bookingLink'),
      link: `https://${experienceBookingFlowBaseURL ?? experienceDetailsBaseURL}/booking/${experience.ownerExperienceId}${isShared ? `?distributorId=${experience.companyId}` : ''}`,
      onCopyTrackingFn: () =>
        ampli.experienceBookingFlowLinkCopied({
          is_private: experience.visibility === 'private',
          experience_name: getLocalized(experience.headline, 'en') ?? '',
          experience_id: experience.id,
          language_list: experience.languages,
        }),
      onClickTrackingFn: () =>
        ampli.experienceBookingFlowLinkOpened({
          experience_id: experience.id,
          experience_name: getLocalized(experience.headline, 'en') ?? '',
          language_list: experience.languages,
          is_private: experience.visibility === 'private',
        }),
    },
  ];
};
