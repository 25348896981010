import NiceModal from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import { ArrowBackOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { Experience } from '@understory-io/experiences-types';
import { Button, lightTheme, LinkButton, Text } from '@understory-io/pixel';
import { EventStatus, ExperienceStatus } from '@understory-io/utils-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useHref,
  useLinkClickHandler,
  useLocation,
  useNavigate,
  useNavigation,
  useSubmit,
} from 'react-router';

import { ampli, ExperienceDeactivatedProperties } from '../../../Ampli';
import { ShowForScope } from '../../../Components/AllowForScope/AllowForScope';
import { StatusBadge } from '../../../Components/badge/status-badge';
import { ContextMenu } from '../../../Components/context-menu/context-menu';
import { getLocalizedString } from '../../../Hooks/useLocalizedStringFormatter';
import { useTranslate } from '../../../Hooks/useTranslate';
import { NoExperienceTitle } from '../overview/experience-item';
import { DeleteExperienceDialog } from './delete-experience-dialog';

interface ExperienceActionBase {
  key: string;
  'data-intercom-target'?: string;
  trackingFn?: (properties: ExperienceDeactivatedProperties) => void;

  label: string;
  variant: 'primary' | 'secondary' | 'danger';
  textColor?: string;
  scopes: string[];
}

export interface ExperienceActionSubmit extends ExperienceActionBase {
  type: 'submit';
  method?: 'post' | 'get';
  action?: string;
}

export interface ExperienceActionButton extends ExperienceActionBase {
  type: 'button';
  onClick: (experienceId: string) => void;
}

export interface ExperienceActionLink extends ExperienceActionBase {
  type: 'link';
  to: string;
}

export type ExperienceAction =
  | ExperienceActionButton
  | ExperienceActionSubmit
  | ExperienceActionLink;

const ACTION_CONFIG: Record<string, ExperienceAction> = {
  deactivate: {
    key: 'deactivate',
    label: 'experience.details.action.deactivateLabel',
    type: 'submit',
    variant: 'secondary',
    textColor: lightTheme.palette.error.e300,
    method: 'post',
    scopes: ['experience.write'],
    action: 'update-status',
    trackingFn: (properties: ExperienceDeactivatedProperties) =>
      ampli.experienceDeactivated(properties),
  },
  activate: {
    key: 'activate',
    label: 'experience.details.action.activateLabel',
    type: 'submit',
    variant: 'secondary',
    method: 'post',
    scopes: ['experience.write'],
    action: 'update-status',
    trackingFn: (properties: ExperienceDeactivatedProperties) =>
      ampli.experienceActivated(properties),
  },
  delete: {
    key: 'delete',
    label: 'experience.details.action.deleteLabel',
    type: 'button',
    variant: 'danger',
    scopes: ['experience.write'],
    onClick: (experienceId: string) =>
      NiceModal.show(DeleteExperienceDialog, { experienceId }),
  },
  edit: {
    key: 'edit',
    label: 'experience.details.action.editLabel',
    type: 'link',
    variant: 'secondary',
    to: 'edit',
    scopes: ['experience.write'],
  },
  createEvent: {
    key: 'createEvent',
    label: 'experience.details.action.createEventLabel',
    type: 'link',
    variant: 'primary',
    to: `/event/create`,
    scopes: ['event.write'],
    'data-intercom-target': 'experience-create-new-event',
    trackingFn: (properties: ExperienceDeactivatedProperties) =>
      ampli.experienceEventCreationStarted(properties),
  },
};

export type ActionConfigKey = keyof typeof ACTION_CONFIG;

type ExperienceDetailsHeaderProps = {
  title: string;
  subtitle: string;
  status: ExperienceStatus;
  actions: ActionConfigKey[];
  experienceId: string;
  experience: Experience;
};

export const ExperienceDetailsHeader = ({
  title,
  subtitle,
  status,
  actions,
  experienceId,
  experience,
}: ExperienceDetailsHeaderProps) => {
  const submit = useSubmit();
  const { state } = useNavigation();
  const isSubmitDisabled = state !== 'idle';
  const { t } = useTranslation();
  const location = useLocation();

  const trackingProperties = useMemo(
    () => ({
      experience_id: experience.id,
      experience_name: getLocalizedString(experience.headline, 'en'),
      is_private: experience.visibility === 'private',
      language_list: experience.languages,
    }),
    [experience]
  );

  return (
    <Stack direction="row" justifyContent="space-between">
      <ExperienceDetails title={title} subtitle={subtitle} status={status} />
      <Stack
        component="form"
        sx={{
          flexDirection: 'row',
          gap: 1,
        }}
      >
        <Stack
          gap={1}
          sx={{
            flexDirection: 'row',
            gap: 1,
            display: { xs: 'none', md: 'flex' },
          }}
        >
          {actions?.map((actionKey) => {
            const actionButton = ACTION_CONFIG[actionKey];

            if (actionButton.type === 'submit') {
              const onClick = () => {
                actionButton.trackingFn?.(trackingProperties);
                submit(
                  { action: actionKey },
                  {
                    method: actionButton.method,
                    action: `${actionButton.action}${location.search}`,
                  }
                );
              };

              return (
                <ShowForScope key={actionKey} scopes={actionButton.scopes}>
                  <Button
                    type="button"
                    variant={actionButton.variant}
                    size="medium"
                    onClick={onClick}
                    disabled={isSubmitDisabled}
                    data-intercom-target={actionButton['data-intercom-target']}
                  >
                    {t(actionButton.label)}
                  </Button>
                </ShowForScope>
              );
            }

            if (actionButton.type === 'button') {
              return (
                <ShowForScope key={actionKey} scopes={actionButton.scopes}>
                  <Button
                    type="button"
                    variant={actionButton.variant}
                    size="medium"
                    onClick={() => actionButton.onClick(experienceId)}
                  >
                    {t(actionButton.label)}
                  </Button>
                </ShowForScope>
              );
            }

            return (
              <ShowForScope key={actionKey} scopes={actionButton.scopes}>
                <ActionLinkButton
                  button={actionButton}
                  experienceId={experienceId}
                  onClick={() => actionButton.trackingFn?.(trackingProperties)}
                />
              </ShowForScope>
            );
          })}
        </Stack>
        <ExperienceDetailsContextMenu
          experienceId={experienceId}
          actions={actions}
        />
      </Stack>
    </Stack>
  );
};

const ExperienceDetailsContextMenu = ({
  experienceId,
  actions,
}: {
  experienceId: string;
  actions: ActionConfigKey[];
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const submit = useSubmit();
  const navigate = useNavigate();
  const location = useLocation();

  const flags = useFlags();

  return (
    <Box sx={{ display: { md: 'none' } }}>
      <ContextMenu
        type="button"
        open={isOpen}
        onOpenChange={setIsOpen}
        options={actions.map((actionKey) => {
          const action = ACTION_CONFIG[actionKey];

          return {
            label: t(action.label),
            scopes: action.scopes,
            onClick: () => {
              if (action.type === 'submit') {
                submit(
                  { action: actionKey },
                  {
                    method: action.method,
                    action: `${action.action}${location.search}`,
                  }
                );
              } else if (action.type === 'link') {
                const linkTo =
                  action.key === 'edit' && flags.featureCreateExperience
                    ? `/v2/experience/${experienceId}/edit`
                    : action.to;
                navigate(linkTo, {
                  state: {
                    experienceId,
                    returnUrl: location.pathname + location.search,
                  },
                });
              } else {
                action.onClick(experienceId);
              }
            },
            textColor: action.textColor,
          };
        })}
      />
    </Box>
  );
};

const ActionLinkButton = ({
  experienceId,
  button,
  onClick,
}: {
  experienceId: string;
  button: ExperienceActionLink;
  onClick: () => void;
}) => {
  const flags = useFlags();
  const { t } = useTranslation();
  const linkTo =
    button.key === 'edit' && flags.featureCreateExperience
      ? `/v2/experience/${experienceId}/edit`
      : button.to;
  const href = useHref(linkTo);
  const location = useLocation();
  const actionButtonClicked = useLinkClickHandler(href, {
    state: { experienceId, returnUrl: location.pathname + location.search },
  });

  return (
    <LinkButton
      href={href}
      variant={button.variant}
      size="medium"
      onClick={(e) => {
        onClick();
        actionButtonClicked(e);
      }}
      data-intercom-target={button['data-intercom-target']}
    >
      {t(button.label)}
    </LinkButton>
  );
};

const ExperienceDetails = ({
  title,
  subtitle,
  status,
}: Pick<ExperienceDetailsHeaderProps, 'title' | 'subtitle' | 'status'>) => {
  const navigate = useNavigate();
  const handleBack = () => navigate('/experiences');

  return (
    <Stack sx={{ flexDirection: { md: 'row' }, gap: { xs: 2, md: 1 } }}>
      <StyledBackButton fontSize="small" onClick={handleBack} />
      <Stack gap={0.5}>
        <Stack direction="row" gap={2.5} alignItems="center">
          <Text variant="medium" fontSize="large">
            {title || <NoExperienceTitle />}
          </Text>
          <StatusBadge type="experience" size="small" state={status} />
        </Stack>
        <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
          {subtitle}
        </Text>
      </Stack>
    </Stack>
  );
};

export const StyledBackButton = styled(ArrowBackOutlined)({
  cursor: 'pointer',
  borderRadius: '50%',
  transition: 'background-color 0.1s',
  '&:hover': {
    backgroundColor: lightTheme.palette.neutral.n100,
  },
  '&:active': {
    backgroundColor: lightTheme.palette.neutral.n200,
  },
});

export const StatusChip = ({
  status,
  fontSize = 'small',
  variant = 'pill',
}: {
  status: ExperienceStatus | EventStatus | 'done';
  fontSize?: 'small' | 'xsmall';
  variant?: 'indicator' | 'pill';
}) => {
  const { t } = useTranslate('utils.stateChips');
  const { backgroundColor, textColor } = getChipStyle(status);

  const isPill = variant === 'pill';

  return (
    <Stack
      sx={{
        paddingY: 0.5,
        paddingX: isPill ? 1 : 0.5,
        backgroundColor: isPill ? backgroundColor : textColor,
        borderRadius: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {variant === 'pill' && (
        <Text
          variant={fontSize === 'small' ? 'medium' : 'normal'}
          fontSize={fontSize}
          color={textColor}
        >
          {t(status)}
        </Text>
      )}
    </Stack>
  );
};

const getChipStyle = (status: ExperienceStatus | EventStatus | 'done') => {
  if (status.includes('cancel')) {
    return {
      backgroundColor: lightTheme.palette.error.e100,
      textColor: lightTheme.palette.error.e400,
    };
  }

  switch (status) {
    case 'active':
      return {
        backgroundColor: lightTheme.palette.success.s100,
        textColor: lightTheme.palette.success.s400,
      };
    case 'inactive':
      return {
        backgroundColor: lightTheme.palette.warning.w100,
        textColor: lightTheme.palette.warning.w400,
      };
    case 'draft':
    case 'done':
      return {
        backgroundColor: lightTheme.palette.neutral.n100,
        textColor: lightTheme.palette.neutral.n400,
      };

    default:
      return {
        backgroundColor: lightTheme.palette.neutral.n100,
        textColor: lightTheme.palette.neutral.n400,
      };
  }
};
