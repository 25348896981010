import { Experience } from '@understory-io/experiences-types';

import { EventType } from '../../../../features/events/event_upsert/domain/types';
import { DetailsFormData } from '../../../../features/events/event_upsert/ui/details/ui/details_form/use_details_form_validation';
import { getLocalized } from '../../../../Hooks/useBookings';

export const eventProperties = (
  details: DetailsFormData,
  event: EventType,
  experiences?: Experience[]
) => {
  const {
    experienceId: experience_id,
    addresses: location,
    seatCount,
    languages: language_list = [],
  } = details;

  const { id: event_id, visibility, assignedGuides } = event;

  const experience_name =
    getLocalized(
      experiences?.find((experience) => experience.id === experience_id)
        ?.headline,
      'en'
    ) ?? '';

  return {
    event_capacity: seatCount.value,
    event_id,
    experience_id,
    experience_name,
    is_private: visibility === 'private',
    language_list,
    location: location?.value,
    number_of_guides: assignedGuides.length,
    flow_type: event.variant,
  };
};
