import * as yup from 'yup';

export const tagSchema = yup
  .array()
  .of(
    yup
      .string()
      .required('utils.validationErrors.required')
      .typeError('utils.validationErrors.invalidString')
  )
  .defined()
  .default([]);
export type Tag = yup.InferType<typeof tagSchema>;
